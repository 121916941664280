<template>
  <div class="introduction">
     <!--title - 疾病简介 -->
    <template v-if="data.introduction">
      <slot name="introduction"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
        疾病简介
      </h2>
      <Rich
      class="html"
        v-model="data.introduction"
        :style_type="2"
      />
    </template>
    <!--title - 常开检验检查 -->
    <template v-if="data.recommends&&data.recommends.length>0">
      <slot name="recommends"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom" >
        <div class="line"/>
        常开检验检查
      </h2>
      <template v-if="arr1.length>0">
        <h2 class="commom-text small-title commom-text-bottom" >
            <img class="lab-icon" src="../../../assets/img/detail/dialog-lab-1.png"/>
            检验
        </h2>
        <ul class="lab"  style="margin-bottom: 16px">
          <li v-for="(i,index) in arr1" :key="i.id">
            {{i.examine}}<span style="color:rgba(48, 49, 51, 1);" v-if="index < arr1.length-1">、</span>
          </li>
        </ul>
      </template>
      <template v-if="arr2.length>0">
        <h2 class="commom-text small-title commom-text-bottom">
            <img class="lab-icon" src="../../../assets/img/detail/dialog-lab-2.png"/>
            检查
        </h2>
        <ul class="lab">
          <li v-for="(i,index) in arr2" :key="i.id">
            {{i.examine}}<span style="color:rgba(48, 49, 51, 1);" v-if="index < arr2.length-1">、</span>
          </li>
        </ul>
      </template>
      </template>
      <!--title - 诊断标准和病情评估 -->
      <template  v-if="data.condition_assessment">
      <slot name="condition_assessment"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          诊断标准和病情评估
        </h2>
      <Rich class="html"
          v-model="data.condition_assessment"
          :style_type="2"
        />
    </template>
    <!--title - 临床表现 -->
    <template v-if="data.clinical_manifestations || data.special_inspection">
      <slot name="clinical_manifestations"></slot>
      <h2 class="commom-text commom-text-top commom-text-bottom">
        <div class="line"/>
        临床表现
      </h2>
      <template v-if="data.clinical_manifestations">
        <h2 class="commom-text commom-text-bottom small-title">
          症状
        </h2>
        <Rich class="html"
          style="padding-bottom: 16px"
          v-model="data.clinical_manifestations"
          :style_type="2"
      />
      </template>
      <template v-if="data.special_inspection">

        <h2 class="commom-text commom-text-bottom small-title" >
          体征
        </h2>
        <Rich class="html"
          v-model="data.special_inspection"
          :style_type="2"
      />
      </template>
    </template>
  </div>
</template>
<script>
import Rich from '../../rich/index.vue'
export default {
  props: {
    data: {
      type: Object,
      default: () =>{}
    }
  },
  computed:{
    arr1() {
      let list = []
      let recommends = this.data.recommends || []
      for(let i of recommends) {
        if(i.type!==2) {
          list.push(i)
        }
      }
      list.sort((a,b)=>{
        return a.type-b.type
      })
      return list
    },
    arr2() {
      let list = []
      let recommends = this.data.recommends || []
      for(let i of recommends) {
        if(i.type===2) {
          list.push(i)
        }
      }
      return list
    }
  },
  components:{
    Rich
  }

}
</script>
<style scoped>
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: #1890FF;
    flex:1;
     &.risk {
      color: red;
    }
  }
  & .line {
    width: 4px;
    height: 16px;
    background: #1890FF;
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.lab {
  display: flex;
  flex-wrap: wrap;
  color: rgba(37, 128, 236, 1);
  padding-left: 30px;
}
.introduction {
  padding-bottom: 80px;
}
</style>
