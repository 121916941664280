<template>
  <div class="chart_box">
    <img v-if="style_type === 0" src="../../../assets/img/detail/b_bg_0.png" class="bg" />
    <img v-else-if="style_type === 1" src="../../../assets/img/detail/b_bg_1.png" class="bg" />
    <img v-else src="../../../assets/img/detail/b_bg_2.png" class="bg" />
    <div class="unit">{{data.unit}}</div>
    <div class="chart not_drag" ref="myChart"></div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { line_colors } from '../config'
import { format_date,format_decimal } from '../../../utils/format'

export default {
  props: {
    data: {
      default: ()=>{},
      type: Object
    },
    style_type: {
      default: 0,
      type: Number
    },
    report_id: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      line_colors,
      myChart: null,
      timer: null,
      xAxis: {},
      yAxis: {},
      markLine: {},
      data_list: [],
      x_data: [],
      max_page_size: 4,
    }
  },
  computed: {
    ...mapState('commonData', {
      menu_show: state => state.menu_show,
    }),
    current_value () {
      let {histories = []} = this.data
      for(let i of histories) {
        if (i.report_id === this.report_id) {
          return i.result_time
        }
      }
      return null
    }
  },
  watch:{
    data() {
      this.init_echart()
    },
    menu_show () {
      this.resize()
    }
  },
  components: {
  },
  created(){
  },
  mounted () {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.myChart)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.resize, false)
      this.myChart.clear()
      echarts.dispose(this.myChart)
      this.myChart = null
    })

    this.init_echart()
    window.addEventListener('resize', this.resize, false)
  },
  methods:{
    resize () {
      // 防抖，有自定义图形组件时使用
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.myChart.resize()
        this.echarts()
      }, 50)
    },
    // 初始数据
    init_data () {
      let {histories = [], result_type} = this.data
      // x轴
      let y_max = null
      let y_min = null
      this.data_list = []
      this.x_data = []
      for (let {rate, result_time: _result_time} of histories) {
        let val = format_decimal(rate)
        if (result_type === 2) {
          if (y_max === null || +rate > y_max) y_max = val
          if (y_min === null || +rate < y_min) y_min = val
        }
        let date = `${_result_time}`
        this.x_data.push(date)
        this.data_list.push({
          value: val,
          date,
          symbolSize: 8
        })
      }

      this.xAxis = {
        type: 'category',
        axisLabel: {
          color: '#6C757D',
          formatter: function(val) {
            return `${format_date(val)}`
          }
        },
        axisLine: {
          lineStyle: {
            color: '#AAB5B4'
          }
        },
        axisTick: {
          lineStyle: {
            color: '#AAB5B4'
          },
          alignWithLabel: true
        },
        data: this.x_data,
      }
      // y轴
      if (result_type === 1) {
        this.yAxis = {
          type: 'category',
          position: 'right',
          axisLabel: {
            color: '#6C757D',
          },
          min: -1,
          max: 2,
          axisLine: {
            lineStyle: {
              color: '#AAB5B4'
            },
          },
          axisTick: {
            lineStyle: {
              color: '#AAB5B4'
            },
            alignWithLabel: true
          },
          boundaryGap: false,
          data: ['阴性', '阳性'],
        }
      } else {
        this.yAxis = {
          type: 'value',
          position: 'right',
          axisLabel: {
            color: '#6C757D',
          },
          min: 0,
          max: 100,
          axisLine: {
            lineStyle: {
              color: '#AAB5B4'
            },
          },
          axisTick: {
            lineStyle: {
              color: '#AAB5B4'
            },
            alignWithLabel: true
          },
          splitLine: {
            show: false
          },
        }
      }

    },
    init_echart () {
      this.init_data()
      this.$nextTick(() => {
        this.echarts()
      })
    },
    echarts () {
      let that = this
      this.myChart.clear()
      let {unit} = this.data
      let color = this.line_colors[this.style_type]
      let len = this.x_data.length
      let zoom_show = len > this.max_page_size ? true : false
      let diff = 100
      if (len > 0) {
        diff = 100 / len
      }
      let current_index = this.x_data.indexOf(`${this.current_value}`)
      current_index = current_index !== -1 ? current_index : 0
      let start_1 = current_index * diff
      let start_2 = 100 - this.max_page_size * diff
      let start = Math.min(start_1, start_2)
      let end = start + this.max_page_size * diff
      let option = {
        dataZoom: [
          {
            show: zoom_show,
            start,
            end,
            showDetail: false,
            brushSelect: false,
          }
        ],
        grid: {
          top: 40,
          left: 0,
          right: 28
        },
        tooltip: {
          trigger: 'item',
          formatter:function(params) {
            let data = params.data
            if(data.symbolSize) return `${data.params.value} ${that.data.unit || ''}`
          }
        },
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        series: [
          {
            data: this.data_list,
            type: 'line',
            smooth: false,
            itemStyle: {
              color
            },
            label: {
              color: 'red'
            },
            lineStyle: {
              color
            },
            markLine: this.markLine
          },
          {
            type: 'custom',
            coordinateSystem: 'cartesian2d',
            renderItem: (params, api) => {
              let index = api.value(0)
              let {value, date} = this.data_list[index]
              let label = value
              if (unit) label += ` ${unit}`
              // 标签
              let [dot_x, dot_y] = api.coord([date, value])
              return {
                type:'group',
                children:[
                  {
                    type: 'text',
                    style: {
                      x: dot_x,
                      y: dot_y - 20,
                      text: label,
                      font:'normal normal 14px Times',
                      textVerticalAlign: 'middle',
                      textAlign: 'center',
                      fill:  date === `${this.current_value}` ? 'red' : color,
                    }
                  },
                ]
              }
            },
            data: this.x_data
          }
        ]
      }
      this.myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.chart_box {
  width: 100%;
  height: 100%;
  position: relative;
}
.chart {
  width: 100%;
  height: 100%;
}
.line {
  position: absolute;
  right: 10px;
  top: 5px;
  bottom: 5px;
  border-right: 1px solid #C0C3C6;
}
.bg {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  height: 155px;
  width: 100%;
}
.unit {
  position: absolute;
  right: 8px;
  top: 0px;
  font-size: 12px;
  color: #98A6AD;
}
</style>
