import Vue from 'vue'
import Router from 'vue-router'
import { getSession, removeSession } from './utils/session'
import { noLoginArr } from './pages/login/config'
import { logout } from './pages/login/service'
import { page_record } from './common-data/service'
import store from './store'
import { restore_page } from './keepAlive'

// 解决点击重复路由报错问题
const original_push = Router.prototype.push
Router.prototype.push = function push () {
  return original_push.apply(this, arguments).catch(err => err)
}

/* Global */
import CommonLayout from './pages/common-layout'

const Demo = () => import('@/pages/demo')
const H5 = () => import('@/pages/h5')

const Login = () => import('./pages/login')

const Home = () => import('./pages/home')
const Excellent = () => import('@/pages/excellent')
const Detail = () => import('@/pages/lab-detail')
const Laboratory = () => import('@/pages/laboratory')
const Data = () => import('@/pages/data')
const DiagnosisMenu = () => import('@/pages/diagnosis-menu')
const Users = () => import('./pages/users')
const Logs = () => import('./pages/logs')
const AboutUs = () => import('./pages/about-us')

//雷达图详情
const RadarDetail = () => import('@/pages/lab-detail-radar')

const AboutUsLogin = () => import('./pages/about-us/LoginAboutUs.vue')
// 管理许可证
const EditLicense = () => import('./pages/editLicense')
// 常见问题
const CommonProblem = () => import('./pages/common-problem')
// 版本更新
const VersionUpdate = () => import('./pages/version-update')
// 应用集详情
const  ApplicationSet = () => import('./pages/application-set')

//危机提醒
const  CrisisReminder = () => import('./pages/crisis-reminder')
//危机提醒详情
const  CrisisDetail = () => import('./pages/crisis-detail')
//看板
const  BulletinBoard = () => import('./pages/bulletin-board')

// 应用集
const AppStore = () => import('./pages/appstore')

// 数据清洗
const DataCleaning = () => import('./pages/data-cleaning')
//使用统计
const OperationsUseStatistical = () => import('./pages/operations-use-statistical')
// 解读能力
const OperationsInterpretAbility = () => import('./pages/operations-interpret-ability')
// 黑名单管理
const Blacklist = () => import('./pages/blacklist')
const BlacklistRecord = () => import('./pages/blacklist-record')
//ai报告解读
const AiReport = () => import('./pages/ai-report')
//注解报告
const AnalysisReport = () => import('./pages/analysis-report')

// 报告详情第二版
const Detail2 = () => import('@/pages/lab-detail-2')
const Unscramble = () => import('@/pages/unscramble')
const EncyclopediaKnowledge = () => import('@/pages/encyclopedia-knowledge')
const Report = () => import('@/pages/report')
//知识详情
const KnowledgeDetail = () => import('@/pages/knowledge-detail')
//数据中心
const DataCenter = () => import('@/pages/data-center')
//自动机器学习平台
const AiPlatform = () => import('@/pages/ai-platform')
const AiPlatformDetail = () => import('@/pages/ai-platform-detail')
// 应用集页面
const AppApplication = () => import('@/pages/app-application')

//综合提示语
const ComprehensivePrompt = () => import('@/pages/comprehensive-prompt')
const ComprehensivePromptDetail = () => import('@/pages/comprehensive-prompt-detail')
//自定义文本
const UserDefinedText= () => import('@/pages/user-defined-text')
//报告列表
const ReportList = () => import('@/pages/report-list')
// 应用管理
const AppManage = () => import('@/pages/app-manage')
// 患者端
const CitizenPhone = () => import('./pages/citizen-phone')
// 即将上线
const Online = () => import('./pages/online')
// 河流图
const River = () => import('./pages/river')
//收藏列表
const FavoriteList = () => import('./pages/favorite-list')

Vue.use(Router)

const routes = [
  {
    path: '/login',
    name: '登录',
    component: Login
  },
  {
    path: '/about-us-login',
    name: '关于慧检登陆',
    component: AboutUsLogin,
  },
  {
    path: '/radar-detail',
    name: '长海-报告详情',
    component: RadarDetail,
    meta: {
      source: 'patch', // 插件版
    },
  },
  {
    path: '/h5',
    name: 'h5',
    component: H5
  },
  {
    path: '/report-detail',
    component: Detail2,
    children: [
      {
        path: '',
        name: '解读',
        component: Unscramble,
      },
      {
        path: '/encyclopedia-knowledge',
        name: '知识',
        component: EncyclopediaKnowledge,
      },
      {
        path: '/knowledge-detail',
        name: '知识详情',
        component: KnowledgeDetail
      },
      {
        path: '/report',
        name: '报告单',
        component: Report,
      },
      {
        path: '/app-application',
        name: '专属应用',
        component: AppApplication,
      },
      {
        path: '/river',
        name: '健康趋势',
        component: River,
      },
    ]
  },
  {
    path: '/',
    component: CommonLayout,
    children: [
      {
        path: '/',
        name: '首页',
        component: Home,
        meta: {
          no_header: true, // 没有面包屑
        },
      },
      {
        path: '/about-us',
        name: '关于慧检',
        component: AboutUs,
      },
      {
        path: '/excellent',
        name: 'AI报告解读',
        component: Excellent,
      },
      {
        path: '/detail',
        name: '报告详情',
        component: Detail,
        meta: {
          full_screen: true, // 全屏展示
          show_nav: true, // 显示右边导航
        },
      },
      {
        path: 'data-center',
        name: '数据中心',
        component: DataCenter,
      },
      {
        path: 'ai-platform',
        name: '自动机器学习平台',
        component: AiPlatform,
      },
      {
        path: 'ai-platform-detail',
        name: '自动机器学习平台详情',
        component: AiPlatformDetail,
      },
      {
        path: '/laboratory',
        name: '详情',
        component: Laboratory,
        meta: { history: true } // 追加面包屑
      },
      {
        path: '/data',
        name: '懂检验',
        component: Data,
      },
      {
        path: '/diagnosis-menu',
        name: '查疾病',
        component: DiagnosisMenu,
      },

      {
        path: '/crisis-reminder',
        name: '重要指标管理',
        component: CrisisReminder,
      },

      {
        path: '/bulletin-board',
        name: '系统看板',
        component: BulletinBoard,
        meta: {
          no_header: true, // 没有面包屑
        },
      },

      {
        path: '/crisis-detail',
        name: '重要指标',
        component: CrisisDetail,
      },
      {
        path: '/users',
        name: '账号管理',
        component: Users,
      },
      {
        path: '/logs',
        name: '日志管理',
        component: Logs,
      },
      {
        path: '/editLicense',
        name: '管理许可证',
        component: EditLicense,
      },
      {
        path: '/common-problem',
        name: '常见问题',
        component: CommonProblem,
      },
      {
        path: '/version-update',
        name: '版本更新',
        component: VersionUpdate,
      },
      {
        path: '/appstore',
        name: '应用集市',
        component: AppStore,
      },
      {
        path: '/appstore-detail',
        name: '应用集详情',
        component: ApplicationSet,
        meta: {
          history: true
        },
      },
      {
        path: '/data-cleaning',
        name: '数据清洗',
        component: DataCleaning,
      },
      {
        path: '/operations-use-statistical',
        name: '使用统计',
        component: OperationsUseStatistical,
      },
      {
        path: '/operations-interpret-ability',
        name: '解读能力',
        component: OperationsInterpretAbility,
      },
      {
        path: '/blacklist',
        name: '黑名单',
        component: Blacklist,
      },
      {
        path: '/blacklist-record',
        name: '操作记录',
        component: BlacklistRecord,
      },
      {
        path: '/ai-report',
        name: 'AI报告',
        component: AiReport,
      },
      {
        path: '/analysis-report',
        name: '注解报告',
        component: AnalysisReport,
      },
      {
        path: '/comprehensive-prompt',
        name: '智慧提示',
        component: ComprehensivePrompt,
      },
      {
        path: '/comprehensive-prompt-detail',
        component: ComprehensivePromptDetail,
        meta: {
          customBreadcrumb: true, // 自定义
        },
      },
      {
        path: '/report-list',
        name: '报告列表',
        component: ReportList,
      },
      {
        path: '/user-defined-text',
        name: '自定义文本',
        component: UserDefinedText,
      },
      {
        path: '/app-manage',
        name: '应用管理',
        component: AppManage,
      },
      {
        path: '/favorite-list',
        name: '我的收藏',
        component: FavoriteList,
      },
    ]
  },
  {
    path: '/demo',
    name: '例子',
    component: Demo
  },
  {
    path: '/citizen-phone',
    name: '患者端',
    component: CitizenPhone
  },
  {
    path: '/online',
    name: '即将上线',
    component: Online
  },
]

const router = new Router({
  routes,
})

// 地址是否被包含
export const path_includes = (path, verify_list) => {
  if (path === '/login') return true
  for (let key of verify_list) {
    const reg = new RegExp(`^${key}$`)
    if (reg.test(path)) {
      return true
    }
  }
  return false
}
router.beforeEach(async (to, from, next) => {
  // 页面缓存
  store.commit('keepAlive/update_keep_alive', {to, from, routes})

  const {path, fullPath} = to
  let redirect = encodeURIComponent(fullPath)
  // 不需要登录验证的地址
  for (let i = 0, len = noLoginArr.length; i < len; i++) {
    if (noLoginArr[i] === path) {
      return next()
    }
  }

  const authToken = getSession()
  const verifyArr = (authToken && authToken.verifyArr) || []

  if (!path_includes(path, verifyArr)) return next({ path: '/login', query: {redirect} })

  // 直接打开login页面的不清除
  if (path === '/login' && authToken && from.path !== path && from.name) {
    try {
      await logout()
    } finally {
      removeSession()
      // 阻止iframe里面的登录
      if (GLOBAL_CONFIG._open_source === 'iframe') {
        top.window.location.reload()
      } else {
        setTimeout(()=>{
          location.reload()
        }, 300)
      }
    }
  }
  next()
})

// 记录访问的页面
router.afterEach((to) => {
  // 记录访问的页面
  const authToken = getSession()
  if (authToken && authToken.token && to.path !== '/login') {
    if(to.name) {
      page_record({
        desc: `页面访问 页面：${to.name}`
      })
    }
  }
  // 恢复缓存的页面，如果有bug，去页面里面activated来执行
  setTimeout(() => {
    restore_page(to.meta?.name)
  })
})

export default router
