import api from '../../utils/api'


// list
export async function get_info (payload) {
  return api.get(`/api/v2/ai/reports/analysis/${payload}`)
}

export async function get_timeline (payload) {
  return api.get(`/api/v2/reading2/patients/${payload}/reports`)
}

export async function set_diagnosis_mark(payload) {
  return api.post(`/api/v2/reports/disease/tagging`,payload)
}

export async function get_reprot_timeline (payload, doctor_id) {
  return api.get(`/api/v2/embed/patients/${payload}/reports?doctor_id=${doctor_id}`)
}

export async function get_report_info (payload) {
  return api.get(`/api/v2/embed/analysis`, {params: payload})
}

// 院内历史诊断
export async function get_history_diag (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         total_elements: 200,
  //         data: [
  //           {
  //             date: 1633017600000,
  //             diagnosis: ['阻塞性胆道疾病', '胆道疾病', '阻塞性胆道疾病'],
  //             department_name: '内科',
  //           },
  //           {
  //             date: 1633708800000,
  //             diagnosis: ['阻塞性胆道疾病', '胆道疾病'],
  //             department_name: '消化科',
  //           },
  //           {
  //             date: 1602172800000,
  //             diagnosis: ['阻塞性胆道疾病'],
  //             department_name: '神经外科',
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v2/patients/history_diag`, {params: payload})
}

// 血气列表
export async function get_flesh_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         list: [
  //           {
  //             report_time: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             natural_conclusion: '结论一',
  //             diagnosis_name:'血气分析'
  //           },
  //           {
  //             report_time: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             natural_conclusion: '结论二',
  //             diagnosis_name: '血气分析'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/bga/list`,{params: payload})
}
// 温馨提示
export async function get_trigger_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         list: [
  //           {
  //             application_name: '名字一',
  //             standard_id: 127,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色的更好的更好的更好的'
  //           },
  //           {
  //             application_name: '名字二',
  //             standard_id: 2,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/${payload}/applications`)
}

export async function post_warning(payload) {

  return api.post(`/api/v2/warnings/cancel`,payload)
}

export async function other_like_api(payload) {
  return api.post(`/api/v2/reports/laboratory/tagging`,payload)
}

// 获取grace报告列表
export async function get_grace_list(payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         data: [
  //           {
  //             date: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             level: '高危',
  //             score: 124,
  //           },
  //           {
  //             date: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             level: '中危',
  //             score: 125,
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/grace/${payload}/history`)
}

export async function get_application(payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         applications: [
  //           {
  //             icon: '',
  //             id: 1,
  //             name: '血气分析',
  //           },
  //           {
  //             icon: '',
  //             id: 2,
  //             name: 'GRACE评分',
  //           },
  //           {
  //             icon: '',
  //             id: 3,
  //             name: '渗出液/漏出液',
  //           },
  //         ],
  //         my_applications: [
  //           {
  //             icon: '',
  //             id: 1,
  //             name: '血气分析',
  //             sort: 2,
  //             hint: '',
  //             highlight: true,
  //           },
  //           {
  //             icon: '',
  //             id: 3,
  //             name: '渗出液/漏出液',
  //           },
  //         ]
  //       }
  //     })
  //   },500)
  // })
  //屏蔽肾上腺应用
  return api.get(`/api/v2/application/doctor`, {params: payload})
}


// 获取渗出液模型报告列表
export async function get_exudate_list(payload) {
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         data: [

  //           {
  //             date: new Date().getTime(),
  //             report_id: '143267_aa76485cf61e9f21a8a4449dc368f212',
  //             result: '结论一'
  //           },
  //           {
  //             date: new Date('2021/12/1').getTime(),
  //             report_id: '40336_41bdf3d1ea2d2537c651c9e538bcc28f',
  //             result: '结论二'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/model/exudate/${payload}/history`)
}

//ckd列表
export async function get_ckd_list(payload) {
  return api.get(`/api/v2/model/ckd/${payload}/history`)
}

// patient_id转化
export async function trans_patient_id(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: '99999'
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v2/reading2/patients/${payload}/translateId`)
}



