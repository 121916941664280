// 角色
export const role_arr = [
  {
    label: '检验科',
    value: 1
  },
  {
    label: '账号管理员',
    value: 2
  },
  {
    label: '日志管理员',
    value: 3
  },
  {
    label: '临床科室',
    value: 4
  },
  {
    label: '运维管理员',
    value: 6
  },
  {
    label: '智慧注解系统管理员',
    value: 7
  },
  {
    label: '科研管理员',
    value: 8
  },
]

// 用户状态
export const user_state_arr = [
  {
    label: '正常',
    value: 1
  },
  {
    label: '锁定',
    value: 2
  }
]
