import router from "../../router"
import {decrypt} from '../../utils/aes'
import { get_application,get_trigger_list } from "./service";

export default {
  namespaced: true,
  state: {
    //我的应用集
    applications: {},
    trigger_list: [],
    applications_copy: {},
    other_applications: []
  },
  getters: {
    decrypt_query () {
      if (router.app.$route.query.encrypt) {
        return decrypt(router.app.$route.query.encrypt, false)
      }
      return {}
    },
    doctor_id(state, getter) {
      return getter.decrypt_query.doctor_id
    },
    patient_id(state, getter) {
      return getter.decrypt_query.id
    },
    report_id() {
      return router.app.$route.query.report_id
    },
    report_date(state, getter) {
      return getter.decrypt_query.report_date
    },
    // 监听url地址是否刷新
    url_gaid(state, getter) {
      return getter.decrypt_query.url_gaid
    }
  },
  mutations: {
    updateApplication(state, payload) {
      state.applications = payload.data
      state.applications_copy = JSON.parse(JSON.stringify(payload.data))
    },
    hander_application(state, payload) {
      if(!state.applications_copy.my_applications.map(item=>{
        return item.application_id
      }).includes(payload.application_id)) {
        state.applications_copy.my_applications.unshift(payload)
      }
    },
    update_app_copy(state, payload) {
      const ids = state.applications_copy.my_applications.map(it=>{return it.application_id})
      state.applications_copy.my_applications.splice(ids.indexOf(payload.application_id), 1);
    },
    update_applications_copy(state, payload) {
      state.applications_copy.my_applications = payload.concat(state.applications_copy.my_applications)
    },
    update_trigger_list(state, payload) {
      state.trigger_list = payload.data.applications
    },
    save_other_list(state, payload) {
      state.other_applications = payload
    }
  },
  actions: {
    //获取我的应用集
    async get_my_application({ commit }, payload) {
      const { data } = await get_application(payload)
      commit({
        type: 'updateApplication',
        data
      })
    },
    // 应用集提醒
    async search_trigger_list({ commit }, payload) {
      if(!payload.report_id) return
      const { data } = await get_trigger_list(payload.report_id)
      commit({
        type: 'update_trigger_list',
        data,
      })
      return { data }
    },
  }
}
