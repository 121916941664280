// 数据格式化
import format from 'date-fns/format'

// 完整时间：yyyy-MM-dd HH:mm:ss
export function format_date (timestamp, str = 'yyyy-MM-dd') {
  return timestamp && format(+timestamp, str)
}

// 小数转百分比
export function format_decimal (number = 0, num = 2, scale = 100) {
  return +((+number * scale).toFixed(num))
}

// 随时生成id
export function create_guid() {
  return (Math.random()*10000000).toString(16).substring(0,4)+'-'+(new Date()).getTime()+'-'+Math.random().toString().substring(2,7)
}

// 数字格式
export function format_number (num, unit) {
  if (!num) return 0
  if (num > 999 && unit) {
    let str = unit === 1000?'K':unit===10000?'W':''
    return num/unit + str
  }
  let str = `${num}`
  let len = str.length
  if (len === 3) {
    return str
  }
  let str2 = ''
  let max = Math.floor(len / 3)
  if (len % 3 === 0) {
    max = max - 1
  }
  for (let i = 0; i < max; i++) {
    let s = str.slice(len - 3, len)
    str = str.substr(0, len - 3)
    str2 = (',' + s) + str2
    len = str.length
  }
  str += str2
  return str
}

// 数组转标签
export function arr_label(arr, props) {
  props = {
    label: 'label',
    value: 'value',
    children: 'children',
    re: true,
    ...props
  }
  const obj = {}
  function re(arr) {
    if (arr && arr.length > 0) {
      for (let item of arr) {
        obj[item[props.value]] = item[props.label]
        if (props.re) re(item[props.children])
      }
    }
  }
  re(arr)
  return obj
}

// 数组转对象
export function arr_obj(arr, props) {
  props = {
    value: 'value',
    children: 'children',
    re: true,
    ...props
  }
  const obj = {}
  function re(arr) {
    if (arr && arr.length > 0) {
      for (let item of arr) {
        obj[item[props.value]] = item
        if (props.re) re(item[props.children])
      }
    }
  }
  re(arr)
  return obj
}
// 复制
export function copyToClip(content) {
  var aux = document.createElement("input");
  aux.setAttribute("value", content);
  document.body.appendChild(aux);
  aux.select();
  document.execCommand("copy");
  document.body.removeChild(aux);
}

// url参数
export function get_url_params (url = location.href) {
  let data = {}
  if (url.includes('?')) {
    let query = url.split('?')[1]
    let vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=')
      data[pair[0]] = pair[1]
    }
  }
  return data
}

// url路径
export function get_url_path (url = location.href) {
  if (url.includes('?')) {
    url = url.split('?')[0]
  }
  return url.match(/\/[^/]*$/) && url.match(/\/[^/]*$/)[0]
}
