<template>
  <div :class="['out',{'out1': $route.meta.source === 'patch'}]">

    <div class="bg" @click="close"></div>
    <div class="live" v-loading="loading">
      <div class="title" v-if="data.length>0">
        <span>我常用的</span>
        <el-button type="primary" size="small" @click="add">+ 新增方案</el-button>
      </div>
      <div class="body">
      <div class="no-data" v-if="data.length===0&&!loading">
        <img src="../../../assets/img/detail/img_no_data.png" width="220" height="220"/>
          <div class="info">暂无方案</div>
          辅助医生快速浏览多指标趋势变化
        <el-button type="primary" style="margin-top: 10px" size="small" @click="add">+ 新增方案</el-button>
      </div>
      <template v-else>
       <div v-for="i in data" :key="i.plan_id" class="plan-item" >
        <div @click="show_trend(i)" style="cursor: pointer;">
          <div class="plan-name">{{i.plan_name}}</div>
          <div class="lab-name">{{i.name_list&&i.name_list.join('、')}}</div>
        </div>
        <ul>
          <el-button type="text" style="color: #1890FF;" @click="del_item(i)">删除</el-button>
          <el-button type="text" style="color: #1890FF;" @click="edit_item(i)">编辑</el-button>
        </ul>
       </div>
      </template>
    </div>
    </div>
    <!-- 编辑方案名称 -->
    <Plan
    :visible.sync="plan_visible"
    @refresh="refresh"
    :patient_id="patient_id"
    :item_obj="item_obj"
    v-if="plan_visible"/>
    <!-- 显示趋势历史 -->
     <Trend
      :visible.sync="trend_visible"
      v-if="trend_visible"
      :plan_id="plan_id"
      :patient_id="patient_id"
    />
  </div>
</template>
<script>
import { get_data,to_del_item } from './service'
import Plan from '../plan'
import Trend from '../trend'
import { user_action } from "../../../common-data/service";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    patient_id:{
      type: [String,Number],
      default: ''
    },
  },
  data () {
    return {
      loading: false,
      open_source: GLOBAL_CONFIG._open_source,
      data: [],
      plan_visible: false,
      plan_id: '',
      plan_name: '',
      trend_visible: false,
      item_obj: {}
    }
  },
  computed: {
  },
  components: {
    Plan,
    Trend
  },
  watch: {
  },
  created() {
    this.search_data()
  },
  methods: {
    async search_data () {
      this.loading = true
      try {
        const {data} = await get_data()
        for(let i of data.data) {
          i.name_list = i.laboratories.map(el=>{
            return el.original_specimen + (el.original_specimen ? '-'  : '') + el.original_laboratory_name
          })
        }
        this.data = data.data
      } finally {
        this.loading = false
      }
    },
    async confirm_del (i) {
      this.loading = true
      try {
        await to_del_item(i.plan_id)
        this.search_data()
      } finally {
        this.loading = false
      }
    },
    close () {
      this.$emit('update:visible', false)
    },
    del_item(i) {
      this.$confirm("是否确认删除？", '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.confirm_del(i)
        })
        .catch(() => {});

    },
    edit_item(i) {
      let obj = JSON.parse(JSON.stringify(i))
      this.item_obj =obj
      this.plan_visible = true
    },
    add () {
      this.item_obj ={}
      this.plan_visible = true
    },
    refresh() {
      this.search_data()
    },
    show_trend(val) {
      this.plan_id = val.plan_id
      this.trend_visible = true
      // 埋点
      user_action({
        action_id: 22,
        extra_data: {
          plan_id: this.plan_id,
          patient_id: this.patient_id,
        }
      })
    }
  }
}
</script>
<style scoped>

@-webkit-keyframes live-in {
  0% {
    transform: translate(100%, 0)
  }

  100% {
    transform: translate(0, 0)
  }
}

@-webkit-keyframes live-out {
  0% {
    transform: translate(0, 0)
  }

  100% {
    transform: translate(0, 100%)
  }
}

.out.live {
  animation: live-in .3s 1ms
}
.out.live{
  animation: live-out .3s
}
.out {
  position: absolute;
  top: 64px;
  left: 0;
  right: 60px;
  bottom: 0;
  z-index: 1000;
  transition: all .3s ease-in-out;
  &.out1 {
    top: 0;
  }
  & .bg {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0px;
    bottom: 0;
    /* background: rgba(0,0,0, 0.5); */
  }
  & .live {
    position: absolute;
    bottom: 0px;
    right: 0px;
    top: 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 291px;
    background: rgba(255, 255, 255, 1);
    box-shadow: -3px 4px 16px rgba(0, 0, 0, 0.24);
    padding: 14px 16px;
    overflow: auto;
  }
}
.body{
  flex:1;
  overflow: auto;

}
.title {
  font-size: 16px;
  line-height: 22px;
  color: #060A21;
  font-weight: bold;
  display: flex;
  align-items: center;
  & >span {
    flex: 1;
    display: inline-block;
  }
}
 .no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
  font-size: 14px;
  line-height: 20px;
  color: #606266;
  & >img {
    margin-bottom: 12px;
  }
  & .info {
    font-weight: 600;
    line-height: 22px;
    color: #606266;
    padding-bottom: 4px;

  }
}
.plan-item {
  & .plan-name {
    font-size: 16px;
    line-height: 22px;
    color: #060A21;
    font-weight: bold;
    padding: 26px 0 8px;
  }
  & .lab-name {
    font-size: 14px;
    line-height: 20px;
    color: #707070;

  }
}

</style>
