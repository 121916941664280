<template>
  <div :class="['rich_comp', {no_border: style_type === 2}]">
    <div v-if="style_type === 2" class="html" v-html="value"></div>
    <template v-else-if="style_type === 1">
      <!-- 菜单 -->
      <Menu
        :editor="editor"
        :menu_options="menu_options"
        @select="select_menu"
      />
      <!-- 编辑区域 -->
      <Editor
        class="editor"
        ref="editor"
        :value="value"
        @input="input"
        :placeholder="placeholder"
      />
    </template>
  </div>
</template>
<script>
import Menu from './Menu'
import Editor from './Editor'

export default {
  props: {
    style_type: {
      default: 1,
      type: Number
    },
    value: {
      default: '',
      type: String
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    menu_options: {
      type: Array,
      default: () => ['bold']
    },
  },
  data () {
    return {
      editor: null,
    }
  },
  computed: {
  },
  watch: {
  },
  components: {
    Editor,
    Menu,
  },
  created () {
  },
  mounted() {
    this.editor = this.$refs['editor']
  },
  methods: {
    input (val) {
      this.$emit('input', val)
    },
    // 插入内容
    insert (node) {
      this.editor.insert(node)
    },
    // 菜单选择
    select_menu ({type, data}) {
      // 图片
      if (type === 'img') {
        this.insert(data)
        return
      }
      // 加粗
      if (type === 'bold') {
        this.editor.exec_command(type)
        return
      }
    },
  }
}
</script>
<style scoped>
.rich_comp {
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 1.5;
}
.no_border {
  border: none;
}
.html {
  tab-size: 4;
  white-space: pre-wrap;
  word-wrap: break-word;
}
>>>.html {
  & img {
    max-width: 100%!important;
    height: auto;
  }
}
</style>
