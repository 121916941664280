<template>
  <table class="table" cellspacing="0" cellpadding="0" border="0">
    <tbody>
      <tr>
        <td colspan="4" rowspan="4" class="name">GFR与尿蛋白对慢性肾脏病<br/>预后的影响类别</td>
        <td :colspan="x_titles.length" class="title">尿白蛋白</td>
      </tr>
      <tr>
        <td v-for="(x, index) in x_titles"
          :key="index"
          class="name">{{x.x_name}}</td>
      </tr>
      <tr>
        <td v-for="(x, index) in x_titles"
          :key="index"><span  v-html="x.standard_1"/></td>
      </tr>
      <tr>
        <td v-for="(x, index) in x_titles"
          :key="index"><span  v-html="x.standard_2"/></td>
      </tr>
      <tr
        v-for="(y, index2) in y_titles"
        :key="index2"
        >
        <td
          :rowspan="y_titles.length"
          width="89"
          class="title"
          v-if="index2 === 0">GFR</td>
        <td class="name pad">{{y.y_name}}</td>
        <td><span v-html="y.standard_1"/></td>
        <td class="pad"><span v-html="y.standard_2"/></td>
        <td
          v-for="(x, index3) in x_titles"
          :key="index3"
           >
          <div class="value" :style="{background:hander_color(data[`${y.y_name}${x.x_name}`])}">
            <span>{{data[`${y.y_name}${x.x_name}`]}}</span>
            <img v-if="(params.lab_x + params.lab_y)===`${y.y_name}${x.x_name}`" class="patient-position" src="../../../assets/img/patient-position.png"/>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    params:{
      default:()=>{},
      type: Object
    }
  },
  data() {
    return {
      degree: true,
      x_titles: [
        {x_name: 'A1',standard_1: '正常或轻度增加',standard_2: '<30mg/g<br/><30mg/mmol'},
        {x_name: 'A2',standard_1: '中度增多',standard_2: '30-300mg/g<br/>3-300mg/mmol'},
        {x_name: 'A3',standard_1: '重度增加',standard_2: '>300mg/g<br/>>30mg/mmol'}
      ],
      y_titles: [
        {y_name: 'G1',standard_1: '肾小球滤过率<br>正常',standard_2: '≥ 90'},
        {y_name: 'G2',standard_1: '肾小球滤过率<br>轻度降低',standard_2: '60~89'},
        {y_name: 'G3a',standard_1: '肾小球滤过率<br>轻度~中度降低',standard_2: '45~59'},
        {y_name: 'G3b',standard_1: '肾小球滤过率<br>中度~重度降低',standard_2: '30~44'},
        {y_name: 'G4',standard_1: '肾小球滤过率<br>重度降低',standard_2: '15~29'},
        {y_name: 'G5',standard_1: '肾衰',standard_2: '< 15'}
      ],
      data: {
        G1A1: '低危',
        G1A2: '中危',
        G1A3: '高危',
        G2A1: '低危',
        G2A2: '中危',
        G2A3: '高危',
        G3aA1: '中危',
        G3aA2: '高危',
        G3aA3: '极高危',
        G3bA1: '高危',
        G3bA2: '极高危',
        G3bA3: '极高危',
        G4A1: '极高危',
        G4A2: '极高危',
        G4A3: '极高危',
        G5A1: '极高危',
        G5A2: '极高危',
        G5A3: '极高危'
      }
    }
  },
  components:{
  },
  created() {
  },
  methods: {
    hander_color(name) {
      let color = '#1890FF'
      switch(name) {
      case '极高危':
        color="#FA5C7C";
        break
      case '高危':
        color="#FFBC00";
        break
      case '中危':
        color="#2C8EF8";
        break
      default:
        color ="#1890FF"
      }
      return color
    },
  }
}
</script>
<style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #6C757D;
    line-height: 22px;
    & td{
      border:1px solid #E3EAEF;
      height: 60px;
    }
    & .title {
      background: #F3F6F8;
    }
    & .value{
      color: #FFF;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    & .name {
      font-size: 18px;
    }
    & .pad {
      padding:0 10px;
    }
  }

  .patient-position {
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    right: 0px;
  }
</style>
