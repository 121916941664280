import {get_detail, get_timeline, get_report} from './service'
export default {
  namespaced: true,
  state: {
    loading: false,
    data: null,
    time_data: [],
    patient: null,
    ai_report_id: '',
    test_list: [], // 报告单列表
  },
  mutations: {
    clearState(state) {
      state.loading = false
      state.data = null
      state.time_data = []
      state.patient = null
      state.ai_report_id = ''
      state.test_list = []
    },
    updateData(state, payload) {
      state.data = payload.data
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    },
    updateTime(state, payload) {
      state.time_data = payload.data.days
    },
    updatePatient(state, payload) {
      state.patient = payload.data.patient
    },
    update_ai_report_id(state, payload) {
      state.ai_report_id = payload
    },
    update_test_list(state, payload) {
      state.test_list = payload.data.reports
    },
  },
  actions: {
    async get_report_detail({ commit }, payload) {
      commit({
        type: 'showLoading'
      })
      try {
        const { data } = await get_detail(payload)
        commit({
          type: 'updateData',
          data
        })

      } finally {
        commit({
          type: 'hideLoading'
        })
      }
    },
    async get_report_time({ commit }, payload) {
      commit({
        type: 'showLoading'
      })
      const { data } = await get_timeline(payload)
      commit({
        type: 'updateTime',
        data
      })
      commit({
        type: 'updatePatient',
        data
      })
      commit({
        type: 'hideLoading'
      })
    },
    async get_test_list({ commit }, payload) {
      commit({
        type: 'showLoading'
      })
      const { data } = await get_report(payload)
      commit({
        type: 'update_test_list',
        data
      })
      commit({
        type: 'hideLoading'
      })
    },
  }
}

