// 页面数据
export const global_page_data = {}

export default {
  namespaced: true,
  state: {
    max: 5, // 最大缓存页面
    include: [], // 缓存哪些页面
  },
  mutations: {
    update_keep_alive (state, payload) {
      const to = payload.to || {}
      const from = payload.from || {}
      const routes = payload.routes || []
      const to_name = to.meta?.name
      const from_name = from.meta?.name

      // 如果要to(进入)的页面是需要keepAlive缓存的
      if (to.meta?.keep_alive) {
        !state.include.includes(to_name) && state.include.push(to_name)
      }

      // 如果要form(离开)的页面是keepAlive缓存的
      // 再根据deepth来判断是前进还是后退
      // 如果是后退：
      const to_deepth = (!to.meta?.deepth && to.meta?.deepth !== 0) ? 1 : to.meta?.deepth
      const from_deepth = (!from.meta?.deepth && from.meta?.deepth !== 0) ? 1 : from.meta?.deepth
      // 同一页面之间跳转，deepth会一样
      if (from.meta?.keep_alive && to_deepth <= from_deepth && to_name !== from_name) {
        const index = state.include.indexOf(from_name)
        index !== -1 && state.include.splice(index, 1)
      }

      // 如果回到了首页，那么缓存为空
      if (to.meta?.deepth === 0) {
        state.include = []
      }

      // 更新父view
      if (state.include.length) {
        const children_view = (childrens) => {
          for (const item of childrens) {
            const name = item.meta?.name
            const children = item.children || []
            if (children.length) {
              if (children_view(children)) {
                !state.include.includes(name) && state.include.push(name)
                return true
              } else {
                const index = state.include.indexOf(name)
                index !== -1 && state.include.splice(index, 1)
              }
            }
            if (name && state.include.includes(name)) {
              return true
            }
          }
          return false
        }
        children_view(routes)
      }

      // 更新页面数据
      // 保存滚动条信息
      if (state.include.includes(from_name)) {
        // 同一个页面不用保存
        if (to_name === from_name) {
          delete global_page_data[from_name]
          return
        }
        const classname = from.meta.scroll_seletor || '.keep_alive'
        global_page_data[from_name] = {
          classname: classname,
          scrolls: [],
        }
        const keep_alives = [...document.querySelectorAll(classname)]
        for (const item of keep_alives) {
          global_page_data[from_name].scrolls.push([item.scrollLeft, item.scrollTop])
        }
      }

      // 清除无用滚动条信息
      for (const name in global_page_data) {
        if (!state.include.includes(name)) {
          delete global_page_data[name]
        }
      }
    },
    // 删除页面缓存
    delete_page (state, payload) {
      const index = state.include.indexOf(payload)
      index !== -1 && state.include.splice(index, 1)
      delete global_page_data[payload]
    },
  },
  actions: {
  }
}

// 恢复页面
export function restore_page (page_name) {
  const data = global_page_data[page_name]
  if (!data) return
  const classname = data.classname
  const scrolls = data.scrolls

  const keep_alives = [...document.querySelectorAll(classname)]
  let index = 0
  for (const item of keep_alives) {
    item.scrollLeft = scrolls[index] && scrolls[index][0]
    item.scrollTop = scrolls[index] && scrolls[index][1]
    index++
  }
}
