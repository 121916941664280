<template>
  <div class="rich_menu">
    <!-- 特殊注意，按钮必须是button，否则在IE浏览器上会影响选区，user-select: none;无效 -->
    <!-- 图片插入 -->
    <button
      v-if="menu_options.includes('img')"
      class="item"
      @click="insert_img">
      <svg style="width: 20px; height: 20px;" viewBox="0 0 20 20">
        <path d="M6.91 10.54c.26-.23.64-.21.88.03l3.36 3.14 2.23-2.06a.64.64 0 0 1 .87 0l2.52 2.97V4.5H3.2v10.12l3.71-4.08zm10.27-7.51c.6 0 1.09.47 1.09 1.05v11.84c0 .59-.49 1.06-1.09 1.06H2.79c-.6 0-1.09-.47-1.09-1.06V4.08c0-.58.49-1.05 1.1-1.05h14.38zm-5.22 5.56a1.96 1.96 0 1 1 3.4-1.96 1.96 1.96 0 0 1-3.4 1.96z"></path>
      </svg>
      <input style="display: none;" type="file" ref="img_file" v-on:change="img_change" accept="image/*" />
    </button>
    <!-- 字体加粗 -->
    <button
      v-if="menu_options.includes('bold')"
      :class="['item', {active: is_bold}]"
      @click="select('bold')">
      <svg style="width: 20px; height: 20px;" viewBox="0 0 1024 1024">
        <path d="M707.872 484.64A254.88 254.88 0 0 0 768 320c0-141.152-114.848-256-256-256H192v896h384c141.152 0 256-114.848 256-256a256.096 256.096 0 0 0-124.128-219.36zM384 192h101.504c55.968 0 101.504 57.408 101.504 128s-45.536 128-101.504 128H384V192z m159.008 640H384v-256h159.008c58.464 0 106.016 57.408 106.016 128s-47.552 128-106.016 128z"></path>
      </svg>
    </button>
  </div>
</template>
<script>
export default {
  props: {
    menu_options: {
      type: Array,
      default: () => ['img', 'bold']
    },
    editor: {
      type: null,
      default: null
    },
  },
  data () {
    return {
      is_bold: false, // 是否加粗
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  mounted() {
    this.img_file = this.$refs['img_file']
    document.addEventListener('selectionchange', this.selectionchange, false)
  },
  destroyed () {
    document.removeEventListener('selectionchange', this.selectionchange, false)
  },
  methods: {
    has_parent(node, parent_html = []) {
      let flag = false
      while (node) {
        if (this.editor === node) {
          break
        }
        if (node.nodeType === 1 && parent_html.includes(node.nodeName.toUpperCase())) {
          flag = true
          break
        }
        node = node.parentNode
      }
      return flag
    },
    // 选择变化
    selectionchange () {
      // 清空状态
      this.is_bold = false

      const range = this.editor && this.editor.get_range()
      if (range) {
        let {startContainer} = range
        const s_flag = this.has_parent(startContainer, ['B', 'STRONG'])
        if (s_flag) {
          this.is_bold = true
        }
      }
    },
    // 插入图片
    insert_img () {
      this.img_file.click()
    },
    img_change () {
      let file = this.img_file.files[0]
      if (!file) return null
      // 大小限制
      if (file.size > 1048576) {
        this.$notify({
          title: '上传图片必须小于1M',
          type: 'error'
        })
        return
      }
      // 必须清空，否则两次上传相同图片不能触发change
      this.img_file.value = null
      // 生成一个文件读取的对象
      const reader = new FileReader()
      reader.onload = (ev) => {
        // base64码
        let img_base64 = ev.target.result
        var img = document.createElement('img');
        img.setAttribute('src', img_base64);
        this.$emit('select', {
          type: 'img',
          data: img,
        })
      }
      //发起异步读取文件请求，读取结果为data:url的字符串形式，
      reader.readAsDataURL(file)
    },
    // 字体加粗
    select (type) {
      this.$emit('select', {
        type
      })
    }
  }
}
</script>

<style scoped>
.rich_menu {
  user-select: none;
  height: 40px;
  background: #eee;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  & .item {
    width: 32px;
    height: 32px;
    margin: 0 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      background: #ddd;
    }
    &.active {
      background: #ccc;
    }
  }
}
</style>
