import {get_wikipedia,get_application_detail} from './service'
import {baseURL} from '../../../utils/api'
import {hander_commom_menus} from './config'
export default {
  namespaced: true,
  state: {
    data: {},//详情
    list: [],//左边列表
    menus:[],//头部菜单
    loading: false,
    select_key: null,//头部菜单显示部分
    current_report_id: '',//当前id
    no_data_obj: null,//没有值应用数据
    current_item: null,//应用参数
    name: '',//诊断名称
    application_id: '',//应用id
    change_init_params: null,//初始参数变化
  },
  getters: {
    is_has_data(state) {
      const {current_report_id,loading} = state
      return current_report_id && !loading
    },
  },
  mutations: {
    clear_data(state) {
      state.loading = false
      state.list = []
      state.data={}
      state.menus = []
      state.no_data_obj= null
      state.current_item = null
      state.change_init_params = null
    },
    show_loading (state) {
      state.loading =  true
    },
    hide_loading (state) {
      state.loading = false
    },
    update_data(state,payload) {
      //更新详情
      let data = payload
      if(state.data.api_type<3)data = payload.data
      state.data = {api_type: state.data.api_type,...data}
    },
    t_update_list(state,payload) {
      //左侧列表值
      state.list = payload.data
    },
    update_menus(state,payload) {
      //更新头部菜单
      let data = payload
      if(state.data.api_type<3)data = payload.data
      state.menus = data
    },
    init_data(state,payload) {
      //初始化传入参数
      state.current_item = payload.current_item
      state.application_id = payload.application_id
      state.current_report_id = payload.current_report_id
      state.name = payload.name
      state.data = payload.data
    },
    update_current_id(state,payload) {
      state.current_report_id = payload
    },
    update_current_item(state,payload) {
      //更新应用信息
      state.current_item = payload
      state.application_id = payload.application_id
    },
    update_select_key(state,payload) {
      //更新头部菜单显示
      state.select_key = payload
    },
    update_no_data(state,payload) {
      state.no_data_obj = payload.data
    },
    change_item_detail(state,payload) {
      //初始化传入对象用于变化申请左侧列表
      state.change_init_params = payload
    }
  },
  actions: {
    async search_wikipedia({ commit }, payload) {
      //普通诊断的详情接口
      commit({
        type: 'show_loading',
      })
      try {
        const {data} = await get_wikipedia(payload)
        const {roc,shap,performances=[],laboratories=[],api_type} = data
        let list = []
        if(laboratories&&laboratories.length>0) list.push({key: 'laboratories',name: '诊断依据'})
        list = hander_commom_menus(data,list)
        if(performances && performances.length>0 && api_type === 2)list.push({key: 'performances',name: '性能'})
        if(roc && api_type === 2)list.push({key: 'roc',name: 'ROC曲线'})
        if(shap && api_type === 2)list.push({key: 'shap',name: 'SHAP图'})
        if(shap) data.hander_shap = baseURL + `/api/v2/images/shap/${shap}`
        if(roc) data.hander_roc = baseURL + `/api/v2/images/roc/${roc}`

        if(data.api_type === 1) {
          let diagnosis = [
            {
              name: data.diagnosis_display_name,
              key: data.id
            }
          ]
          let laboratories_1 = data.laboratories || []
          let links = []
          for(let i of laboratories_1) {
            i.key = i.laboratory_id
            links.push({
              source: data.id,
              target:i.laboratory_id,
              knowledges: [],
            })
          }
          diagnosis = diagnosis.map((item, index) => {
            item._type = 2
            item._name = item.name
            item._index = index
            return item
          })
          laboratories_1 = laboratories_1.map((item, index) => {
            item._type = 3
            item._name = item.original_laboratory_name + (item.original_specimen ? `（${item.original_specimen}）` : '')
            item._index = index
            return item
          })
          data.current_key = diagnosis[0].key
          data.nodes = JSON.parse(JSON.stringify([ ...diagnosis, ...laboratories_1]))
          data.links = JSON.parse(JSON.stringify(links))
        }
        commit({
          type: 'update_menus',
          data: list
        })
        commit({
          type: 'update_data',
          data
        })
      } finally {
        commit({
          type: 'hide_loading',
        })
      }
    },
    async search_no_data_detail({rootState, commit }, payload) {
      //无数据申请
      const {data} = rootState.DiagnosisDialog
      //判断无数据申请条件
      if(![1,2].includes(data.api_type)&&payload) {
        try {
          commit({
            type: 'show_loading',
          })
          const {data} = await get_application_detail(payload)
          commit({
            type: 'update_no_data',
            data
          })
        } finally {
          commit({
            type: 'hide_loading',
          })
        }
      }
    },
    async update_list({commit}, payload)  {
      //更新列表
      commit({
        type: 't_update_list',
        data: payload
      })
    }
  }
}
