import api from '../../../utils/api'

// 获取化验项详情
export async function add_data (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/plan/add',payload)
}
export async function edit_data (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/plan/edit',payload)
}
export async function get_item_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         items: [
  //           '红细胞计数',
  //           '结晶[仪器]',
  //           '血',
  //           '尿'
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/system/es/lab_specimen_unit',{params: payload})
}


