// 系统标识
let system_flag = 'huijian_pc'

export function set_system_flag (flag ) {
  system_flag = flag
}

export function get_system_key (key) {
  return `${system_flag}_${key}`
}

// 用户信息
export function removeSession (key = 'session') {
  localStorage.removeItem(get_system_key(key))
}

export function setSession (session, key = 'session') {
  localStorage.setItem(get_system_key(key), JSON.stringify(session))
}

export function getSession (key = 'session') {
  return JSON.parse(localStorage.getItem(get_system_key(key)))
}

export function setPersonalData (key, value) {
  const authToken = getSession()
  if(authToken){
    const id = authToken.user_id
    const newKey = `personal_${id}_${key}`
    localStorage.setItem(get_system_key(newKey), JSON.stringify(value))
  }
}

export function getPersonalData(key) {
  const authToken = getSession()
  const id = authToken.user_id
  const newKey = `personal_${id}_${key}`
  return JSON.parse(localStorage.getItem(get_system_key(newKey)))
}

export function removePersonalData(key) {
  const authToken = getSession()
  if(authToken){
    const id = authToken.user_id
    const newKey = `personal_${id}_${key}`
    localStorage.removeItem(get_system_key(newKey))
  }
}
