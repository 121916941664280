export default {
  namespaced: true,
  state: {
    diagnosis_name: '',
    project_obj:{}
  },
  mutations: {
    update_diagnosis_name (state, payload) {
      state.diagnosis_name = payload
    },
    update_project_obj (state, payload) {
      state.project_obj = payload
    },
  },
  actions: {

  }
}
