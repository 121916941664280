<template>
  <div class="breadcrumb-box" v-if="is_show">
    <div>
      <div class="back-box" @click="back" v-if="$route.meta.is_back">
        <div class="line">&lt;</div>
        <div :class="['breadcrumb-label']">返回</div>
      </div>
      <el-breadcrumb v-else class="g-breadcrumb" separator="/">
        <el-breadcrumb-item v-for="{path, name} in breadcrumb" :key="path" :to="{ path: path }">{{name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
    }
  },
  components: {
  },
  computed: {
    ...mapState('commonData', {
      breadcrumb: state => state.breadcrumb,
    }),
    is_show() {
      return this.breadcrumb &&
        this.breadcrumb.length > 1 &&
        this.$route.name !== '首页'
    }
  },
  watch: {
    $route () {
      this.breadcrumbInit()
    }
  },
  created () {
    this.breadcrumbInit()
  },
  methods: {
    ...mapMutations('commonData', [
      'setBreadcrumb'
    ]),
    breadcrumbInit () {
      if (this.$route.meta.customBreadcrumb)  return // 自定义的话，直接跳出

      let breadcrumb = []
      if (this.$route.meta.history) {
        let isHas = true
        breadcrumb = this.breadcrumb.filter(({name}) => {
          if (name === this.$route.name) isHas = false
          return isHas
        })
        breadcrumb.push({
          path: this.$route.fullPath,
          name: this.$route.name
        })
      } else {
        const newR = this.$route.matched.filter(({ path, name }) => {
          return path !== '/' && name
        }).map(({ path, name }) => {
          if (name === this.$route.name) path = this.$route.fullPath
          return { path, name }
        })
        // newR.unshift({
        //   path: '/',
        //   name: '首页'
        // })
        breadcrumb = newR
      }
      this.setBreadcrumb(breadcrumb)
    },
    back () {
      this.$router.go(-1)
    }
  }
}
</script>
<style>
  .g-breadcrumb .el-breadcrumb__inner, .breadcrumb {
    color: #1890FF;
    font-weight: 500;
  }
  .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
    color: #98A6AD;
  }
  .g-breadcrumb .el-breadcrumb__separator {
    color: #98A6AD;
    margin: 0 8px;
  }
  .g-breadcrumb .el-breadcrumb__separator {
    color: #98A6AD;
  }
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .breadcrumb-box{
    display: flex;
    align-items: center;
  }
  .back-box{
    display: flex;
    align-items: center;
    width: 100px;
    height: 48px;
    cursor: pointer;
  }
  .breadcrumb-label {
    font-size: 14px;
    line-height: 1;
    color: #34A853;
  }
  .line {
    color: #34A853;
    margin: -2px 8px 0px;
  }
</style>
