<template>
  <table class="table" cellspacing="0" cellpadding="0" border="0">
    <tbody>
      <tr>
        <td rowspan="2" class="first">
         <span class="title1"></span>
         <br>
         <span class="title2"></span><br>
        </td>
      </tr>
      <tr>
        <td
          style="background: #F2F8FF"
          v-for="(x, index) in x_titles"
          :key="index"
          class="title">{{x.name}}</td>
      </tr>
      <tr
        v-for="(y, index2) in data"
        :key="index2"
        >
        <td class="title">{{y.name}}</td>

        <td
          style="cursor: pointer; "
          v-for="(x, index3) in x_titles"
          :key="index3"
          class="value">{{y[x.key]}}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    data: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {
      x_titles: [{name: '训练集',key: 'train_value'},{name:'测试集',key: 'test_value'}]
    }
  },
  components:{
  },
  created() {
  },
  methods: {
  }
}
</script>
<style scoped>
  .table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    color: #303133;
    & td{
      border:1px solid #E9E9E9;
      height:40px;
    }
    & .title {
      font-weight: 400;
      color: #303133
    }
    & .value{
      background:#fff;
    }
  }
.first {
  border:1px solid #E9E9E9;
  text-align: center;
	height: 40px; /*这里需要自己调整，根据自己的需求调整高度*/
	position: relative;
  background: #F2F8FF;
  color: #303133;
}
td[class=first]{
	width: 200px;
}
td[class=first]:before {
	content: "";
    position: absolute;
    width: 1px;
    height: 206px;
    top: 0;
    left: -1px;
    background-color: #E9E9E9;
    display: block;
    transform: rotate(-76.5deg);
    transform-origin: top;

}
.title1{
	position: absolute;
	top: 9px;
  right:2px;
  line-height: 22px;
}
.title2{
	position: absolute;
	bottom: 2px;
	left:1px;
  line-height: 22px;
}
</style>
