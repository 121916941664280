let router_names = []

// 插入历史
export function pushState (name) {
  router_names.push(name)
  window.history.pushState(null, null)
}

// 监听事件
window.addEventListener('popstate', () => {
  const name = router_names.pop()
  let ev = null
  try {
    ev = new Event(name, {'bubbles':true, 'cancelable':true})
  } catch (error) {
    ev = document.createEvent('Event')
    ev.initEvent(name, true, true);
  }
  window.dispatchEvent(ev)
}, false)
