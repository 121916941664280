import {get_models, get_license, edit_license, get_identifier,get_version,verify_license} from './service'
import {setSession, getSession} from '../utils/session'

export default {
  namespaced: true,
  state: {
    breadcrumb: [], // 面包屑
    menu_show: true, // 菜单展示隐藏
    ai_models: [], // 支持哪些ai应用
    license: {}, // license信息,
    identifier: '',// 设备编号
    version_list: {},// 版本列表
    verify: false,
    other_app_url: null, // 详情页应用地址
  },
  mutations: {
    setBreadcrumb (state, payload) {
      state.breadcrumb = payload
    },
    set_menu_show (state, payload) {
      state.menu_show = payload
    },
    update_ai_models (state, payload) {
      state.ai_models = payload.data
    },
    update_license (state, payload) {
      state.license = payload.data
    },
    update_identifier(state, payload) {
      state.identifier = payload.data&&payload.data.identifier
    },
    update_version(state, payload) {
      state.version_list = payload.data
    },
    update_verify(state, payload) {
      state.verify = payload.data.verify
    },
    update_other_app_url(state, payload) {
      state.other_app_url = payload
    },
  },
  actions: {
    async get_ai_models ({ commit }, payload) {
      const {data} = await get_models(payload)
      commit({
        type: 'update_ai_models',
        data: data.models
      })
      // 添加权限
      const authToken = getSession()
      const verifyArr = (authToken && authToken.verifyArr) || []
      data.models.forEach(item => {
        if (item.enable) verifyArr.push(`/${item.artifact_id}`)
      })
      authToken.verifyArr = verifyArr
      setSession(authToken)
    },
    async get_license ({ commit }, payload) {
      const {data} = await get_license(payload)
      commit({
        type: 'update_license',
        data
      })
    },
    async edit_license ({ commit }, payload) {
      const {data} = await edit_license(payload)
      commit({
        type: 'update_license',
        data
      })
      return {data}
    },
    async get_identifier({ commit }, payload) {
      const {data} = await get_identifier(payload)
      commit({
        type: 'update_identifier',
        data
      })
    },
    async get_version({ commit }, payload) {
      const {data} = await get_version(payload)
      commit({
        type: 'update_version',
        data
      })
    },
    async verify_license({ commit }, payload) {
      const {data} = await verify_license(payload)
      commit({
        type: 'update_verify',
        data
      })
      return {data}
    },
  }
}
