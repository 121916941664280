<template>
   <el-input
    @input="age_input"
    @blur="blur"
    :disabled="disabled"
    v-bind="[$attrs, $props]"
    type="number"
    :class="{'my-input':unit}"
    :placeholder="placeholder"
    :value="value">
    <template slot="suffix" v-if="unit">{{unit}}</template>
  </el-input>
</template>
<script>
export default {
  data(){
    return {
      oldValue: null
    }
  },
  props:{
    value: {
      type: [String,Number],
      default:null
    },
    disabled: {
      type:Boolean,
      default: false
    },
    unit: {
      type: String,
      default:''
    },
    placeholder:{
      type: String,
      default:''
    },
    integerDigits: { // 小数点前几位
      default: 11,
      type: Number
    },
    decimalDigits: { // 小数点后几位
      default: 4,
      type: Number
    },
    negative: {
      type:Boolean,
      default: false
    }

  },
  created() {
    this.oldValue = this.value
  },
  methods: {
    age_input(val) {
      let negative = ''
      if(this.negative) {
        negative = '(-?)'
      }
      let reg
      if (this.decimalDigits > 0) {
        reg = new RegExp(`(?!^0\\d)^(${negative}\\d{1,${this.integerDigits}}(\\.\\d{0,${this.decimalDigits}})?)?$`, 'g')
      } else {
        reg = new RegExp(`(?!^0\\d)^(${negative}\\d{1,${this.integerDigits}})?$`, 'g')
      }
      if(reg.test(val)) {
        this.oldValue = val
        this.$emit('input',val)
      } else {
        this.$emit('input',this.oldValue)
      }
    },
    blur () {
      // 输入负号清空
      if(!this.value) {
        this.oldValue = ''
        this.$emit('input', 1)
        setTimeout(() => {
          this.$emit('input', '')
        }, 0);
      }

    }
  }
}
</script>
<style scoped>
>>>.el-input__suffix {
  color: #606266;
}
</style>
<style>
.my-input {
  &.el-input--suffix .el-input__inner {
    padding-right: 52px;
  }
}
</style>
