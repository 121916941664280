import api from '../../utils/api'

// 登录
export async function login (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         nickname: '张三',
  //         token: '34dweferfrewgrwgrwgrgrw'
  //       }
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/users/operation_users/login', payload)
}

// 退出
export async function logout () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post('/api/v2/users/session/delete')
}

export async function bind_phone (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         bind_phone: false
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/users/operation_users/phone',{params: payload})
}

export async function send_code (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         code: '078466'
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/users/operation_users/codes',{params: payload})
}

// 医生id登录
export async function doctor_login (payload) {
  return api.post('/api/v2/users/session/embed', payload)
}
