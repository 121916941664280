import { get_info, get_flesh_list, get_timeline,get_trigger_list,get_grace_list,get_exudate_list,get_application,get_ckd_list } from "./service";
import router from "../../router"
import {format_date} from '../../utils/format'
import {decrypt} from '../../utils/aes'
export function build_two(list, fn) {
  //处理平级为树形方法
  const groups = {};
  list.forEach(function (o) {
    const group = JSON.stringify(fn(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return groups;
}

export function reset_data(data, fail) {
  //重置评级属性，以满足树形结构要求
  if (!data) {
    return;
  }
  let abnormal_list = {
    group_name: ["异常"],
    group_list: [],
    abnormal_num: 0,
  };
  data.forEach((item) => {
    if (item.abnormal) {
      abnormal_list.group_list.push(item);
      abnormal_list.abnormal_num++;
    }
    item.selected = false;
    item.after_click = false;
  });

  let normal_obj = build_two(data, (item) => {
    return item.group_name;
  });
  let now_obj = {};
  Object.keys(normal_obj).forEach(function (key) {
    now_obj[key] = normal_obj[key];
  });

  let arr = [];
  for (let i in now_obj) {
    let obj = {
      group_name: now_obj[i][0].group_name && now_obj[i][0].group_name.length
        ? now_obj[i][0].group_name
        : ["其他"],
      group_list: now_obj[i],
    };
    arr.push(obj);
  }

  if (fail && fail.length) {
    fail.forEach((item) => {
      item.un_cleaned = item.error;
    });
    arr.push({
      group_name: ["正在学习中"],
      group_list: [...fail],
      total: fail.length,
      abnormal_num: 0,
      un_cleaned: true,
    });
  }
  arr.unshift(abnormal_list);
  return arr
}

export default {
  namespaced: true,
  state: {
    loading: false,
    data: null,
    time_data: null,
    person: null, //个人信息模块数据
    age: 0, // 个人年纪数据
    time: null, //时间轴数据
    table_data: [],// 表格树形结构
    num_map: {}, //属性数目
    filter_list: null, //table 显示数据
    need_focus: [], //还需关注id list
    re_back_item: null, //反馈的item 类型
    no_type_list: [], // 无类型 >>> 还需关注项目
    suggestion_diagnosis: [], // 建议疾病
    feedback_map: null, // 后端回传 反馈结果 1-4 代表 实验室解读-建议疾病项目
    select_input: [],
    allow_select: false,
    select_check_list: [],
    // 血气列表
    flesh_list: [],
    // 异常解读
    interpretation: [],
    //相关疾病
    disease: {},
    report_id: '',
    learn_interpretation: [],
    //应用集提醒
    trigger_list: [],
    //grace报告列表
    grace_list: [],
    // 渗出液模型报告列表
    exudate_list: [],
    //ckd列表
    ckd_list: [],
    //我的应用集
    applications: {},
    application_visible: false,
  },
  getters: {
    is_patch() {
      return router.app.$route.meta.source === 'patch'
    },
    decrypt_query (state, getter) {
      if (getter.is_patch && router.app.$route.query.encrypt) {
        return decrypt(router.app.$route.query.encrypt, false)
      }
      return router.app.$route.query
    },
    doctor_id(state, getter) {
      if (getter.is_patch) {
        return getter.decrypt_query.doctor_id
      }
      return null
    },
    patient_id(state, getter) {
      return getter.decrypt_query.id
    },
    report_id(state) {
      return router.app.$route.query.report_id || state.report_id
    },
    report_date(state, getter) {
      if (getter.is_patch) {
        return getter.decrypt_query.report_date
      }
      return null
    },
  },
  mutations: {
    clearState(state) {
      state.loading = false
      state.data = null
      state.time_data = null
      state.time = null
      state.person = null
      state.age = 0
      state.table_data = []
      state.num_map = {}
      state.filter_list = null
      state.need_focus = []
      state.re_back_item = null
      state.no_type_list = []
      state.suggestion_diagnosis = []
      state.feedback_map = null
      state.select_input = []
      state.allow_select = false
      state.select_check_list = []
      //异常解读
      state.interpretation = []
      //相关疾病
      state.disease = {}
      state.report_id = ''
      //异常解读中的还需学习的检验项目
      state.learn_interpretation = []
      state.trigger_list = []
      state.grace_list = []
      state.exudate_list = []
      state.applications = {}
      state.application_visible = false
      state.ckd_list = []
    },
    updateData(state, payload) {
      state.data = payload.data
      const data = JSON.parse(JSON.stringify(payload.data))
      const arr = reset_data(data.laboratories, data.clean_failed_laboratories)
      //表格数据
      state.table_data = [...arr]
      //表格左侧数据
      const map_data = {
        data: [...arr],
        all_total: data.total_laboratories
      }
      let obj = {}
      map_data.data.forEach((item) => {
        item.total = item.group_list.length;
        obj[item.group_name[0]] = item.total;
      });
      obj["异常"] = map_data.all_total;
      state.num_map = obj
      //初始化表格当前显示内容
      state.filter_list = arr[0].group_list
      //还需关注的id列表
      state.need_focus = data.need_focus
      //无type类型还需关注的数据list
      state.no_type_list = data.laboratories.filter((item) => {
        return data.need_focus.includes(item.laboratory_id)
      });
      //反馈消息列表
      state.feedback_map = data.feedback
      //建议信息
      state.suggestion_diagnosis = data.suggestion_diagnosis
      //异常解读
      const fixed_obj = {}
      data.laboratories.forEach(item=>{
        fixed_obj[item.laboratory_id] = item
      })
      const inter = data.interpretation
      if(inter.length) {
        inter.forEach(item=>{
          item.lab_obj = fixed_obj[item.laboratory_id]
        })
      }
      state.interpretation = inter

      const learn_inter = data.learn_interpretation
      if(learn_inter.length) {
        learn_inter.forEach(item=>{
          item.lab_obj = fixed_obj[item.laboratory_id]
        })
      }

      state.learn_interpretation = learn_inter
      //疾病关联
      const Ass = {
        groups: data.categories.groups,
        radar_chart: data.categories.radar_chart
      }
      if(Ass.groups.length) {
        Ass.groups.forEach(item=>{
          item.big_open = false
          item.children.forEach(it=>{
            it.evidences.forEach(ev=>{
              ev._obj = fixed_obj[ev.laboratory_id]
            })
            //拍平证据id
            it.evd_ids = it.evidences.map(evd=>{
              return evd.laboratory_id
            })
            if(it.sub_diagnosis) {
              let sub_diagnosis = it.sub_diagnosis
              sub_diagnosis.forEach(sub=>{
                sub.evidences.forEach(sub_ev=>{
                  sub_ev._obj = fixed_obj[sub_ev.laboratory_id]
                  if(it.evd_ids.indexOf(sub_ev.laboratory_id) < 0) {
                    it.evd_ids.push(sub_ev.laboratory_id)
                  }
                })
              })
              it.sub_blood = it.sub_diagnosis.filter(i=>{
                return i.blood_spirits
              })
              it.another_diagnosis = it.sub_diagnosis.filter(i=>{
                return !i.blood_spirits
              })
            }
          })
        })
      }
      state.disease = Ass
    },
    updateTime(state, payload) {
      state.time_data = payload.data
      //更新病人个人信息
      const data = JSON.parse(JSON.stringify(payload.data))
      state.person = data.patient
      //重组时间轴数据
      let normal_obj = build_two(data.days, (item) => {
        return format_date(item.date, "yyyy");
      });
      let now_obj = {};
      Object.keys(normal_obj).forEach(function (key) {
        now_obj[key] = normal_obj[key];
      });
      for(let i in now_obj) {
        let item = now_obj[i]
        item.forEach(it=>{
          it.active = false
        })
      }
      state.time = now_obj;
    },
    updateApplication(state, payload) {
      state.applications = payload.data
    },
    updateAppVisible(state, payload) {
      state.application_visible = payload
    },
    updateAllowSelect(state, payload) {
      state.allow_select = payload
    },
    updateSeletIptValue(state, payload) {
      state.select_input = payload
    },
    updateCheckList(state, payload) {
      state.select_check_list = payload
    },
    updateAge(state, payload) {
      state.age = payload
    },

    updateResetData(state, payload) {
      const arr = reset_data(payload.data, payload.fail)
      state.table_data = arr
      state.filter_list = arr[0].group_list
    },

    updateFilterList(state, payload) {
      state.filter_list = payload.data
    },
    updateReBackItem(state, payload) {
      state.re_back_item = payload
    },
    showLoading(state) {
      state.loading = true
    },
    hideLoading(state) {
      state.loading = false
    },
    update_flesh_list(state, payload) {
      state.flesh_list = payload.data.list
    },
    update_trigger_list(state, payload) {
      state.trigger_list = payload.data.applications
    },
    update_report_id(state, payload) {
      state.report_id = payload
    },
    update_grace_list(state, payload) {
      console.log(11,payload.data)
      state.grace_list = payload.data.data
    },
    update_exudate_list(state, payload) {
      state.exudate_list = payload.data.data
    },
    update_ckd_list(state, payload) {
      state.ckd_list = payload.data.data
    },
  },
  actions: {
    async get_report({ state, commit }, payload) {
      commit({
        type: 'showLoading'
      })
      state.search_params = { ...payload }
      try {
        const { data } = await get_info(payload)
        commit({
          type: 'updateData',
          data
        })

      } finally {
        commit({
          type: 'hideLoading'
        })
      }
    },
    // 血气
    async search_flesh_list({ commit }, payload) {
      const { data } = await get_flesh_list(payload)
      commit({
        type: 'update_flesh_list',
        data,
      })
      return { data }
    },
    // 应用集提醒
    async search_trigger_list({ commit }, payload) {
      if(!payload.report_id) return
      const { data } = await get_trigger_list(payload.report_id)
      commit({
        type: 'update_trigger_list',
        data,
      })
      return { data }
    },
    async get_report_time({ commit }, payload) {
      commit({
        type: 'showLoading'
      })
      const { data } = await get_timeline(payload)
      commit({
        type: 'updateTime',
        data
      })
      commit({
        type: 'hideLoading'
      })
    },
    // 获取grace报告列表
    async get_grace_list({ commit },payload) {
      const { data } = await get_grace_list(payload)
      commit({
        type: 'update_grace_list',
        data
      })
      return { data }
    },
    // 获取渗出液模型报告列表
    async get_exudate_list({ commit },payload) {
      const { data } = await get_exudate_list(payload)
      commit({
        type: 'update_exudate_list',
        data
      })
      return { data }
    },
    //ckd
    async get_ckd_list({ commit },payload) {
      const { data } = await get_ckd_list(payload)
      commit({
        type: 'update_ckd_list',
        data
      })
      return { data }
    },
    //获取我的应用集
    async get_my_application({ commit }, payload) {
      const { data } = await get_application(payload)
      commit({
        type: 'updateApplication',
        data
      })
    },
  }
}
