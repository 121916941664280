<template>
  <div class="menu-wrapper">
    <div :class="['menu_box', {selected: ['/'].includes(activeIndex)}]" v-if="[1, 4].includes(role)">
      <div @click="change('/')" :class="['title', {active: activeIndex == '/'}]">
        <i class="icon icon1"></i>
        <div class="text">首页</div>
      </div>
    </div>
    <div :class="['menu_box', {selected: ['/excellent', '/appstore'].includes(activeIndex)}]" v-if="[1, 4].includes(role)">
      <div class="title">
        <i class="icon icon2"></i>
        <div class="text">AI工具</div>
      </div>
      <ul class="item_box">
        <li @click="change('/excellent')" :class="['item', {active: activeIndex == '/excellent'}]">
          <div class="text">AI报告解读</div>
        </li>
        <li @click="change('/appstore')" :class="['item', {active: activeIndex == '/appstore'}]">
          <div class="text">应用集市</div>
        </li>
        <li v-if="show_collects" @click="change('/favorite-list')"
          :class="['item', {active: ['/favorite-list'].includes(activeIndex)}]">
          <div class="text">我的收藏</div>
        </li>
      </ul>
    </div>
    <div :class="['menu_box', {selected: ['/diagnosis-menu', '/data'].includes(activeIndex)}]" v-if="[1, 4].includes(role)">
      <div class="title">
        <i class="icon icon3"></i>
        <div class="text">百科知识</div>
      </div>
      <ul class="item_box">
        <li @click="change('/diagnosis-menu')" :class="['item', {active: activeIndex == '/diagnosis-menu'}]">
          <div class="text">查疾病</div>
        </li>
        <li @click="change('/data')" :class="['item', {active: activeIndex == '/data'}]">
          <div class="text">懂检验</div>
        </li>
      </ul>
    </div>
    <div v-if="role === 7 || (role === 1&&permission_column.length>0)" :class="['menu_box', {selected: ['/crisis-reminder', '/b'].includes(activeIndex)}]">
      <div class="title">
        <i class="icon icon4"></i>
        <div class="text">智慧检验科</div>
      </div>
      <ul class="item_box">
        <li v-if="role === 1 && permission_column.includes('1')" @click="change('/bulletin-board',)" :class="['item', {active: activeIndex == '/bulletin-board',}]">
          <div class="text">系统看板</div>
        </li>
        <li v-if="role === 1 && permission_column.includes('2')" @click="change('/crisis-reminder')"
          :class="['item', {active: ['/crisis-reminder', '/crisis-detail'].includes(activeIndex)}]">
          <div class="text">重要指标管理</div>
        </li>
        <li v-if="role === 7" @click="change('/report-list')" :class="['item', {active: activeIndex == '/report-list'}]">
          <div class="text">报告列表</div>
        </li>
        <li v-if="role === 7" @click="change('/comprehensive-prompt')"
          :class="['item', {active: ['/comprehensive-prompt', '/comprehensive-prompt-detail'].includes(activeIndex)}]">
          <div class="text">智慧提示</div>
        </li>
        <li v-if="role === 7" @click="change('/user-defined-text')" :class="['item', {active: activeIndex == '/user-defined-text'}]">
          <div class="text">自定义文本</div>
        </li>
      </ul>
    </div>
    <div :class="['menu_box', {selected: ['/data-center','/ai-platform','/ai-platform-detail'].includes(activeIndex)}]" v-if="[1,8].includes(role)">
      <div class="title">
        <i class="icon icon8"></i>
        <div class="text">科研平台</div>
      </div>
      <ul class="item_box">
        <li v-if="permission_column.includes('3')" @click="change('/data-center')" :class="['item', {active: activeIndex == '/data-center'}]">
          <div class="text">数据中心</div>
        </li>
        <li v-if="role===8" @click="change('/ai-platform')" :class="['item', {active: ['/ai-platform', '/ai-platform-detail'].includes(activeIndex)}]">
          <div class="text">自动机器学习平台</div>
        </li>
      </ul>
    </div>
    <div
      :class="['menu_box', {selected: ['/common-problem', '/editLicense', '/version-update', '/about-us'].includes(activeIndex)}]"
      v-if="[1, 4].includes(role)">
      <div class="title">
        <i class="icon icon5"></i>
        <div class="text">帮助中心</div>
      </div>
      <ul class="item_box">
        <li @click="change('/common-problem')" :class="['item', {active: activeIndex == '/common-problem'}]">
          <div class="text">常见问题</div>
        </li>
        <li @click="change('/editLicense')" :class="['item', {active: activeIndex == '/editLicense'}]">
          <div class="text">管理许可证</div>
        </li>
        <li @click="change('/version-update')" :class="['item', {active: activeIndex == '/version-update'}]">
          <div class="text">版本更新</div>
        </li>
        <li @click="change('/about-us')" :class="['item', {active: activeIndex == '/about-us'}]">
          <div class="text">关于慧检</div>
        </li>
      </ul>
    </div>
    <div
      :class="['menu_box', {selected: ['/data-cleaning','/operations-use-statistical','/operations-interpret-ability'].includes(activeIndex)}]"
      v-if="role === 6">
      <div class="title">
        <i class="icon icon6"></i>
        <div class="text">运维服务</div>
      </div>
      <ul class="item_box">
        <li @click="change('/data-cleaning')" :class="['item', {active: activeIndex == '/data-cleaning'}]">
          <div class="text">数据清洗</div>
        </li>
        <li @click="change('/operations-use-statistical')"
          :class="['item', {active: activeIndex == '/operations-use-statistical'}]">
          <div class="text">使用统计</div>
        </li>
        <li @click="change('/operations-interpret-ability')"
          :class="['item', {active: activeIndex == '/operations-interpret-ability'}]">
          <div class="text">解读能力</div>
        </li>
      </ul>
    </div>
    <div :class="['menu_box', {selected: ['/blacklist','/blacklist-record'].includes(activeIndex)}]" v-if="role === 6">
      <div class="title">
        <i class="icon icon7"></i>
        <div class="text">黑名单管理</div>
      </div>
      <ul class="item_box">
        <li @click="change('/blacklist')" :class="['item', {active: activeIndex == '/blacklist'}]">
          <div class="text">黑名单</div>
        </li>
        <li @click="change('/blacklist-record')" :class="['item', {active: activeIndex == '/blacklist-record'}]">
          <div class="text">操作记录</div>
        </li>
      </ul>
    </div>
    <div :class="['menu_box', {selected: ['/ai-report'].includes(activeIndex)}]" v-if="role === 6">
      <div class="title">
        <i class="icon icon2"></i>
        <div class="text">AI报告管理</div>
      </div>
      <ul class="item_box">
        <li @click="change('/ai-report')" :class="['item', {active: activeIndex == '/ai-report'}]">
          <div class="text">AI报告</div>
        </li>
        <li @click="change('/analysis-report')" :class="['item', {active: activeIndex == '/analysis-report'}]">
          <div class="text">注解报告</div>
        </li>
      </ul>
    </div>
    <div :class="['menu_box', {selected: ['/users', '/logs'].includes(activeIndex)}]"
      v-if="role === 2 || role === 3">
      <div class="title">
        <i class="icon icon5"></i>
        <div class="text">管理</div>
      </div>
      <ul class="item_box">
        <li v-if="role === 2" @click="change('/users')" :class="['item', {active: activeIndex == '/users'}]">
          <div class="text">账号管理</div>
        </li>
        <li v-if="role === 2" @click="change('/app-manage')" :class="['item', {active: activeIndex == '/app-manage'}]">
          <div class="text">应用管理</div>
        </li>
        <li v-if="role === 3" @click="change('/logs')" :class="['item', {active: activeIndex == '/logs'}]">
          <div class="text">日志管理</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { getSession } from '../utils/session'

export default {
  data() {
    return {
      role: getSession().role,
      permission_column: getSession().permission_column || [],
      show_collects: GLOBAL_CONFIG.show_collects,
    }
  },
  computed: {
    activeIndex() {
      return this.$route.path
    },
  },
  components: {
  },
  created() {
  },
  methods: {
    change(index) {
      this.$router.push({ path: index })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.menu-wrapper {
  background: #fff;
  width: 270px;
  padding: 48px 0 20px;
  box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.08);
}

.menu_box {
  & .title {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 23px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #303133;

    & .text {
      flex: 1;
    }

    &.active {
      color: #1890FF;
      background: #F6F9FD;
      border-right: 4px solid #1890FF;
    }

    &.disabled {
      color: #C0C4CC;
      cursor: not-allowed;
    }
  }

  & .icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    background: url('../assets/img/menu/1.png') center center no-repeat;
    background-size: contain;
    margin-right: 11px;
  }

  & .icon1 {
    background-image: url('../assets/img/menu/1.png');
  }

  & .icon2 {
    background-image: url('../assets/img/menu/2.png');
  }

  & .icon3 {
    background-image: url('../assets/img/menu/3.png');
  }

  & .icon4 {
    background-image: url('../assets/img/menu/4.png');
  }

  & .icon5 {
    background-image: url('../assets/img/menu/5.png');
  }

  & .icon6 {
    background-image: url('../assets/img/menu/6.png');
  }

  & .icon7 {
    background-image: url('../assets/img/menu/7.png');
  }

  & .icon8 {
    transform: scale(.75);
    background-image: url("../assets/img/menu/8.png");
  }
}

.item_box {
  & .item {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 58px;
    font-size: 15px;
    font-weight: 500;
    color: #707070;
    cursor: pointer;

    & .text {
      flex: 1;
    }

    &.active {
      color: #1890FF;
      background: #F6F9FD;
      border-right: 4px solid #1890FF;
    }

    &.disabled {
      color: #C0C4CC;
      cursor: not-allowed;
    }
  }
}
</style>
