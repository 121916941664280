<template>
  <div class="chart_box">
    <div class="chart" ref="myChart"></div>
    <div class="pagination">
      <NumberPagination
        @current-change="handle_current_change"
        :current-page="page"
        :page-size="page_size"
        :total="total"
        />
      <div class="page_size">当前节点数：{{current_child_nodes.length}}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isEmpty } from '../../../utils/validate'
import { format_decimal } from '../../../utils/format'
import NumberPagination from '../../NumberPagination'

export default {
  props: {
    nodes: {
      default: () => [],
      type: Array
    },
    links: {
      default: () => [],
      type: Array
    },
    value: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    default_page: {
      default: 1,
      type: Number
    },
    page_size: {
      default: 10,
      type: Number
    },
    small_page_size: {
      default: 3,
      type: Number
    },
  },
  data() {
    return {
      myChart: null,
      big_radius: 100, // 大的半径
      small_radius: 40, // 小的半径
      nodes_obj: {}, // 节点对象，便于快速选择
      nodes_style: [], // 节点
      all_links_style: [], // 总连线
      links_style: [], // 连线
      total: 0,
      current_child_nodes: [], // 当前页子节点数
      page: this.default_page
    }
  },
  computed: {
    ...mapState('commonData', {
      menu_show: state => state.menu_show,
    })
  },
  components: {
    NumberPagination
  },
  watch:{
    menu_show () {
      this.myChart && this.myChart.resize()
    },
    nodes(){
      this.page = 1
      this.init_echart()
    },
    value () {
      this.page = 1
      this.update_echarts()
    }
  },
  created() {
  },
  mounted () {
    // 基于准备好的dom，初始化echarts实例
    this.myChart = echarts.init(this.$refs.myChart)
    this.$once('hook:beforeDestroy', () => {
      window.removeEventListener('resize', this.myChart.resize, false)
      this.myChart.clear()
      echarts.dispose(this.myChart)
      this.myChart = null
    })
    this.init_echart()
    window.addEventListener('resize', this.myChart.resize, false)

    // 绑定事件
    this.myChart.on('click', (params) => {
      if (this.disabled) return
      if (params.dataType === 'node' && this.value !== params.name) {
        this.$emit('input', params.name)
        this.$emit('node_change', this.nodes_obj[params.name])
      }
    })
  },
  methods: {
    // 初始化数据
    init_data () {
      // 清除数据
      this.nodes_obj = {}
      this.all_links_style = []

      let nodes = JSON.parse(JSON.stringify(this.nodes))
      for (let item of nodes) {
        item._children = []
        item._parent = null
        this.nodes_obj[item['key']] = item
      }
      let link_labo_keys = new Set() // 有连线的化验项
      for (let item of this.links) {
        let source_key = item.source
        let target_key = item.target
        let knowledges = item.knowledges
        let rate = item.rate
        let source_obj = this.nodes_obj[source_key]
        let target_obj = this.nodes_obj[target_key]
        // 如果节点不存在，就跳过本次循环
        if (!source_obj || !target_obj) continue
        let source_type = source_obj._type
        let target_type = target_obj._type

        let link = {
          source: source_key,
          target: target_key,
          value: item,
        }
        // 化验项
        let labo_obj = null
        // 患者做为起点
        if (source_type === 1) {
          target_obj._parent = source_key
          source_obj._children.push(target_key)
        }
        // 疾病做为起点
        else if (source_type === 2) {
          // 患者做为终点
          if (target_type === 1) {
            source_obj._parent = target_key
            target_obj._children.push(source_key)
          }
          // 化验项做为终点
          else if (target_type === 3) {
            source_obj._children.push(target_key)
            labo_obj = target_obj
            // 如果没有连线上没有知识就不能从化验项推出疾病
            if (knowledges && knowledges.length > 0) {
              target_obj._children.push(source_key)
              link_labo_keys.add(target_key)
            }
          }
        }
        // 化验项做为起点
        else if (source_type === 3) {
          target_obj._children.push(source_key)
          labo_obj = source_obj
          // 如果没有连线上没有知识就不能从化验项推出疾病
          if (knowledges && knowledges.length > 0) {
            source_obj._children.push(target_key)
            link_labo_keys.add(source_key)
          }
        }

        // 连线样式
        if (labo_obj) {
          if (labo_obj.abnormal) {
            let ref = ''
            if (labo_obj.standard_result_type === 2) {
              if (labo_obj.abnormal && labo_obj.abnormal_indicator === '降低（L）') {
                ref += '偏低'
              } else if(labo_obj.abnormal && labo_obj.abnormal_indicator === '升高（H）') {
                ref += '偏高'
              }
            } else {
              ref += labo_obj.original_result
            }
            if (!isEmpty(rate))  ref += ` (${format_decimal(rate)})`
            link.label = {
              show: false,
              formatter: ref,
              color: ref.includes('低') ? '#0ACF97' : '#FA5C7C',
            }
          } else if (!isEmpty(rate)) {
            let ref = `${format_decimal(rate)}`
            link.label = {
              show: false,
              formatter: ref,
              color: '#0ACF97',
            }
          }
        }

        this.all_links_style.push(link)
      }
      // 排序
      for (let item of Object.values(this.nodes_obj)) {
        item._children = item._children.sort((a, b) => this.nodes_obj[a]._index - this.nodes_obj[b]._index)
      }
      // 添加无法解读的化验项
      let no_link_labo_key = '_no_link_labo'
      let no_link_labo = this.nodes_obj[no_link_labo_key] = {
        _children: [],
        _parent: null,
        _name: '无法解读的化验项',
        _type: 2,
        key: no_link_labo_key
      }
      let patient_key = null
      for (let item of this.nodes) {
        let key = item.key
        if (item._type === 3 && !link_labo_keys.has(key)) {
          no_link_labo._children.push(key)
          this.nodes_obj[key]._children.push(no_link_labo_key)
          this.all_links_style.push({
            source: no_link_labo_key,
            target: key,
          })
        } else if (item._type === 1) {
          patient_key =  key
        }
      }
      if (patient_key && no_link_labo._children.length > 0) {
        this.nodes_obj[patient_key]._children.push(no_link_labo_key)
        no_link_labo._parent = patient_key
        this.all_links_style.push({
          source: no_link_labo_key,
          target: patient_key,
        })
      }
    },
    // 节点颜色
    get_node_color (item) {
      let color = ''
      if (item._type === 1) {
        color = '#FA5C7C'
      } else if (item._type === 2){
        if (item.reliable === 2) {
          color = '#FA5C7C'
        } else if (item.reliable === 1){
          color = '#FFBC00'
        } else {
          color = '#2C8EF8'
        }
      } else {
        color = '#727CF5'
      }
      return color
    },
    // 节点名称
    get_node_name (item) {
      let result = ''
      let name = item._name
      if (name.length >= 22) {
        name = `${name.substr(0, 21)}...`
      }
      if (name.length <= 14) {
        result += name
      } else {
        result += `${name.substr(0, 11)}`
        result += `\n${name.substr(11)}`
      }

      if (item._type === 3) {
        if (!isEmpty(item.original_result)) {
          if (item.abnormal && item.standard_result_type !== 2) {
            result += `\n{c2|${item.original_result}}`
          } else {
            result += `\n${item.original_result}`
          }
          if (item.abnormal && item.abnormal_indicator === '降低（L）') {
            result += ` {c1|} {down|}`
          } else if (item.abnormal && item.abnormal_indicator === '升高（H）') {
            result += ` {c2|} {up|}`
          }
        }
        if (item.original_reference_interval || item.original_units) {
          result += `\n`
          if (item.original_reference_interval) {
            let original_reference_interval = item.original_reference_interval
            if(original_reference_interval.length>21) original_reference_interval = original_reference_interval.substr(0, 21) + '...'
            result += `{c3|参考值 ${original_reference_interval} }`
          }
          if (item.original_units) {
            result += `{c3|单位 ${item.original_units}}`
          }
        }
      }
      return result
    },
    // 节点名称（无样式版）
    get_node_name2 (item) {
      let result = ''
      result += `${item._name} `

      if (item._type === 3) {
        if (!isEmpty(item.standard_result)) {
          result += `${item.original_result}<br>`
        }
        if (item.original_reference_interval) {
          result += `参考值: ${item.original_reference_interval}<br>`
        }
        if (item.original_units) {
          result += `单位: ${item.original_units}`
        }
      }
      return result.replace(/^\s+|\s+$/g, '')
    },
    // 更新数据
    update_data () {
      // 清除数据
      this.nodes_style = []
      this.links_style = []
      this.total = 0
      this.current_child_nodes = []

      let current_obj = this.nodes_obj[this.value]
      if (!current_obj) return
      let parent_key = current_obj._parent
      let parent_node = this.nodes_obj[parent_key]
      let c_width = this.myChart.getWidth()
      let center_x = c_width/2
      let center_y = c_width/2
      // 总数
      this.total = current_obj._children.length
      // 过滤连线
      this.links_style = this.all_links_style.filter(item => {
        let source_key = item.source
        let target_key = item.target
        let childrens = current_obj._children
        let parent_childrens = parent_node && parent_node._children

        if (source_key === this.value) {
          if (childrens.includes(target_key) || target_key === parent_key) {
            return true
          } else {
            return false
          }
        } else if (target_key === this.value) {
          if (childrens.includes(source_key) || source_key === parent_key) {
            return true
          } else {
            return false
          }
        } else if (source_key === parent_key) {
          if (parent_childrens.includes(target_key)) {
            return true
          } else {
            return false
          }
        } else if (target_key === parent_key) {
          if (parent_childrens.includes(source_key)) {
            return true
          } else {
            return false
          }
        }
        return false
      })

      // 中心点
      let center_name = this.get_node_name2(current_obj)
      this.nodes_style.push({
        name: current_obj.key,
        value: current_obj,
        x: center_x,
        y: center_y,
        symbolSize: 60,
        symbol: 'circle',
        fixed: true,
        label: {
          formatter: center_name.length <= 4 ? center_name : `${center_name.substr(0, 3)}...`,
          position: 'inside'
        },
        itemStyle: {
          color: '#fff',
          borderColor: this.get_node_color(current_obj),
        }
      })
      // 分页
      // 中心点为疾病时，一页只显示4个
      let page_size = this.page_size
      let start_index = (this.page - 1) * page_size
      let end_index = this.page * page_size
      let child_nodes = []
      if (current_obj._children.length > 0) {
        child_nodes = current_obj._children.slice(start_index, end_index)
        this.current_child_nodes = current_obj._children.slice(start_index, end_index)
      }
      if (parent_key) {
        child_nodes.unshift(parent_key)
      }
      // 角度
      let child_nodes_len = child_nodes.length
      let deg_diff = 0
      if (child_nodes_len) {
        deg_diff = Math.PI * 2 / child_nodes_len
      }
      // 父元素
      if (parent_key) {
        let parent_node_deg = - deg_diff / 8 + deg_diff/4 * Math.random()
        let p_x = center_x + this.big_radius*Math.sin(parent_node_deg)
        let p_y = center_y - this.big_radius*Math.cos(parent_node_deg)

        this.nodes_style.push({
          name: parent_key,
          value: parent_node,
          x: p_x,
          y: p_y,
          symbolSize: 25,
          symbol: 'circle',
          fixed: true,
          label: {
            formatter: this.get_node_name(parent_node),
          },
          itemStyle: {
            color: '#fff',
            borderColor: this.get_node_color(parent_node),
          }
        })
        // 兄弟元素
        let brother_nodes = parent_node._children.filter(key => this.value != key)
        brother_nodes = brother_nodes.slice(0, this.small_page_size)
        let brother_deg_diff = Math.PI * 2 / (brother_nodes.length + 1)
        brother_nodes.forEach((key, index) => {
          let node = this.nodes_obj[key]
          let deg = brother_deg_diff * (index + 1)- brother_deg_diff / 8 + brother_deg_diff/4 * Math.random() + Math.PI
          let radius = this.small_radius * 0.75 + this.small_radius * 0.25 * Math.random()
          let color = this.get_node_color(node)
          let position = 'top'
          // if ((deg < Math.PI * 1.5 && deg >  Math.PI) ||(deg < Math.PI * 3 && deg > Math.PI * 2.5)) {
          //   position = 'bottom'
          // }
          this.nodes_style.push({
            name: key,
            value: node,
            x: p_x + radius*Math.sin(deg),
            y: p_y - radius*Math.cos(deg),
            symbolSize: 8,
            symbol: 'circle',
            fixed: true,
            label: {
              formatter: this.get_node_name(node),
              position
            },
            itemStyle: {
              color: (node._children.length > 0 || node.source === 4) ? color :'#fff', // 为决策树时不空心
              borderColor: color,
            }
          })
        })
      }
      // 子元素
      child_nodes.forEach((key, index) => {
        if (parent_key && index === 0) return
        let node = this.nodes_obj[key]
        let deg = deg_diff * index - deg_diff / 8 + deg_diff/4 * Math.random()
        let position = 'top'
        if (deg > Math.PI / 2 && deg <  Math.PI * 1.5) {
          position = 'bottom'
        }
        let radius = this.big_radius * 0.75 + this.big_radius * 0.25 * Math.random()
        let color = this.get_node_color(node)
        this.nodes_style.push({
          name: key,
          value: node,
          x: center_x + radius*Math.sin(deg),
          y: center_y - radius*Math.cos(deg),
          symbolSize: 25,
          symbol: 'circle',
          fixed: true,
          label: {
            formatter: this.get_node_name(node),
            position
          },
          itemStyle: {
            color: (node._children.length > 0 || node.source === 4) ? color :'#fff', // 为决策树时不空心
            borderColor: color,
          }
        })
      })
      // 空节点，解决不居中的问题
      this.nodes_style = this.nodes_style.concat([
        {
          name: 'top_null',
          x: center_x,
          y: center_y - (this.big_radius + 20),
          fixed: true,
          label: {
            show: false,
          },
          itemStyle: {
            opacity: 0
          }
        },
        {
          name: 'bottom_null',
          x: center_x,
          y: center_y + (this.big_radius + 20),
          fixed: true,
          label: {
            show: false,
          },
          itemStyle: {
            opacity: 0
          }
        },
        {
          name: 'right_null',
          x: center_x + (this.big_radius + 20),
          y: center_y,
          fixed: true,
          label: {
            show: false,
          },
          itemStyle: {
            opacity: 0
          }
        },
        {
          name: 'left_null',
          x: center_x - (this.big_radius + 20),
          y: center_y,
          fixed: true,
          label: {
            show: false,
          },
          itemStyle: {
            opacity: 0
          }
        }
      ])
    },
    init_echart () {
      this.init_data()
      this.update_data()
      this.$nextTick(() => {
        this.myChart.resize()
        this.echarts()
      })
    },
    echarts () {
      this.myChart.clear()
      // 指定图表的配置项和数据
      let option = {
        title: {
        },
        tooltip: {
          show: true,
          padding: 0,
          enterable: true,
          appendToBody: true,
          formatter: (params) => {
            if (params.dataType === "edge" &&
            params.value &&
            params.value.knowledges &&
            params.value.knowledges.length > 0) {
              let html = ''
              for (let item of params.value.knowledges) {
                html += `<div class="item">`
                html += `<div class="title">${item.knowledge_id}</div>`
                html += `<div class="content">${item.knowledge_content.replace(/】/g,'】</div>').replace(/【/g,'<div>【').replace(/\n/g,'<div/>')}</div>`
                html += `</div>`
              }
              return `<div class="g_knowledges">${html}</div>`
            } else if (params.dataType === 'node' && params.value) {
              return `<div style="max-width: 400px; white-space: normal;">${this.get_node_name2(params.value)}</div>`
            }
            return null
          }
        },
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        series: [
          {
            type: 'graph',
            focusNodeAdjacency: true,
            layout: 'none',
            label: {
              color: '#6C757D',
              fontSize: 12,
              lineHeight: 14,
              rich: {
                c1: {
                  color: '#0ACF97',
                  fontSize: 10,
                  lineHeight: 10,
                },
                c2: {
                  color: '#FA5C7C',
                  fontSize: 10,
                  lineHeight: 10,
                },
                c3: {
                  color: '#98A6AD',
                  fontSize: 10,
                  lineHeight: 10,
                },
                up: {
                  height: 20,
                  backgroundColor: {
                    image: './common-img/ic_increase.png'
                  }
                },
                down: {
                  height: 20,
                  backgroundColor: {
                    image: './common-img/ic_down.png'
                  }
                },
              },
              show: true,
              position: 'top',
            },
            lineStyle: {
              color: '#0ACF97',
              width: 3,
              curveness: 0.2,
              opacity: 1,
            },
            itemStyle: {
              borderWidth: 4,
            },
            data: this.nodes_style,
            links: this.links_style,
          }
        ]
      }
      this.myChart.setOption(option)
    },
    handle_current_change (val) {
      this.$emit('page_change', val)
      this.page = val
      this.update_echarts()
    },
    update_echarts () {
      this.update_data()
      this.myChart.setOption({
        series: [
          {
            data: this.nodes_style,
            links: this.links_style,
          }
        ]
      })
    }
  }
}
</script>

<style scoped>
  .chart_box {
    width: 100%;
    height: 100%;
    padding-bottom: 40px;
  }
  .chart {
    width: 100%;
    height: 100%;
    margin: 0px auto;
  }
  .pagination {
    position: relative;
    & .page_size {
      position: absolute;
      display: inline-block;
      right: 20px;
      top: 0px;
      line-height: 40px;
      color: #98A6AD;
    }
  }
</style>
