<template>
   <el-dialog
      width="60%"
      title=""
      :visible.sync="visible"
       :before-close="before_close"
      append-to-body>
      <div class="title" slot="title">
        依据原文
      </div>
        <div class="body" v-loading="loading">
          <template v-if="data.theory&&data.theory.length>0">
            <div v-for="(i,index) of data.theory" :key="index + 'theory'" style="margin-bottom: 38px;">
              <!-- <h2 class="commom-text">{{`${i.source.name ?  i.source.name + ' |' : ''} ${i.source.publish_version&&+i.source.publish_version>0 ?  '第' + i.source.publish_version + '版' + ' | ' : ''}${i.source.publisher ?  i.source.publisher : ''}`}}</h2> -->
              <Rich class="html"
                v-model="i.context"
                :style_type="2"
                style="padding-top: 20px"
              />
            </div>
          </template>
      <div class="no-data" v-if="(!data.theory || data.theory.length===0)&&!loading">
        <img src="../../../assets/img/detail/learning.png" width="300" height="144"/>
          正在学习中～
      </div>
      </div>
    </el-dialog>
</template>
<script>
import {get_laboratories} from './service'
import Rich from '../../rich/index.vue'
export default {
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  props: {
    knowledge_id: {
      type: Array,
      default: () =>[]
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  components: {
    Rich
  },
  created() {
    this.get_data()
  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
    async get_data () {
      this.loading = true
      try {
        let params = {
          knowledge_id: this.knowledge_id
        }
        const {data} = await get_laboratories(params)
        this.data = data
        if(data.theory &&data.theory.length>0) {
          data.theory =  data.theory.filter(i=>{
            let context = i.context.trim()
            let len_index = context.length-1
            let text = []
            let el = 1
            while(el<5) {
              if(context[len_index]!==' '){
                text.unshift(context[len_index])
                el++
              }
              len_index--
            }
            return !(['医生建议','医生提供'].includes(text.join('')) && context.length < 16)
          })
        }
        this.introduce = data.introduce
        this.clinical = data.clinical
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  color: #6B747D;
}
.h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #303133;
    padding-bottom: 12px;
  }
  .html {
    font-size: 15px;
    line-height: 24px;
    color: #303133;
  }
 .no-data {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 28px;
  padding-top: 50px;
  font-size: 24px;
  line-height: 19px;
  color: #303133;
  & >img {
    margin-bottom: 12px;
  }
}
  .body {
    font-size: 15px;
    line-height: 21px;
    color: #303133;

    position: relative;
  }
  .commom-text {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    color: #303133;
    padding: 32px 0 12px;
  }


>>>.el-dialog__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
}
>>>.el-dialog__body {
  padding:0 20px 20px;
}
</style>
