<template>
<!-- 量表 -->
  <el-drawer
    :visible.sync="visible"
    v-loading="loading"
    class="t-drawer"
    size="780px"
    top="40px"
    :destroy-on-close="true"
    :append-to-body="true"
    :before-close="before_close">
     <div class="title" slot="title">
      {{data.report_id ? '编辑' : '新建'}}
    </div>
    <div class="content">
      <p class="info" style="margin-top: 15px;">说明：仅应用于已经发生新冠病毒感染的患者，进行分型诊治辅助</p>
      <div class="result">
        <h2 class="commom-text" style="margin-top: -15px;">
          <div class="line"/>
          一般情况
        </h2>
      </div>
      <el-form ref="ruleForm" :model="data" label-position="top" class="form">
        <el-form-item label="姓名" prop="name">
          <el-input :maxlength="20" clearable :disabled="!!data.name" v-model="data.name" placeholder="请填写姓名"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="gender">
          <el-select clearable v-model="data.gender" placeholder="请选择性别" style="width: 100%;">
              <el-option
                v-for="item in ['男','女']"
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age"  :rules="{validator:checkage, trigger: 'change'}">
          <MyInput  clearable
            v-model="data.age"
            :decimalDigits="0"
            unit="岁"
            placeholder="请填写年龄"
            />
        </el-form-item>
        <el-form-item label="体重" prop="weight" :rules="{ validator:checkweight, trigger: 'change'}">
          <MyInput  clearable
            v-model="data.weight"
            :decimalDigits="2"
            unit="kg"
            placeholder="请填写体重"
            />
        </el-form-item>
        <el-form-item label="身高" prop="height">
          <MyInput  clearable
            v-model="data.height"
            :decimalDigits="2"
            unit="cm"
            placeholder="请填写身高"
            />
        </el-form-item>
        <el-form-item label="BMI" prop="BMI">
          <MyInput clearable
            :disabled="true"
            v-model="data.BMI"
            :decimalDigits="2"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item label="" prop="BMI">
          <el-checkbox v-model="data.pul_ct_abnormality">肺部符合新冠肺炎的新发影像学异常</el-checkbox>
        </el-form-item>
         <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          推荐检查
        </h2>
       <ul class="t_content">
          <li class="last"><div>必要检查</div><div>生命体征、心电图、血气分析（注意记录吸氧条件）、血常规、凝血、D二聚体、肝功、肾全、LDH、CRP、ESR</div></li>
          <li class="last"><div>推荐检查</div><div>铁蛋白、CK、cTnI、NT-proBNP/BNP、TB淋巴细胞亚群、IL-6</div></li>
          <li class="last"><div>可选检查</div><div>IL-8、IL-10、球蛋白、补体；抗MDA5抗体；抗磷脂抗体谱；胸CT（没有72小时之内影像资料且病情危重的患者）</div></li>
        </ul>
        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          重症和危重症的高危因素
        </h2>

        <el-form-item label="慢病史" prop="chronic_history">
          <el-select style="width:100%;" v-model="data.chronic_history" multiple placeholder="请选择慢病史">
            <el-option
              v-for="item in select_list_data.chronic_history"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="肿瘤病史" prop="history_tumor">
          <el-select style="width:100%;" v-model="data.history_tumor" multiple placeholder="请选择肿瘤病史">
            <el-option
              v-for="item in select_list_data.history_tumor"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="用药史" prop="drug_history">
          <el-select style="width:100%;" v-model="data.drug_history" multiple placeholder="请选择用药史">
            <el-option
              v-for="item in select_list_data.drug_history"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="个人史" prop="personal_history">
          <el-select style="width:100%;" v-model="data.personal_history" multiple placeholder="请选择个人史">
            <el-option
              v-for="item in personal_history"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
         <el-form-item label="其他" prop="history_tumor">
          <el-select style="width:100%;" v-model="data.other_risk" multiple placeholder="请选择其他">
            <el-option
              v-for="item in select_list_data.other_risk"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          重症及危重症的预警因素
        </h2>
        <h3 class="commom-text2">
          <div class="line"/>
          临检
        </h3>
        <div class="form_div">
          <el-form-item
            v-for="(item, index) in data.inspection"
            :key="index"
            :label="item.label"
            class="form_line">
            <el-radio-group v-model="item.value">
              <el-radio :label="1">已出现</el-radio>
              <el-radio :label="2">未出现</el-radio>
              <el-radio :label="3">无检测结果</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <h3 class="commom-text2">
          <div class="line"/>
          生化
        </h3>
        <div class="form_div">
          <el-form-item
            v-for="(item, index) in data.biochemistry"
            :key="index"
            :label="item.label"
            class="form_line">
            <el-radio-group v-model="item.value">
              <el-radio :label="1">已出现</el-radio>
              <el-radio :label="2">未出现</el-radio>
              <el-radio :label="3">无检测结果</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <h3 class="commom-text2">
          <div class="line"/>
          免疫
        </h3>
        <div class="form_div">
          <el-form-item
            v-for="(item, index) in data.immune"
            :key="index"
            :label="item.label"
            class="form_line">
            <el-radio-group v-model="item.value">
              <el-radio :label="1">已出现</el-radio>
              <el-radio :label="2">未出现</el-radio>
              <el-radio :label="3">无检测结果</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <h3 class="commom-text2">
          <div class="line"/>
          凝血
        </h3>
        <div class="form_div">
          <el-form-item
            v-for="(item, index) in data.coagulation"
            :key="index"
            :label="item.label"
            class="form_line">
            <el-radio-group v-model="item.value">
              <el-radio :label="1">已出现</el-radio>
              <el-radio :label="2">未出现</el-radio>
              <el-radio :label="3">无检测结果</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <h3 class="commom-text2">
          <div class="line"/>
          其他
        </h3>
        <div class="form_div">
          <el-form-item
            v-for="(item, index) in data.other_warning"
            :key="index"
            class="form_line">
              <div class="el-form-item__label tips_box" slot="label">
                <div>{{item.label}}</div>
                <div v-if="item.key === 3 && item.value === 1" class="tips">
                  <i class="el-icon-caret-left tips-icon"/>
                  <div class="tips-text"> Tips：症状出现后第2周内发展为危重症，需要警惕 </div>
                </div>
              </div>
            <el-radio-group v-model="item.value">
              <el-radio :label="1">已出现</el-radio>
              <el-radio :label="2">未出现</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          危重症因子
        </h2>
         <el-form-item label="危重症因子" prop="illness_factor">
          <el-select style="width:100%;" v-model="data.illness_factor" multiple placeholder="请选择危重症因子">
            <el-option
              v-for="item in select_list_data.illness_factor"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          重症因子
        </h2>
        <el-form-item label="重症因子" prop="severe_factor">
          <el-select style="width:100%;" v-model="data.severe_factor" multiple placeholder="请选择重症因子">
            <el-option
              v-for="item in select_list_data.severe_factor"
              :key="item.key"
              :label="item.value"
              :value="item.key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="动脉血氧分压(PaO2)" prop="paO2">
          <MyInput
            clearable
            v-model="data.paO2"
            :decimalDigits="2"
            unit="mmHg"
            placeholder="请填写动脉血氧分压(PaO2)"
            />
        </el-form-item>
        <el-form-item label="吸氧浓度(FiO2)" prop="fiO2">
          <MyInput
            clearable
            v-model="data.fiO2"
            :decimalDigits="2"
            unit="%"
            placeholder="请填写吸氧浓度(FiO2)"
            />
        </el-form-item>
        <el-form-item label="动脉血氧分压(PaO2)/吸氧浓度(FiO2)" prop="pCF">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.pCF"
            :decimalDigits="2"
            unit="mmHg"
            placeholder="自动计算"
            />
        </el-form-item>
        <h2 class="commom-text" style="width: 100%;margin-top: -15px;">
          <div class="line"/>
          影像学评估
        </h2>
        <el-form-item
          label="左肺上叶病变百分比"
          prop="imaging_evaluation.left_upper_blade"
          :rules="{validator:checkpercent, trigger: 'change'}">
          <MyInput
            clearable
            v-model="data.imaging_evaluation.left_upper_blade"
            :decimalDigits="0"
            unit="%"
            placeholder="请填写左肺上叶病变百分比"
            />
        </el-form-item>
        <el-form-item label="左肺上叶病变分数" prop="imaging_evaluation.left_upper_score">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.left_upper_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item
          label="左肺中叶百分比"
          prop="imaging_evaluation.left_midd_blade"
          :rules="{validator:checkpercent, trigger: 'change'}">
          <MyInput
            clearable
            v-model="data.imaging_evaluation.left_midd_blade"
            :decimalDigits="0"
            unit="%"
            placeholder="请填写左肺中叶百分比"
            />
        </el-form-item>
        <el-form-item label="左肺中叶分数" prop="imaging_evaluation.left_midd_score">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.left_midd_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item
          label="左肺下叶百分比"
          prop="imaging_evaluation.left_lower_blade"
          :rules="{validator:checkpercent, trigger: 'change'}">
          <MyInput
            clearable
            v-model="data.imaging_evaluation.left_lower_blade"
            :decimalDigits="0"
            unit="%"
            placeholder="请填写左肺下叶百分比"
            />
        </el-form-item>
        <el-form-item label="左肺下叶分数" prop="imaging_evaluation.left_lower_score">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.left_lower_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item
          label="右肺上叶百分比"
          prop="imaging_evaluation.right_upper_blade"
          :rules="{validator:checkpercent, trigger: 'change'}">
          <MyInput
            clearable
            v-model="data.imaging_evaluation.right_upper_blade"
            :decimalDigits="0"
            unit="%"
            placeholder="请填写右肺上叶百分比"
            />
        </el-form-item>
        <el-form-item label="右肺上叶病变分数" prop="imaging_evaluation.right_uppper_score">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.right_uppper_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item
          label="右肺下叶百分比"
          prop="imaging_evaluation.right_lower_blade"
          :rules="{validator:checkpercent, trigger: 'change'}">
          <MyInput
            clearable
            v-model="data.imaging_evaluation.right_lower_blade"
            :decimalDigits="0"
            unit="%"
            placeholder="请填写右肺下叶百分比"
            />
        </el-form-item>
        <el-form-item label="右肺下叶分数" prop="imaging_evaluation.right_lower_score">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.right_lower_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
        </el-form-item>
        <el-form-item label="总分" prop="imaging_evaluation.image_score" style="width: 100%;">
          <MyInput
            clearable
            :disabled="true"
            v-model="data.imaging_evaluation.image_score"
            :decimalDigits="2"
            unit="分"
            placeholder="自动计算"
            />
            <p class="info">说明：总分越高，重症、死亡风险越高。6、7分以上，风险显著升高</p>
        </el-form-item>
      </el-form>
    </div>
    <div class="foot">
      <el-button  style="margin-left: 10px;"  @click="cancel">取消</el-button>
      <el-button  @click="to_sbmit">保存</el-button>
      <span v-if="hander_has_data() && can_save_data">请完善必填项</span>
    </div>
  </el-drawer>
</template>
<script>
import {format_date} from '../../../utils/format'
import MyInput from './MyInput'
import {sbmit_covid19_data} from './service'
import { mapActions } from 'vuex'
import {isEmpty} from '../../../utils/validate'

export default {
  data() {
    var checkage = (rule, value, callback) => {
      if (value&&(+value<0 || +value>200)) {
        callback(new Error('请填写0-200的年龄'));
      } else {
        callback();
      }
    };
    let checkweight = (rule, value, callback) => {
      if (value!==null &&value!=='' && +value === 0) {
        callback(new Error('请填写大于0的体重'));
      } else {
        callback();
      }
    }
    let checkpercent = (rule, value, callback) => {
      if (+value > 100 ) {
        callback(new Error('请填写0-100%'));
      } else {
        callback();
      }
    }

    return {
      checkage,
      checkweight,
      checkpercent,
      show: false,
      loading: false,
      data: {},
      can_save_data: false,
      tip: true,
      t_show: true,
      personal_history: [],
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    init_data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    patient_id:{
      type: String,
      default:''
    },
    report_id:{
      type: String,
      default:''
    },
    select_list_data: {
      type: Object,
      default: () =>{}
    }
  },
  components: {
    MyInput,
  },
  mounted() {

  },
  watch: {
    init_data() {
      this.data = JSON.parse(JSON.stringify(this.init_data))
    },
    'data.weight'(val) {
      const height = +this.data.height / 100
      if(val&&height){
        this.data.BMI = Math.floor((+val/(height*height)) * 100) / 100
      } else {
        this.data.BMI = ''
      }

    },
    'data.height'(val) {
      const weight = +this.data.weight
      const value = +val/100
      if(val&&weight) {
        this.data.BMI = Math.floor((weight/(value*value)) * 100) / 100
      } else {
        this.data.BMI = ''
      }
    },
    'data.age'() {
      this.hander_personal_history()
    },
    'data.gender'() {
      this.hander_personal_history()
    },
    'data.paO2'() {
      if (this.data.paO2 && this.data.fiO2) {
        this.data.pCF = +(this.data.paO2 / this.data.fiO2 * 100).toFixed(2)
      } else {
        this.data.pCF = null
      }
    },
    'data.fiO2'() {
      if (this.data.paO2 && this.data.fiO2) {
        this.data.pCF = +(this.data.paO2 / this.data.fiO2 * 100).toFixed(2)
      } else {
        this.data.pCF = null
      }
    },
    'data.imaging_evaluation.left_upper_blade'() {
      this.data.imaging_evaluation.left_upper_score = this.get_score(this.data.imaging_evaluation.left_upper_blade)
      this.get_all_score()
    },
    'data.imaging_evaluation.left_midd_blade'() {
      this.data.imaging_evaluation.left_midd_score = this.get_score(this.data.imaging_evaluation.left_midd_blade)
      this.get_all_score()
    },
    'data.imaging_evaluation.left_lower_blade'() {
      this.data.imaging_evaluation.left_lower_score = this.get_score(this.data.imaging_evaluation.left_lower_blade)
      this.get_all_score()
    },
    'data.imaging_evaluation.right_upper_blade'() {
      this.data.imaging_evaluation.right_uppper_score = this.get_score(this.data.imaging_evaluation.right_upper_blade)
      this.get_all_score()
    },
    'data.imaging_evaluation.right_lower_blade'() {
      this.data.imaging_evaluation.right_lower_score = this.get_score(this.data.imaging_evaluation.right_lower_blade)
      this.get_all_score()
    },

  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.init_data))
    this.hander_personal_history()
  },
  methods: {
    ...mapActions('labDetails', [
      'get_my_application'
    ]),
    format_date,
    get_score (num) {
      if (isEmpty(num)) {
        return null
      }
      num = +num
      if (num === 0) {
        return 0
      } else if (num <= 25) {
        return 1
      } else if (num <= 50) {
        return 2
      } else if (num <= 75) {
        return 3
      } else {
        return 4
      }

    },
    get_all_score () {
      if (isEmpty(this.data.imaging_evaluation.left_upper_score) &&
      isEmpty(this.data.imaging_evaluation.left_midd_score) &&
      isEmpty(this.data.imaging_evaluation.left_lower_score) &&
      isEmpty(this.data.imaging_evaluation.right_uppper_score) &&
      isEmpty(this.data.imaging_evaluation.right_lower_score)) {
        this.data.imaging_evaluation.image_score = null
      } else {
        this.data.imaging_evaluation.image_score = (+this.data.imaging_evaluation.left_upper_score) +
        (+this.data.imaging_evaluation.left_midd_score) +
        (+this.data.imaging_evaluation.left_lower_score) +
        (+this.data.imaging_evaluation.right_uppper_score) +
        (+this.data.imaging_evaluation.right_lower_score)
      }
    },
    close () {
      this.$emit('update:visible', false)
    },
    before_close() {
      this.cancel()
    },
    hander_personal_history() {
      const {age,gender} = this.data
      if(+age>=18&&+age<=45&&gender==='女'){
        this.personal_history = this.select_list_data.personal_history
      } else {
        this.personal_history = this.select_list_data.personal_history.filter(i=>{
          return i.key !== 2
        })
      }
    },
    cancel() {
      this.$confirm('此操作将丢失编辑的内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.close()
      }).catch(() => {
      });
    },
    //保存提示语
    hander_has_data() {
      // let keys = ['name','gender','age']
      // for(let i of keys) {
      //   if(!this.data[i]) return true
      // }
      return false
    },
    to_sbmit() {
      this.can_save_data = false
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.can_save_data = false
          this.$confirm('确定要保存结果?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.to_sbmit_data()
          }).catch(() => {

          });
        } else {
          this.can_save_data = true
        }
      })
    },
    async to_sbmit_data() {
      try {
        this.loading = true
        let params = JSON.parse(JSON.stringify(this.data))
        for(let i in params) {
          if(i.indexOf('_unit')>-1) {
            let str = i.match(/(\S*)_unit/)[1]
            if(!params[str] && params[str]!==0) params[i] = ''
          }
        }
        const {data} = await sbmit_covid19_data({...params,patient_id: this.patient_id})
        this.$emit('select_common_report',data.report_id)
        this.$emit('search_covid19_list')
        //报告相同直接申请不走watch
        if(`${data.report_id}` === `${this.data.report_id}`)this.$emit('search_covid19_detail')
        this.get_my_application({patientId:this.patient_id,reportId: this.report_id})
        this.close()
      }finally{
        this.loading = false
      }
    },

  }
}
</script>
<style scoped>
.info {
  color: var(--color-danger);
}
.result{
  display: flex;
  align-items: center;
}
.commom-text {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-text-regular);
  display: flex;
  align-items: center;
  padding-top: 28px;
  & .line {
    width: 4px;
    height: 16px;
    background: #1890FF;
    border-radius: 3px;
    margin-right: 8px;
  }
}
.commom-text2 {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text-secondary);
  display: flex;
  align-items: center;
  padding-top: 13px;
  & .line {
    width: 4px;
    height: 12px;
    background: #1890FF;
    border-radius: 3px;
    margin-right: 8px;
  }
}

.rule {
  color: #939395;
  cursor: pointer;
  padding-top: 10px;
  & >span {
    color: #1890FF;
  }
}
.my-input{
  margin-right: -1px;
  position: relative;
  z-index: 1;
}

>>>.select-unit .el-input__inner{
  border-left: none;
  border-radius:0 4px 4px 0;
}
>>>.select-unit.el-select .el-input.is-focus .el-input__inner {
  border-color: #dcdfe6;
}
 >>>.select-unit.el-select .el-input__inner:focus {
  border-color: #dcdfe6;
}
>>>.el-form-item {
  margin-bottom: 10px;
}
.form {
  display: flex;
  flex-wrap: wrap;
  width:100%;
  margin-right: -20px;
  & >div{
    width: 50%;
    padding: 0 10px;
  }
}
.score-icon {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #1890FF;
  cursor: pointer;
  padding-top: 28px;
}

.foot {
  height: 64px;
  box-shadow: 0px -9px 28px rgba(0,0,0,0.05);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 29px;
  & >span {
    padding-left: 10px;
    color: #f56c6c;
  }
}
>>>.el-input.is-disabled .el-input__inner {
  color: #606266;
}
>>>.el-textarea.is-disabled .el-textarea__inner {
  color: #606266;
}
>>>.el-form--label-top .el-form-item__label {
  padding: 0;
}
>>>.el-drawer__body {
  height: 100%;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  & .content {
    flex:1;
    overflow: auto;
    padding:0 24px 50px;
  }
}
>>>.el-drawer__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
  padding-bottom: 20px;
  margin-bottom: 0px;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: var(--color-text-regular);
}
>>>.el-drawer.rtl {
  overflow: auto;
}
.t_content {
  padding-top: 16px;
  color: var(--color-text-regular);
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0,0,0,0.149);
  border-right: 1px solid rgba(0,0,0,0.149);
  & >li {
    width: 50%;
    display: flex;
    & >div {
      padding: 13px 23px;
      &:nth-child(1){
        width: 130px;
        background:var(--color-text-six);
        border: 1px solid rgba(0,0,0,0.149);
        border-bottom: none;
        display: flex;
        align-items: center;
      }
      &:nth-child(2){
        flex: 1;
        border-top: 1px solid rgba(0,0,0,0.149);
      }
    }
    &.last {
      width: 100%;
    }
  }
}
.tips_box {
  display: flex;
  align-items: center;
}
.tips {
  display: flex;
  align-items: center;
  & .tips-text {
    border-top: 1px solid rgba(0,0,0,0.149);
    background: #303133;
    color: #fff;
    padding:0 8px;
    border-radius: 4px;
    font-size: 12px;
    min-width: 100px;
    word-wrap: break-word;
    margin-left: -9px;
  }
  & .tips-icon {
    font-size: 24px;
  }
}
.form_div {
  width: 100% !important;
}
>>>.form_line {
  display: flex;
  & .el-form-item__label {
    flex: 1;
  }
}
</style>

