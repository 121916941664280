<template>
  <div class="textarea-input">
    <el-input
      v-bind="[$attrs, $props]"
      v-on="$listeners"
      :value="value">
    </el-input>
    <i v-if="value && clearable && !disabled" @click="clear" class="el-icon-circle-close circle-close"></i>
  </div>
</template>
<script>
export default {
  data(){
    return {

    }
  },
  props:{
    value: {
      type: [String,Number],
      default:null
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type:Boolean,
      default: false
    },

  },
  created() {

  },
  methods: {
    clear(){
      this.$emit('input','')
    }
  }
}
</script>
<style scoped>
.textarea-input {
  position: relative;
  & .circle-close {
    position: absolute;
    right: 18px;
    top: 20px;
    cursor: pointer;
    color: #c0c4cc;
    display: none;
  }
  &:hover{
    & .circle-close {
      display: block;
    }
  }
}
>>>.el-textarea__inner {
  padding-right: 22px;
}
</style>

