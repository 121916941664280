<template>
  <div class="menu_box">
    <!-- 我常用的列表显示 -->
    <div>
      <div
        @click="show_plan()"
        :class="['item', {selected: plan_visible}]">
        <img src="../assets/img/detail-menu/1.png" class="icon" />
        <img src="../assets/img/detail-menu/1_2.png" class="icon active" />
        <p>看趋势</p>
      </div>
      <div
        @click="to_url('/diagnosis-menu')"
        :class="['item', {selected: other_app_url === '/diagnosis-menu'}]">
        <img src="../assets/img/detail-menu/3.png" class="icon" />
        <img src="../assets/img/detail-menu/3_2.png" class="icon active" />
        <p>查疾病</p>
      </div>
      <div
        @click="to_url('/data')"
        :class="['item', {selected: other_app_url === '/data'}]">
        <img src="../assets/img/detail-menu/4.png" class="icon" />
        <img src="../assets/img/detail-menu/4_2.png" class="icon active" />
        <p>懂检验</p>
      </div>
      <div
        slot="reference"
        @click="to_appstore"
        :class="['item', {selected: show_trigger}]">
        <el-badge :value="trigger_list.length" :max="99"  :hidden="trigger_list.length===0">
          <img src="../assets/img/detail-menu/5.png" class="icon" />
          <img src="../assets/img/detail-menu/5_2.png" class="icon active" />
        </el-badge>
        <p>应用集</p>
      </div>
    </div>
    <div class="line" v-if="applications&&applications.my_applications&&applications.my_applications.length"></div>
    <div class="my_app" v-if="applications&&applications.my_applications&&applications.my_applications.length">
      <div class="my_app_items" >
        <div v-for="item in applications.my_applications" :key="item.id"
          style="position: relative;"
          @click.stop="show_fn(item)"
          :class="['item','my_items']">
          <el-badge is-dot :hidden="!item.highlight">
            <img :src="`./common-img/app_${item.api_type}.png`" class="icon" width="24">
          </el-badge>
          <p style="font-size: 12px;width:100%;">{{short_name_config[item.api_type]}}</p>
          <div class="warning_box" v-if="item.hint && item.api_type !==7">
            <div class="warning_round">!</div>
            <el-tooltip :content="item.hint" placement="bottom" effect="light">
              <p class="warning_content">{{ item.hint }}</p>
            </el-tooltip>
            <div
              class="warning_close"
              @click.stop="cancle_warning(item)"
            >
              X
            </div>
          </div>
        </div>
      </div>
      <div class="my_app_last">
        <div
          @click="show_my_app()"
          :class="['item', {selected: application_visible}]">
          <img src="../assets/img/detail-menu/all_1.png" width="24" class="icon" />
          <img src="../assets/img/detail-menu/all_2.png" width="24" class="icon active" />
          <p>应用管理</p>
        </div>
      </div>
    </div>
    <DiagnosisDialog
      v-show="!other_app_url"
      :visible.sync="diagnosis_visible"
      v-if="diagnosis_visible"
      :name="diagnosis_name"
      :api_type="api_type"
      :item="current_item"
    />
    <!-- 应用管理弹窗 -->
    <ApplicationDialog
      :visible.sync="application_visible"
      v-if="application_visible"
      @reload="load"
    />
    <!-- 我常用的 -->
    <Favorites
      :patient_id="patient_id"
      :visible.sync="plan_visible"
      v-if="plan_visible"
    />
    <TriggerModel :report_id="report_id" :list="trigger_list" :visible.sync="show_trigger" v-if="show_trigger"/>
  </div>
</template>

<script>
import { mapState,mapMutations,mapGetters, mapActions } from 'vuex'
import Favorites from './detail/favorites'
import TriggerModel from './detail/TriggerModel'
import DiagnosisDialog from "./detail/diagnosis-dialog";
import ApplicationDialog from "./detail/application-dialog"
import { user_action } from "../common-data/service";
import { short_name_config } from '../pages/lab-detail/config'
export default {
  props: {
    other_app_url: {
      default: '',
      type: String
    },
  },
  data() {
    return {
      diagnosis_visible: false,
      diagnosis_report_id:'',
      diagnosis_name: '',
      plan_visible: false,
      show_trigger: false,
      api_type: 0,
      short_name_config: {...short_name_config}

    }
  },
  computed: {
    ...mapState('labDetails', {
      flesh_list: state => state.flesh_list,
      trigger_list:  state => state.trigger_list,
      applications: state => state.applications,
      application_visible: state => state.application_visible,
    }),
    // 获取患者id
    ...mapGetters('labDetails', [
      'patient_id',
      'report_id',
    ])
  },
  components: {
    Favorites,
    TriggerModel,
    DiagnosisDialog,
    ApplicationDialog,
  },
  watch: {
    other_app_url(val) {
      // 其他按钮显示隐藏我常用的列表
      if(val) {
        this.plan_visible = false
        this.show_trigger = false
        this.$nextTick(()=>{
          this.updateAppVisible(false)
        })
      }
    },
    report_id() {
      //备注
      this.load()
    },
  },
  created() {
    this.load()
  },
  destroyed() {
  },
  methods: {
    ...mapMutations('labDetails', [
      'updateAllowSelect',
      'updateAppVisible'
    ]),
    ...mapActions("labDetails", [
      'get_my_application'
    ]),
    // 跳转
    to_url (path) {
      this.show_trigger = false
      this.plan_visible = false
      this.diagnosis_visible = false
      this.$emit('change', path)
      this.$nextTick(()=>{
        this.updateAppVisible(false)
      })
    },
    // 我常用的列表显示
    show_plan() {
      this.show_trigger = false
      this.$emit('change', '')
      this.plan_visible = !this.plan_visible
      // 多选按钮显示
      if(this.plan_visible)this.updateAllowSelect(true)
      this.$nextTick(()=>{
        this.updateAppVisible(false)
      })
    },
    hide() {
      this.to_url = false
    },
    to_appstore() {
      this.plan_visible = false
      this.$emit('change', '')
      this.show_trigger = true
      this.$nextTick(()=>{
        this.updateAppVisible(false)
      })
    },
    // 右边菜单栏显示诊断百科
    show_fn(item) {
      this.diagnosis_name = short_name_config[item.api_type] || item.name
      this.api_type = item.api_type
      this.current_item = item
      this.show_dialog(item)
      this.$nextTick(()=>{
        this.updateAppVisible(false)
      })
    },
    show_dialog(item) {
      this.show_trigger = false
      this.plan_visible = false
      this.$emit('change', '')
      this.diagnosis_visible = true
      this.$nextTick(()=>{
        this.updateAppVisible(false)
      })
      // 埋点
      user_action({
        action_id: 15,
        extra_data: {
          report_id: this.report_id,
          app_id: item.application_id,
          app_name: item.name,
        }
      })
    },
    show_my_app() {
      this.show_trigger = false
      this.plan_visible = false
      this.$emit('change', '')
      this.updateAppVisible(!this.application_visible)
    },
    load() {
      if(this.patient_id&&this.report_id) {
        this.get_my_application({patientId:this.patient_id, reportId: this.report_id})
      }
    },
    cancle_warning(item) {
      item.hint=""
    },
  },
};
</script>

<style>
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* 菜单栏 */
.menu_box {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 60px;
  background: #2F343E;
  box-shadow: 0px 3px 33px rgba(0, 0, 0, 0.08);
  padding-top: 68px;
  & .item {
    color: #E9EAED;
    font-size: 13px;
    text-align: center;
    margin: 5px 0px 15px 4px;
    width: 52px;
    opacity: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    white-space: normal;
     &.selected {
      background: #3B4554;
      & .icon {
        display: none;
        &.active {
          display: block;
        }
      }
    }
    & .icon {
      height: 24px;
      width: auto;
      margin-bottom: 3px;
      &.active {
        display: none;
      }
    }
  }
}
.line {
  width: 46px;
  height: 1px;
  border-top: 1px solid #fff;
  margin:5px auto 17px;
}
.my_app {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.my_app_items {
  flex: 1;
  overflow: hidden;
}
.my_app_last {
  margin-top: 15px;
}

>>>.el-badge__content{
  border: none;
}
>>>.el-badge__content.is-dot {
  width: 7px;
  height: 7px;
}

.warning_box {
  position: absolute;
  top: 10px;
  max-width: 500px;
  min-width: 300px;
  right: 70px;
  text-align: left;
  display: flex;
  padding: 4px 8px;
  background: #ff0000;
  color: #ffffff;
  border-radius: 6px;
  line-height: 18px;
  align-items: center;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);

  &::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-left: 7px solid #ff0000;
    border-bottom: 4px solid transparent;
    position: absolute;
    right: -7px;
  }
}

.warning_round {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  background: #fff;
  color: #ff0000;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
  margin-right: 7px;
}

.warning_content {
  width: 100%;
  display: block;
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.warning_close {
  cursor: pointer;
  font-size: 14px;
  width: 18px;
  height: 18px;
  text-align: center;
  flex: 1;
}

@media (max-height: 860px) {
  .my_items:nth-child(n+6) {
    display: none;
  }
}

@media (max-height: 800px) {
  .my_items:nth-child(n+5) {
    display: none;
  }
}

@media (max-height: 740px) {
  .my_items:nth-child(n+4) {
    display: none;
  }
}

@media (max-height: 680px) {
  .my_items:nth-child(n+3) {
    display: none;
  }
}

@media (max-height: 620px) {
  .my_items:nth-child(n+2) {
    display: none;
  }
}

@media (max-height: 560px) {
  .my_items:nth-child(n+1) {
    display: none;
  }
}

@media (max-height: 500px) {
  .my_app {
    display: none;
  }
}

</style>
