<template>
  <el-select
    class="select"
    size="medium"
    clearable
    filterable
    remote
    placeholder="标本"
    :disabled="!domain.original_laboratory_name"
    v-model="domain.original_specimen">
    <el-option
      v-for="(item) in specimen_list"
      :value="item.name"
      :label="item.name"
      :key="item.name">
    </el-option>
  </el-select>
</template>
<script>
import {get_item_list} from './service'
export default {
  data() {
    return {
      specimen_list:[],
      loading: false,
      last_name: '',
      last_len: null
    }
  },
  props: {
    domain: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    'domain.original_laboratory_name'(val) {
      this.domain.original_specimen = ''
      if(val)this.search_specimen(val)
    }
  },
  created() {
    if(this.domain.original_laboratory_name) this.search_specimen(this.domain.original_laboratory_name)
  },
  methods: {
    async search_specimen(val) {
      this.loading = true
      let params ={
        page: 0,
        page_size: 200,
        laboratory_name:val,
        type:2
      }
      try {
        const {data} = await get_item_list(params)
        this.specimen_list = data.data || []
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style scoped>
.select {
  width: 100%;
}
</style>
