var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-drawer',{staticClass:"p_drawer",attrs:{"append-to-body":true,"visible":_vm.visible,"size":"50%","destroy-on-close":true,"before-close":_vm.before_close,"title":`${_vm.dynamicValidateForm.plan_id ? '编辑' : '新建'}方案`},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"drawer_body"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"dynamicValidateForm",staticClass:"drawer_content",attrs:{"model":_vm.dynamicValidateForm}},[_c('el-form-item',{staticClass:"bot",staticStyle:{"padding":"10px 24px 0"},attrs:{"label":"方案名称","prop":"plan_name","rules":{ required: true, message: '请输入方案名称', trigger: 'blur'}}},[_c('el-input',{staticClass:"input",attrs:{"maxlength":20,"clearable":"","placeholder":"请输入方案名称，最大支持20个字。"},model:{value:(_vm.dynamicValidateForm.plan_name),callback:function ($$v) {_vm.$set(_vm.dynamicValidateForm, "plan_name", $$v)},expression:"dynamicValidateForm.plan_name"}})],1),_c('el-form-item',{staticClass:"bot",staticStyle:{"padding":"10px 24px 0"},attrs:{"label":"检验项","rules":{
          required: true
        }}},[_c('el-button',{attrs:{"icon":"el-icon-plus"},on:{"click":_vm.addDomain}},[_vm._v("添加")])],1),_c('div',{ref:"scroll",staticClass:"content"},_vm._l((_vm.dynamicValidateForm.laboratories),function(domain,index){return _c('div',{key:domain.key,staticClass:"item"},[_c('el-form-item',{staticClass:"bot one",attrs:{"prop":'laboratories.' + index + '.original_laboratory_name',"rules":{
              required: true, message: '请选择检验名称', trigger: 'change'
            }}},[_c('Item1',{attrs:{"domain":domain,"or_name_list":_vm.or_name_list}})],1),_c('el-form-item',{staticClass:"bot one",attrs:{"prop":'laboratories.' + index + '.original_specimen',"rules":{
              required: true, message: '请选择标本', trigger: 'change'
            }}},[_c('Item2',{attrs:{"domain":domain}})],1),_c('i',{staticClass:"el-icon-delete del",on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(domain)}}})],1)}),0)],1),_c('div',{staticClass:"drawer_footer"},[_c('el-button',{attrs:{"size":"medium"},on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary","size":"medium"},on:{"click":_vm.submitForm}},[_vm._v("保 存")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }