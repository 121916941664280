import api from '../../utils/api'
export async function get_list (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         page_number: 5,
  //         page_size: 10,
  //         number_of_elements: 50,
  //         total_elements: 10,
  //         total_pages:5,
  //         items: [
  //           {
  //             id: 1,
  //             laboratory_name: '血清钾',
  //             unit: 'mmol/L',
  //             specimen: '血',
  //             laboratory_type: 1, //1-定性 2-定量
  //             max: 10,
  //             max_warning_content: '高血钾症。。。。。。',
  //             min: 0.1,
  //             min_warning_content: '低血钾症。。。。。。',
  //             state: false, //false-停用 true-启用
  //             normal_warning_content: '请留意呼吸。。。。',
  //             normal: '阳性',
  //           },
  //           {
  //             id: 2,
  //             laboratory_name: '血尿钾',
  //             unit: 'mmol/L',
  //             specimen: '尿',
  //             laboratory_type: 2, //1-定性 2-定量
  //             max: 108,
  //             max_warning_content: '高血尿钾症。。。。。。',
  //             min: 0.22,
  //             min_warning_content: '低血尿钾症。。。。。。',
  //             state: true, //false-停用 true-启用
  //             normal_warning_content: '请留意呼吸。。。。',
  //             normal: '阴性',
  //           },
  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/warnings', {params:payload})
}

export async function update_info(payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: null
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v2/warnings/create_update`, payload)
}

