var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"width":"80%","title":"","visible":_vm.visible,"before-close":_vm.before_close,"append-to-body":""},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('div',{staticClass:"title",attrs:{"slot":"title"},slot:"title"},[_vm._v(" 填写依据 ")]),_c('div',{staticClass:"body"},[_c('el-button',{staticStyle:{"margin":"10px 0"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.add}},[_vm._v("新增")]),_c('ul',{staticClass:"G-TABLE G-TABLE-title"},[_c('li',{staticClass:"item item-title",staticStyle:{"min-width":"200px"}},[_vm._v("检验项")]),_c('li',{staticClass:"item item-title"},[_vm._v("标本")]),_c('li',{staticClass:"item item-title"},[_vm._v("结果")]),_c('li',{staticClass:"item item-title"},[_vm._v("单位")]),_c('li',{staticClass:"item item-title"},[_vm._v("参考值")]),_c('li',{staticClass:"item item-title"},[_vm._v("操作")])]),_c('el-form',{ref:"form",staticClass:"form",attrs:{"model":_vm.data}},_vm._l((_vm.data.evidences),function(domain,index){return _c('div',{key:domain.key,staticClass:"G-TABLE"},[_c('el-form-item',{staticClass:"item item1",staticStyle:{"min-width":"200px"},attrs:{"prop":'evidences.' + index + '.name',"rules":{
              required: true, message: '检验项不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"autosize":"","type":"textarea","size":"mini","placeholder":"请输入检验项"},model:{value:(domain.name),callback:function ($$v) {_vm.$set(domain, "name", $$v)},expression:"domain.name"}})],1),_c('el-form-item',{staticClass:"item",attrs:{"prop":'evidences.' + index + '.specimen',"rules":{
              required: true, message: '标本不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"autosize":"","type":"textarea","size":"mini","placeholder":"请输入标本"},model:{value:(domain.specimen),callback:function ($$v) {_vm.$set(domain, "specimen", $$v)},expression:"domain.specimen"}})],1),_c('el-form-item',{staticClass:"item",attrs:{"prop":'evidences.' + index + '.result',"rules":{
              required: true, message: '结果不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"autosize":"","type":"textarea","size":"mini","placeholder":"请输入结果"},model:{value:(domain.result),callback:function ($$v) {_vm.$set(domain, "result", $$v)},expression:"domain.result"}})],1),_c('el-form-item',{staticClass:"item",attrs:{"prop":'evidences.' + index + '.unit',"rules":{
              required: true, message: '单位不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"autosize":"","type":"textarea","size":"mini","placeholder":"请输入单位"},model:{value:(domain.unit),callback:function ($$v) {_vm.$set(domain, "unit", $$v)},expression:"domain.unit"}})],1),_c('el-form-item',{staticClass:"item",attrs:{"prop":'evidences.' + index + '.reference',"rules":{
              required: true, message: '参考值不能为空', trigger: 'blur'
            }}},[_c('el-input',{attrs:{"autosize":"","type":"textarea","size":"mini","placeholder":"请输入参考值"},model:{value:(domain.reference),callback:function ($$v) {_vm.$set(domain, "reference", $$v)},expression:"domain.reference"}})],1),_c('el-form-item',{staticClass:"item",staticStyle:{"text-align":"center"}},[_c('i',{staticClass:"el-icon-delete",staticStyle:{"cursor":"pointer","line-height":"30px"},on:{"click":function($event){return _vm.removeDomain(index)}}})])],1)}),0)],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.before_close}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }