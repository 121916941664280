import api from '../../utils/api'
export async function get_application(payload) {
  return api.get(`/api/v2/application/doctor`, {params: payload})
}
//馨提示
export async function get_trigger_list(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         list: [
  //           {
  //             application_name: '名字一',
  //             standard_id: 127,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色的更好的更好的更好的'
  //           },
  //           {
  //             application_name: '名字二',
  //             standard_id: 2,
  //             muted: false,
  //             hint_content: '是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色是个好伤感好伤感是个山光水色'
  //           }
  //         ]
  //       }
  //     })
  //   },500)
  // })
  return api.get(`/api/v2/ai/reports/${payload}/applications`)
}

// 换取test_no
export async function trans_test_no(payload) {
  // console.log(payload)
  // return new Promise((resolve)=>{
  //   setTimeout(()=>{
  //     resolve({
  //       data:{
  //         test_no: '19X0429525',
  //       }
  //     })
  //   },500)
  // })
  return api.post(`/api/v2/operation/his/test_no`,payload)
}

