import api from '../../utils/api'

export async function get_detail (payload) {
  return api.get(`/api/v2/ai/reports/lite/analysis/${payload.report_id}`, payload)
}

export async function get_timeline (payload) {
  return api.get(`/api/v2/reading2/patients/${payload}/reports`)
}

export async function trans_patient_id(payload) {
  return api.get(`/api/v2/reading2/patients/${payload}/translateId`)
}

// 获取化验项详情
export async function get_plans () {
  return api.get('/api/v2/plan')
}

export async function delete_plan (payload) {
  return api.post(`/api/v2/plan/${payload}/delete`)
}

// 院内历史诊断
export async function get_history_diag (payload) {
  return api.get(`/api/v2/patients/history_diag`, {params: payload})
}

export async function get_overview (payload) {
  return api.get(`/api/v2/ai/reports/patient/interpret`, {params: payload})
}

export async function get_time_line (payload) {
  return api.get(`/api/v2/ai/reports/patient/interpret/time_line`, {params: payload})
}

export async function if_fav (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v2/ai/reports/collect/${payload}/check`)
}

export async function my_fav (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v2/ai/reports/collect/${payload}`)
}

export async function no_my_fav (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.get(`/api/v2/ai/reports/collect/${payload}/cancel`)
}

export async function get_report (payload) {
  return api.get(`/api/v1/datacenter/itaggings/reports/list/${payload.patient_id}`, {params: payload})
}
