<template>
  <draggable w="auto" h="auto" style="width: 472px;z-index: 99999;" dragCancel=".not_drag" class="verson" >
    <div class="el-dialog" style="margin: auto; width: auto;" @click.stop>
      <div class="el-dialog__header">
        <slot name="title">
          <span class="el-dialog__title"></span>
        </slot>
        <button
          type="button"
          class="el-dialog__headerbtn"
          @click="close">
          <i class="el-dialog__close el-icon el-icon-close close"></i>
        </button>
      </div>
      <div class="el-dialog__body">
        <div class="title">
          <div class="text">我们更新了</div>
          <div class="verson-text">{{verson_config.update_verson_no}}</div>
        </div>
        <div class="content">
        <ul class="update_info">
          <li class="update">本次更新:</li>
          <li v-for="(item,el) in current_verson.update_content_list" :key="el+'i'">{{el + 1}}. {{item}}</li>
        </ul>

        </div>
      </div>
      <div class="el-dialog__footer" v-if="$slots.footer">
        <slot name="footer"></slot>
      </div>
    </div>
  </draggable>
</template>
<script>
import draggable from './vue-draggable-resizable'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      loading: false,
      verson_config: JSON.parse(qa_verson_config.verson_config)
    }
  },
  computed: {
    current_verson() {
      for(let i of this.verson_config.list) {
        if(i.verson_no === this.verson_config.update_verson_no){
          return i
        }
      }
      return {}
    }
  },
  components: {
    draggable
  },
  watch: {
  },
  created () {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
  }
}
</script>

<style scoped>
.verson {
  position: absolute;
  bottom: 0;
  right:0;
  max-height: 50%;
  overflow: auto;
}
.update_info{
  font-size: 16px;
  line-height: 24px;
  color: #303133;
  & .update {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    color: #303133;
    padding-bottom: 8px;
  }
}
.content {
  position: relative;
  padding: 0 20px;
  flex:1;
  z-index: 1;
  & .verson {
    font-size: 18px;
    font-weight: 400;
    color: #FFFFFF;
  }
}
.img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.title {
  background: url("../assets/img/verson.png") center center no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 160px;
  padding:20px;
  & .verson-text {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    color: #FFFFFF;
  }
}
.text {
  font-size: 24px;
  font-weight: 600;
  line-height: 33px;
  color: #FFFFFF;
}
.el-dialog__header {
  padding: 0px;
  margin-bottom: 0;
}
.el-dialog {
  border-radius: 6px;
}
.el-dialog__body {
  padding: 0px 0px 30px;

}
.el-dialog__headerbtn .el-dialog__close {
  color: #fff;
}
.verson{
  cursor: move;
}
.not_drag {
  cursor: default;
}
.close {
  font-size: 32px;

}

</style>
