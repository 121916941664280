<template>
<!-- CKD -->
  <div class="type_content" v-if="is_has_data">
    <ul class="flow-content left">
      <li v-for="(i,index) in list" :key="i.report_id" @click="select_report(i.report_id)" style="cursor: pointer;">
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': i.report_id === current_report_id}]">
          <div class="time time-left">{{format_date(i.report_time,'yyyy-MM-dd HH:mm')}}</div>
          {{i.diagnosis_name}}
        </div>
      </li>
    </ul>
    <div class="right is-hav-pad">
       <!--结论-- 显示化验列表和结论-->
      <template v-if="data.laboratories&&data.laboratories.length>0">
        <h2 class="commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          <span style="flex: 1">诊断依据：</span>
        </h2>
        <span v-if="data.patient_age" style="padding-right: 20px;">年龄：{{data.patient_age}}</span><span v-if="data.patient_gender" style="">性别：{{data.patient_gender}}</span>
        <LabTable :data="data.laboratories" :api_type="data.api_type"/>
      </template>
        <!--title - CKD -->
      <h2 class="source-3">处于<div :style="{background:hander_color(evidence_inference.reliable_name)}"/>{{evidence_inference.lab_x + evidence_inference.lab_y}}期，{{evidence_inference.reliable_name}}，请结合患者病因综合判断</h2>
      <div class="img_box">
        <CKDTable :params="evidence_inference"/>
      </div>
      <!-- 公共百科 -->
      <slot name="CommonCyclopedia"></slot>
    </div>
  </div>
</template>
<script>
import LabTable from './LabTable'
import CKDTable from './CKDTable'
import {format_date} from '../../../utils/format'
import { mapState,mapActions } from 'vuex'
import {get_ckd} from './service'
import {hander_commom_menus} from './config'
export default {
  data() {
    return {
      evidence_inference: {},
      is_first: true
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: String,
      default:''
    },
    is_has_data: {
      type: Boolean,
      default: false
    },
    patient_id:{
      type: String,
      default:''
    },
  },
  components: {
    LabTable,
    CKDTable
  },
  computed:{
    ...mapState('labDetails', {
      ckd_list: state => state.ckd_list
    }),
  },
  watch: {
    ckd_list(val) {
      this.$emit('update_list',val)
      if(val.length>0&&!this.current_report_id) {
        this.$emit('select_common_report',val[0].report_id)
      } else {
        this.search_ckd()
      }
      if(this.is_first) {
        this.$emit('update_loading',false)
        this.is_first = false
      }
    },
    current_report_id(){
      this.search_ckd()
    }
  },
  created() {
    this.$emit('update_loading',true)
    this.get_ckd_list(this.patient_id)
  },
  methods: {
    ...mapActions('labDetails', [
      'get_ckd_list',
    ]),
    format_date,
    // ckd
    hander_color(name) {
      let color = '#1890FF'
      switch(name) {
      case '极高危':
        color="#FA5C7C";
        break
      case '高危':
        color="#FFBC00";
        break
      case '中危':
        color="#2C8EF8";
        break
      default:
        color ="#1890FF"
      }
      return color
    },
    select_report(id) {
      this.$emit('select_common_report',id)
    },
    // ckd详情
    async search_ckd() {
      const {current_report_id} = this
      if(!current_report_id){
        return
      }
      this.$emit('update_loading',true)
      try {
        let params = {
          report_id:current_report_id
        }
        const {data} = await get_ckd(params)
        let list = []
        this.evidence_inference = data.evidence_inference
        if(data.laboratories&&data.laboratories.length>0) list.push({key: 'laboratories',name: '诊断依据'})
        hander_commom_menus(data, list)
        this.$emit('update_menus',list)
        this.$emit('update_data',{api_type: this.data.api_type,...data})
      } finally{
        this.$emit('update_loading',false)
      }
    },
  }
}
</script>
<style scoped>
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  color: #303133;
  display: flex;
  align-items: center;
  &.commom-text-top {
    padding-top: 32px;
  }
  &.commom-text-bottom{
     padding-bottom: 12px;
  }
  &.small-title {
    font-size: 16px;
  }
  & .info {
    font-size: 16px;
    line-height: 24px;
    color: #1890FF;
    flex:1;
     &.risk {
      color: red;
    }
  }
  & .line {
    width: 4px;
    height: 16px;
    background: #1890FF;
    border-radius: 3px;
    margin-right: 8px;
  }
  & .lab-icon {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
}
.flow-content{
  & >li {
    display: flex;
    position: relative;
    & .line {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 7px;
      height: 100%;
    }
    & .line-left {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 6px;
      height: 100%;
    }
    & .icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border: 4px solid rgba(0, 136, 255, 1);
      border-radius: 50%;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 1;
      &.icon-left {
        width: 14px;
        height: 14px;
      }
    }
    & .message {
      flex:1;
      font-size: 14px;;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 18px;
      color: #262626;
      margin-top: -2px;
      &.message-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #303133;
        &.select-message {
          color: #0088FF;
        }
      }
      & .time {
        font-size: 12px;;
        font-weight: 400;
        line-height: 19px;
        color: #565656;
        & .time-left {
          font-size: 12px;
          line-height: 17px;
          color: #606266;
          padding-top: 7px;
        }
      }
      & .add {
        font-size: 14px;;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}
.type_content {
  flex:1;
 & .left {
    width: 260px;
    overflow: auto;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding-left: 28px;
    top: 0;
    border-right: 1px solid rgba(112, 112, 112, 0.14);
    padding-top: 28px;
  }
  & .right {
    padding-bottom: 100px;
    margin-left: 260px;
    &.is-hav-pad{
      padding-left: 28px;
    }
  }
}
.source-3 {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  color: #6C757D;
  line-height: 26px;
  padding-bottom: 30px;
  padding-top: 32px;
  & div {
    width: 36px;
    height: 19px;
    background: #FFBC00;
    margin: 0 6px;
  }
}
.img_box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & img {
    max-width: 100%;
  }
}



</style>

