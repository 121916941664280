<template>
  <el-select
    class="select"
    size="medium"
    clearable
    filterable
    :loading="loading"
    remote
    :remote-method="remote_name"
    placeholder="检验名称"
    v-model="domain.original_laboratory_name">
    <el-option
      v-for="(item) in name_list"
      :value="item.name"
      :label="item.name"
      :key="item.name">
    </el-option>
  </el-select>
</template>
<script>
import {get_item_list} from './service'
export default {
  data() {
    return {
      name_list:[],
      loading: false
    }
  },
  props: {
    domain: {
      type: Object,
      default: () => {}
    },
    or_name_list: {
      type: Array,
      default: () => []
    },
  },
  watch: {
    or_name_list() {
      this.name_list = this.or_name_list
    }
  },
  created() {
    this.name_list = this.or_name_list
  },
  methods: {
    async remote_name(val) {
      this.loading = true
      try {
        let params ={
          page: 0,
          page_size: 200,
          laboratory_name: val,
          type:1
        }
        const {data} = await get_item_list(params)
        this.name_list = data.data || []
      } finally {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.select {
  width: 100%;
}
</style>
