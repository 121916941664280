<template>
  <div class="editor_comp">
    <!-- 默认文字 -->
    <div class="placeh" v-show="!value">{{placeholder}}</div>
    <!-- 内容 -->
    <div class="editor_content" contentEditable ref="editor"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: '请输入'
    },
    id_prefix: {
      type: String,
      default: 'editor_'
    },
  },
  data() {
    return {
      editor: null,
      timer: null,
      old_value: '',
      range: null, // 当前选区
      mark_name: `${this.id_prefix}mark_text`,
    }
  },
  components: {
  },
  watch: {
    value(val) {
      let current_value = this.editor.innerHTML
      if (current_value !== val) {
        this.editor.innerHTML = val
      }
    }
  },
  created() {
  },
  mounted() {
    this.editor = this.$refs['editor']
    this.init_data()
    this.bind_event()
    document.addEventListener('mouseup', this.rangePosition, false)
  },
  destroyed () {
    this.unbind_event()
    document.removeEventListener('mouseup', this.rangePosition, false)
  },
  methods: {
    // 选择定位，为了兼容ie浏览器，contentEditable=false，还能被选择
    rangePosition () {
      let selection = window.getSelection()
      if (selection.isCollapsed) {
        let range = selection.getRangeAt(0)
        let parent = range.startContainer.parentNode
        if (this.is_mark(parent)) {
          range.setStartBefore(parent)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        }
      }
    },
    init_data () {
      if (this.value) {
        this.editor.innerHTML = this.value
        this.old_value = this.value
      }
    },
    // 监听函数
    observer () {
      if (this.timer) clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        let current_value = this.editor.innerHTML
        if (current_value !== this.old_value) {
          this.old_value = current_value
          this.$emit('input', current_value)
        }
      }, 0)
    },
    // 绑定事件
    bind_event () {
      this.editor.addEventListener('keyup', this.observer, false)
      this.editor.addEventListener('mouseup', this.observer, false)
      this.editor.addEventListener('blur', this.observer, false)
      this.editor.addEventListener('paste', this.observer, false)
    },
    // 删除事件
    unbind_event () {
      this.editor.removeEventListener('keyup', this.observer, false)
      this.editor.removeEventListener('mouseup', this.observer, false)
      this.editor.removeEventListener('blur', this.observer, false)
      this.editor.removeEventListener('paste', this.observer, false)
    },
    // 获取选择区域（必须选择范围）
    get_range () {
      let selection = window.getSelection()
      if (selection.isCollapsed) return null
      let range = selection.getRangeAt(0)
      let parentNode = range.commonAncestorContainer

      // 是否处于编辑框
      let flag = false
      while (parentNode) {
        if (this.editor === parentNode) {
          flag = true
          break
        }
        parentNode = parentNode.parentNode
      }
      if (!flag) return null

      return range
    },
    // 获取选择区域
    get_range_2 () {
      let selection = window.getSelection()
      if (selection.rangeCount === 0) return null
      let range = selection.getRangeAt(0)
      let parentNode = range.commonAncestorContainer

      // 是否处于编辑框
      let flag = false
      while (parentNode) {
        if (this.editor === parentNode) {
          flag = true
          break
        }
        parentNode = parentNode.parentNode
      }
      if (!flag) return null

      return range
    },
    // 保存选区
    save_range () {
      this.range = this.get_range()
    },
    save_range_2 () {
      this.range = this.get_range_2()
    },
    // 恢复选区
    restore_range () {
      if (this.range) {
        document.getSelection().removeAllRanges()
        document.getSelection().addRange(this.range)
        this.range = null
      }
    },
    // 创建节点
    create_node (options) {
      options = {
        value: '', // 文本
        dataset: {}, // data属性
        styles: {}, // 样式
        type: 1, // 类型
        ...options,
      }
      let node = null
      if (options.type === 1) {
        node = document.createElement('label')
        node.setAttribute(this.mark_name, '')
        node.setAttribute('contentEditable', false)
        // data属性
        for (let [key, value] of Object.entries(options.dataset)) {
          node.dataset[`${this.id_prefix}${key}`] = value
        }
        // 样式
        for (let [key, value] of Object.entries(options.styles)) {
          node.style[key] = value
        }
        node.innerText = options.value
      } else {
        node = document.createTextNode(options.value)
      }
      return node
    },
    is_mark (node) {
      return node.nodeType === 1 && node.getAttribute(this.mark_name) === ''
    },
    // 获取选择的最小片段
    get_fragments () {
      let range = this.get_range()
      if (!range) return {
        start_offset: 0,
        end_offset: 0,
        min_fragments: [],
        mark_fragments: [],
      }

      let {commonAncestorContainer, startContainer, endContainer, startOffset, endOffset} = range
      // 标记片段
      let mark_fragments = []
      // 最小片段
      let min_fragments = []
      if (this.is_mark(commonAncestorContainer) || commonAncestorContainer.childNodes.length === 0) {
        min_fragments.push(commonAncestorContainer)
      } else {
        // 判断是否在范围内
        let start_flag = false
        let end_flag = false
        // 递归
        const re = (arr) => {
          if (!arr || arr.length === 0) return
          for (let node of arr) {
            if (end_flag) return
            // 是否开始
            if (startContainer.childNodes.length === 0) {
              if (node === startContainer) start_flag = true
            } else {
              if (node === startContainer.childNodes[startOffset]) {
                start_flag = true
                startOffset = 0
              }
            }
            if (start_flag) {
              // 标记片段
              if (this.is_mark(node)) {
                mark_fragments.push(node)
                min_fragments.push(node)
              }
              // 最小片段
              else if (node.childNodes.length === 0) {
                min_fragments.push(node)
              }
            }
            // 是否结束
            if (endContainer.childNodes.length === 0) {
              if (node === endContainer) end_flag = true
            } else {
              if (node === endContainer.childNodes[endOffset]) {
                end_flag = true
                endOffset = 0
              }
            }
            if (!this.is_mark(node)) {
              re(node.childNodes)
            }
          }
        }
        re(commonAncestorContainer.childNodes)
      }
      return {
        start_offset: startOffset,
        end_offset: endOffset,
        min_fragments,
        mark_fragments,
      }
    },
    // 添加属性
    add_props (options={}) {
      options = {
        dataset: {}, // data属性
        styles: {}, // 样式
        ...options,
      }
      const {start_offset, end_offset, min_fragments} = this.get_fragments()
      // 标注
      for (let i = 0, len = min_fragments.length; i < len; i++) {
        let node = min_fragments[i]
        let parentNode = node.parentNode
        // 元素
        if (node.nodeType === 1) {
          node.setAttribute(this.mark_name, '')
          node.setAttribute('contentEditable', false)
          // data属性
          for (let [key, value] of Object.entries(options.dataset)) {
            node.dataset[`${this.id_prefix}${key}`] = value
          }
          // 样式
          for (let [key, value] of Object.entries(options.styles)) {
            node.style[key] = value
          }
        }
        // 文本
        else if (node.nodeType === 3) {
          let fragment = document.createDocumentFragment()
          let nodeValue = node.nodeValue
          for (let j = 0, len2 = nodeValue.length; j < len2; j++) {
            let value = nodeValue[j]
            let elem = null
            if (len === 1) {
              if (j >= start_offset && j < end_offset) {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 1,
                })
              } else {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 2,
                })
              }
            } else if (i === 0) {
              if (j >= start_offset) {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 1,
                })
              } else {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 2,
                })
              }
            } else if (i === len - 1) {
              if (j < end_offset) {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 1,
                })
              } else {
                elem = this.create_node({
                  ...options,
                  value,
                  type: 2,
                })
              }
            } else {
              elem = this.create_node({
                ...options,
                value,
                type: 1,
              })
            }
            fragment.appendChild(elem)
          }
          parentNode.replaceChild(fragment, node)
        }
      }
      // 去掉鼠标范围
      window.getSelection().removeAllRanges()
      this.observer()
    },
    // 删除属性
    del_props (options={}) {
      options = {
        dataset: [], // data属性
        styles: [], // 样式
        ...options,
      }
      const {mark_fragments} = this.get_fragments()
      for (let i = 0, len = mark_fragments.length; i < len; i++) {
        let node = mark_fragments[i]
        // data属性
        for (let key of options.dataset) {
          node.removeAttribute(`data-${this.id_prefix}${key}`)
        }
        // 样式
        for (let key of options.styles) {
          node.style[key] = ''
        }
        // 是否删除
        let attr_list = []
        for (let attr of Object.keys(node.dataset)) {
          if (attr.startsWith(this.id_prefix)) {
            attr_list.push(attr)
          }
        }
        if (attr_list.length === 0 && [...node.style].length === 0) {
          if (node.nodeName.toUpperCase() === 'LABEL') {
            let new_node = document.createTextNode(node.innerText)
            node.parentNode.replaceChild(new_node, node)
          } else {
            node.removeAttribute(this.mark_name)
          }
        }
      }

      // 去掉鼠标范围
      window.getSelection().removeAllRanges()
      this.observer()
    },
    // 插入内容
    insert (node) {
      let range = this.get_range_2()
      if (range) {
        range.insertNode(node)
        document.getSelection().removeAllRanges()
      } else {
        this.editor.appendChild(node)
      }
      this.observer()
    },
    // html操作
    exec_command (aCommandName, aShowDefaultUI, aValueArgument) {
      let range = this.get_range()
      if (!range) return
      const bool =  document.execCommand(aCommandName, aShowDefaultUI, aValueArgument)
      this.observer()
      return bool
    },
  }
}
</script>

<!-- Add "scoped " attribute to limit CSS to this component only -->
<style scoped>
.editor_comp {
  position: relative;
  height: 100%;
}
.placeh {
  color: #ccc;
  position: absolute;
  top: 5px;
  left: 15px;
}
.editor_content {
  tab-size: 4;
  white-space: pre-wrap;
  word-wrap: break-word;
  min-height: 54px;
  width: 100%;
  height: 100%;
  outline: none;
  padding: 5px 15px;
  position: relative;
  z-index: 2;
}
</style>
