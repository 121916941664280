<template>
   <el-dialog
      width="50%"
      title=""
      :visible.sync="visible"
      :before-close="before_close"
      append-to-body>
      <div class="title" slot="title">
        {{item.title || '提示'}}
      </div>
      <div class="body">
        <Rich class="html"
         v-if="item.hint_content"
          v-model="item.hint_content"
          :style_type="2"
          style="padding-top: 27px"
        />
        <template v-if="item.fx">
         <h2 class="commom-text">
          <div class="line"/>
          提示考虑方向
        </h2>
        <Rich class="html"
          v-model="item.fx"
          :style_type="2"
        />
      </template>
        <template v-if="item.cl">
           <h2 class="commom-text">
          <div class="line"/>
          提示处理
          </h2>
          <Rich class="html"
            v-model="item.cl"
            :style_type="2"
          />
      </template>
       <template v-if="sub_hint.length>0">
           <h2 class="commom-text">
          <div class="line"/>
          提示处理
          </h2>
           <Rich
           class="html"
            v-for="(i,index) in item.sub_hint"
            :key="index"
            v-model="i.cl"
            :style_type="2"
          />



      </template>


      </div>
    </el-dialog>
</template>
<script>
import Rich from '../rich/index.vue'
export default {
  data() {
    return {
      loading: false,
      data: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: ()=>{}
    }
  },
  components: {
    Rich
  },
  computed: {
    sub_hint() {
      let sub_hint = this.item.sub_hint || []
      for(let i of sub_hint) {
        i.cl = i.title + '：<br>' + i.cl
      }
      return sub_hint
    },
  },
  created() {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
  }
}
</script>
<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  color: var(--color-text-primary);
}
.body {
  font-size: 14px;
  line-height: 18px;
  min-height: 150px;
}

>>>.el-dialog__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
}
>>>.el-dialog__body {
  padding: 0 27px 40px;
}
.html {
  font-size: 16px;
  color: var(--color-text-secondary);
}
.commom-text {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  padding: 27px 0 10px;
  color: var(--color-text-primary);
  display: flex;
  align-items: center;
  & .line {
    width: 4px;
    height: 16px;
    background: var(--color-primary);
    border-radius: 3px;
    margin-right: 8px;
  }
}

</style>
