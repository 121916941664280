
//grace配置
export const grace_config = {
  cardiac_arrest: false,
  st_down: false,
  cardiac_enzymes_high: false,
  age: null,
  heart_rate:  null,
  blood_pressure: null,
  serum_creatinine: null,
  kill_ip_rank: null,
  blood_pressure_unit: 'mmHg',
  serum_creatinine_unit: 'μmol/L',
  evidence_modify: false,
  evidences: []
}
//grace配置
export const exudate_config = {
  laboratories: [],
}

//量表字段
export const form_obj = {
  name: '',
  gender: '',
  age: null,
  weight: null,
  weight_unit: 'kg',
  operation: '',
  lymph_metastasis: null,
  capsular_invasion: '',
  medicine_name: '',
  medicine_time: '',
  medicine_metering: '',
  other_medicine_metering: '',
  palpitation: '',
  tsh: '',
  tsh_unit:'mU/L',
  ft3: '',
  ft3_unit: 'pmol/L',
  ft4: '',
  ft4_unit: 'pmol/L',
  anxiety: '',
  fatigue: '',
  insomnia: '',
  lethargy	: '',
  weight_change: '',
  other_medicines: '',
  headache: '',
  flushing: '',
  diarrhea: '',
  calcium: '',
}
export const default_units={
  ft4_unit: 'pmol/L',
  ft3_unit: 'pmol/L',
  tsh_unit:'mU/L',
  weight_unit: 'kg',
}

// 评分量表
export const score_table_list = [
  {
    value: 1,
    level: '低危',
    score_range: '小于等于108'
  },
  {
    value: 2,
    level: '中危',
    score_range: '109-139'
  },
  {
    value: 3,
    level: '高危',
    score_range: '大于等于140'
  },
]

//Killip分级
export const killip_list = [
  {
    value: 1,
    label: '无明显的心力衰竭',
    level: 'Ⅰ级'
  },
  {
    value: 2,
    label: '有左心衰竭，肺部啰音<50%肺野，奔马律，窦性心动过速或其他心律失常，静脉压升高，X线胸片有肺淤血的表现',
    level: 'II级'
  },
  {
    value: 3,
    label: '肺部啰音>50%肺野，可出现急性肺水肿',
    level: 'III级'
  },
  {
    value: 4,
    label: '心源性休克，有不同阶段和程度的血液动力学障碍',
    level: 'IV级'
  }
]


// 公共百科菜单
export function hander_commom_menus(data,list) {
  const {clinical_manifestations,condition_assessment,introduction,special_inspection,recommends=[]} = data
  if(introduction)list.push({key: 'introduction',name: '疾病简介'})
  let arr1 = recommends.filter(item=>{
    return item.type!==2
  })
  let arr2 = recommends.filter(item=>{
    return item.type===2
  })
  if(arr1&&arr1.length>0)list.push({key: 'inspection',name: '常开检验'})
  if(arr2&&arr2.length>0)list.push({key: 'check',name: '常开检查'})
  if(condition_assessment)list.push({key: 'condition_assessment',name: '诊断标准和病情评估'})
  if(clinical_manifestations || special_inspection)list.push({key: 'clinical_manifestations',name: '临床表现'})
  return list
}
//处理渗透液数据
export function hander_data_6(data) {
  data.laboratories = []
  if(data.total_protein) {
    data.total_protein.key = 'total_protein'
    data.laboratories.push(data.total_protein)
  }
  if(data.protein_quantification) {
    data.protein_quantification.key = 'protein_quantification'
    data.laboratories.push(data.protein_quantification)
  }
  if(data.fluid_lactate_dehydrogenase) {
    data.fluid_lactate_dehydrogenase.key = 'fluid_lactate_dehydrogenase'
    data.laboratories.push(data.fluid_lactate_dehydrogenase)
  }
  if(data.blood_lactate_dehydrogenase) {
    data.blood_lactate_dehydrogenase.key = 'blood_lactate_dehydrogenase'
    data.laboratories.push(data.blood_lactate_dehydrogenase)
  }
  return data
}

//处理渗透液提交数据
export function hander_param_6(data) {
  for(let i of data.laboratories) {
    data[i.key] = i
  }
  return data
}

//剂量
export const dose_list =  ['50ug','75ug','100ug','125ug','150ug','其他']
//应用系统
export const systems = [
  {"label":"五官科疾病",value:1 },
  {"label":"产科疾病", value: 2},
  {"label":"其他",value: 3},
  {"label":"内分泌代谢性疾病", value: 4},
  {"label":"呼吸系统疾病",value: 5},
  {"label":"心血管疾病",value: 6},
  {"label":"感染性疾病",value: 7},
  {"label":"泌尿系统疾病",value: 8},
  {"label":"消化系统疾病",value: 9},
  {"label":"生殖系统疾病",value: 10},
  {"label":"皮肤疾病",value: 11},
  {"label":"神经系统疾病",value: 12},
  {"label":"精神疾病",value: 13},
  {"label":"肌肉骨骼疾病",value: 14},
  {"label":"肿瘤性疾病",value: 15},
  {"label":"血液系统疾病",value: 16},
  {"label":"风湿免疫病",value: 17}
]

//ckd颜色
export function hander_color(name) {
  let color = '#1890FF'
  switch(name) {
  case '极高危':
    color="#FA5C7C";
    break
  case '高危':
    color="#FFBC00";
    break
  case '中危':
    color="#786AE5";
    break
  default:
    color ="#07c160"
  }
  return color
}

// 新冠配置
export const type9_config = {
  name: '',
  report_time: null,
  results: [],
  gender: '',
  age: null,
  weigh: null,
  height: null,
  patient_weight_unit: 'KG',
  patient_height_unit: 'CM',
  BMI: null,
  pul_ct_abnormality: '',
  chronic_history: [],
  history_tumor: [],
  drug_history: [],
  other_risk: [],
  inspection: [],
  biochemistry: [],
  immune: [],
  coagulation: [],
  other_warning:[],
  illness_factor: [],
  severe_factor: [],
  paO2: null,
  fiO2: null,
  pCF: null,
  imaging_evaluation: {},
}
// 新冠影像学评估配置
export const type9_config_imaging_evaluation = {
  left_upper_blade: null,
  left_upper_score: null,
  left_midd_blade: null,
  left_midd_score: null,
  left_lower_blade: null,
  left_lower_score: null,
  right_upper_blade: null,
  right_uppper_score: null,
  right_lower_blade: null,
  right_lower_score: null,
}

//肾上腺皮质
export const type11_config = {
  report_id: '',
  hypercalcemia: '',
  symptoms: []
}
//肾上腺皮质
export const type11_symptoms = [
  '暂无其他症状体征',
  '低血压',
  '发热',
  '呕吐',
  '乏力',
  '纳差（食欲不振）',
  '口服/外服糖皮质激素类药物史，且当前已停用',
  '颅脑手术史',
  '肾上腺手术史',
  '大出血史',
  '脑血管意外史',
]


