import api from '../../../utils/api'

// 获取化验项详情
export async function get_data () {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         data: [
  //           {
  //             laboratories: [
  //               {
  //                 laboratory_name: '化验名称1'
  //               },
  //               {
  //                 laboratory_name: '化验名称2'
  //               },
  //               {
  //                 laboratory_name: '化验名称3'
  //               },
  //               {
  //                 laboratory_name: '化验名称4'
  //               }
  //             ],
  //             plan_name: '方案一',
  //             plan_id: 1
  //           },
  //           {
  //             laboratories: [
  //               {
  //                 laboratory_name: '化验名称1'
  //               },
  //               {
  //                 laboratory_name: '化验名称2'
  //               },
  //               {
  //                 laboratory_name: '化验名称3'
  //               },
  //               {
  //                 laboratory_name: '化验名称4'
  //               }
  //             ],
  //             plan_name: '方案2',
  //             plan_id: 2
  //           },

  //         ]
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v2/plan')
}

export async function to_del_item (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {}
  //     })
  //   }, 500)
  // })
  return api.post(`/api/v2/plan/${payload}/delete`)
}
