<template>
  <div>
    <el-drawer
      :visible.sync="visible"
      class="my-drawer"
      size="600px"
      top="40px"
      :destroy-on-close="true"
      :append-to-body="true"
      :before-close="before_close">
      <div class="title" slot="title">
        <h1 class="text">{{name}}</h1>
      </div>
      <div class="dialog_content" v-loading="loading">
        <template v-if="name === '性能'">
          <h2 class="h2 h2-1" >介绍</h2>
          <div><span class="add-bold">AUC(Area Under Curve)</span>是处于ROC曲线下方的那部分面积的大小。<span class="add-bold">AUC越大，代表模型的性能越好</span>。是处于ROC曲线下方的那部分面积的大小。AUC越大，代表模型的性能越好。</div>
          <div style="padding-top: 28px">
            <span class="add-bold">真正类率</span>(true positive rate,<span class="add-bold">TPR</span>),也称为<span class="add-bold">敏感性(sensitivity)</span>
             <br>
            <img style="margin-top: 8px" src="../../../assets/img/detail/tpr.png"/>
          </div>
          <div style="padding: 28px 0">
            <span class="add-bold">真负类率</span>(true negative rate,<span class="add-bold">TNR</span>),也称为<span class="add-bold">特异性(specificity)</span>
             <br>
            <img style="margin-top: 8px" src="../../../assets/img/detail/tnr.png"/>
          </div>
          TP：（真阳性）被正确诊断为患病的病人<br/> TN：（真阴性）被正确诊断为健康的健康人<br/> FP：（假阳性）被错误诊断为患病的健康人<br/> FN：（假阴性）被错误诊断为健康的病人
        </template>
        <template v-if="name === 'ROC曲线'">
          <h2 class="h2 h2-1">定义</h2>
          <div> <span class="add-bold">ROC</span>的全称是<span class="add-bold">Receiver Operating Characteristic Curve</span>，中文名字叫<span class="add-bold">“受试者工作特征曲线”</span>，顾名思义，其主要的分析方法就是画这条特征曲线。曲线的横坐标为假阳性率（False Positive Rate, FPR），纵坐标为真阳性率（True Positive Rate, TPR）。通常我们希望TPR越高越好，FRP越低越好，因此形成了相互制约的两个指标。当ROC完成曲线后，就会对模型有一个定性的分析，如果要对模型进行量化的分析，此时需要引入一个新的概念，就是AUC（Area under roc Curve）值，指ROC曲线下的面积大小，而计算AUC值只需要沿着ROC横轴做积分就可以了。AUC的值越大，说明该模型的性能越好。</div>
        </template>
        <template v-if="name === 'SHAP图'">
          <h2 class="h2 h2-1" >介绍</h2>
          <div >Lundberg和Lee的论<span class="text1">文<span>【1】</span></span>提出了SHAP（SHapley Additive exPlanation）值这一广泛适用的方法用来解释各种模型（分类以及回归）。SHAP是由Shapley value启发的可加性解释模型。Shapley value起源于合作博弈论，Shapley value的解释是：给定当前的一组特征值，一个特征值对实际预测和平均预测之间的差异的贡献是估计的 Shapley value。SHAP 最大的优势是能反映出每一个样本中的特征的影响力，而且还表现出影响的正负性。</div>
           <h2 class="h2" >示例</h2>
           <img style="max-width: 100%" height="auto" src="../../../assets/img/detail/shap.png">
           <div style="padding: 8px 0;">上图中，每个点都是一个特征和一个实例的 Shapley value。y 轴上的位置由特征确定，x 轴上的位置由 Shapley value确定<span class="add-bold">（左负右正）</span>。颜色从低到高代表特征的值（Feature value）<span class="add-bold">（红色表示特征值较高，蓝色表示特征值较低）</span>。重叠点在 y 轴方向抖动，因此我们可以了解每个特征的 Shapley value的分布。这些特征是根据它们的重要性<span class="add-bold">自上而下</span>排序的。上图肠癌模型中，年龄是肠癌最重要的影响因素，年龄大会增加肠癌的预测风险值；CA199是次重要的影响因素，CA199的升高会增加肠癌的风险预测值；总血红蛋白浓度降低会增加肠癌的风险预测值。</div>
           <span style="color:rgba(254, 177, 16, 1)">提醒：</span>所有的效果都描述了模型的数据分布，在现实世界中不一定是因果关系。
           <h2 class="h2" >参考文献</h2>
           <div class="wenxian">
            【1】A Unified Approach to Interpreting Model Predictions，Part of Advances in Neural Information Processing Systems 30 (NIPS 2017) ，Scott M. Lundberg, Su-In Lee.
              <br>
            【2】Code:https://github.com/slundberg/shap
           </div>
        </template>
      </div>
    </el-drawer>
  </div>
</template>
<script>

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    name: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      loading:false
    }
  },
  computed: {
  },
  components: {

  },
  watch: {
  },
  created() {

  },
  methods: {
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.close()
    },
  }
}
</script>
<style>
.jup{
  color: #6C757D;
  &:hover{
    color:#1890FF;
  }
}
.evidence .el-dialog__header {
  border-bottom: 1px solid #DEE2E6;
}
.decision_dislog {
  & .el-dialog__header {
    position: relative;
    z-index: 2;
  }
}
</style>
<style scoped>
.title {
  position: relative;
  & .text{
    position: relative;
    z-index: 1;
    font-size: 30px;
    font-weight: bold;
    color: #303133;
    padding:40px 28px 0px;
    padding-bottom:32px;
    & >span {
      font-size: 20px;
      font-weight: 400;
      color: #98A6AD;
      line-height: 28px;
      margin-left: 12px;
    }
  }
  & .img {
    position: absolute;
  }
}
.text1 {
  position: relative;
  padding-right: 25px;
  & >span {
    font-size: 12px;
    position: absolute;
    top: -5px;
  }
}

.dialog_content {
  padding: 0 28px 32px;
 font-size: 16px;
  line-height: 24px;
  color: #303133;
  overflow: auto;
}
.h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  color: #303133;
  padding: 28px 0 8px;
  &.h2-1 {
    padding-top: 0px;
  }

}
.wenxian {
  color: #606266;
  line-height: 24px;
}
.add-bold {
  font-weight: bold;
}
>>>.el-drawer.rtl {
  overflow: auto;
}

>>>.el-drawer__body {
  padding: 0px 20px;
  position: relative;
  z-index: 10;
}
>>>.el-drawer__header {
padding: 0px;
}
>>>.el-drawer__close-btn {
  position: absolute;
  right: 20px;
  top:40px;
  z-index: 100;

  & .el-dialog__close {
    transform: scale(1.4);
    font-size: 22px;
    color: #707070;
    font-weight: bold;
  }
}
>>> .el-drawer__header {
  margin-bottom: -12px;
}

</style>

