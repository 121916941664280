export default {
  namespaced: true,
  state: {
    search_params: null,
  },
  mutations: {
    clear_state(state) {
      state.search_params = null
    },
    update_search_params(state, payload) {
      state.search_params = payload
    },
  },
  actions: {
  }
}
