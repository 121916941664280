<template>
<!-- 渗透液 -->
  <div class="type_content" v-if="is_has_data">
    <ul class="flow-content left">
      <li @click="select_report('')" style="cursor: pointer;" v-if="current_item&&!current_item.disable">
        <div class="line-left" v-if="list.length>0"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': !current_report_id}]">
          <div class="add">新建</div>
        </div>
      </li>
      <li v-for="(i,index) in list" :key="i.report_id" @click="select_report(i.report_id)" style="cursor: pointer;">
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': i.report_id === current_report_id}]">
          <div class="time time-left">{{format_date(i.report_time,'yyyy-MM-dd HH:mm')}}</div>
          {{i.diagnosis_name}}
        </div>
      </li>
    </ul>
    <div class="right is-hav-pad">
      <div class="result">
        <div class="result-text" v-if="data.diagnosis_name">
         综合判断
         <div class="score">以Light标准，多考虑为：<span>{{data.diagnosis_name}}</span></div>
        </div>
        <div class="bt">
          <el-button v-if="current_report_id&&data.mine" type="danger" round size="small" @click="to_del_exudate">删除</el-button>
          <el-button type="primary" round size="small" @click="is_show = false" v-if="is_show&&data.mine">编辑</el-button>
          <div class="tixing" v-if="!is_show">
            <el-button type="primary" slot="reference" style="margin-left: 10px;" round size="small" @click="to_submit_exudate" :disabled="!is_assessment">保存</el-button>
            <div :class="['massage',{'massage-1': !is_assessment}]" v-if="visible">
              <i class="el-icon-caret-top tixing-arrow"/>
              {{!is_assessment ? '先点击查看结果再保存' : '您可以保存当前报告结果，供其他医生查阅'}}
              <i @click="visible = false" style="font-weight: bold;cursor: pointer;" class="el-icon-close"/>
            </div>
          </div>
        </div>
      </div>
      <div class="title">模型相关检验项：</div>
      <el-form :rules="rules" ref="ruleForm" :model="data" v-if="!is_show">
        <div class="form">
          <div
            :style="{width:i.key==='blood_lactate_dehydrogenase' ? '100%' :'50%',paddingRight: '24px'}"
            v-for="(i,index) in data.laboratories"
            :key="i.laboratory_id">
            <div v-if="i.key==='blood_lactate_dehydrogenase'" class="range">
              <el-form-item
                :prop="'laboratories.' + index + '.original_result'"
                style="width: 66%; padding-right: 24px;"
                :label="i.original_laboratory_name + '(' + i.original_specimen + ')' + '结果'" >
                  <MyInput
                    clearable
                    v-model="i.original_result"
                    :disabled="is_show || i.is_disabel"
                    :decimalDigits="2"
                    :unit="i.original_unit"
                    placeholder="请填写数值"
                  />
                </el-form-item>
                <el-form-item
                class="lower"
                :prop="'laboratories.' + index + '.range_lower'"
                style="width: 40%;padding-top: 40px;"
                :rules="{validator: validatePass_low, trigger: 'change'}"
                :label="i.original_laboratory_name + '(' + i.original_specimen + ')' + '区间'" >
                   <MyInput
                    clearable
                    v-model="i.range_lower"
                    :disabled="is_show"
                    :decimalDigits="2"
                    placeholder="参数区间下限"
                  />

                </el-form-item>
                <div style="padding:15px 10px 0;"> - </div>
              <el-form-item
              :prop="'laboratories.' + index + '.range_upper'"
              style="width: 40%;padding-top: 40px;"
              :rules="{validator: validatePass_up, trigger: 'change'}" >
                <MyInput
                  clearable
                  v-model="i.range_upper"
                  :disabled="is_show"
                  :decimalDigits="2"
                  placeholder="参数区间上限"
                />
              </el-form-item>
            </div>
            <el-form-item
            v-else
            style="width: 100%;"
            :prop="'laboratories.' + index + '.original_result'"
            :label="i.original_laboratory_name + '(' + i.original_specimen + ')' + '结果'" >
              <MyInput
                clearable
                v-model="i.original_result"
                :disabled="is_show || i.is_disabel"
                :decimalDigits="2"
                :unit="i.original_unit"
                placeholder="请填写数值"
              />
            </el-form-item>
            </div>

          </div>
         <el-form-item class="result">
          <div class="result-but">
            <el-button :disabled="(current_report_id&&!data.mine) || is_show" type="primary" round size="small"  @click="assessment_report">点击查看结果</el-button>
            <div class="score" v-if="data.diagnosis_name">以Light标准，多考虑为：<span>{{data.diagnosis_name}}</span></div>
          </div>

        </el-form-item>
      </el-form>
      <LabTable :data="data.laboratories" v-else/>
      <slot name="CommonCyclopedia"></slot>
    </div>
    <ApiType6Dialog
      :visible.sync="no_visible"
      v-if="no_visible"/>
  </div>
</template>
<script>
import {format_date} from '../../../utils/format'
import LabTable from './LabTable'
import {killip_list,score_table_list} from './config'
import MyInput from './MyInput'
import {exudate_config,hander_commom_menus,hander_data_6,hander_param_6} from './config'
import { submit_exudate,del_exudate,get_lab_list,submit_assessment_exudate,get_exudate} from './service'
import { mapState,mapActions } from 'vuex'
import ApiType6Dialog from './ApiType6Dialog'
export default {

  data() {
    var validatePass_low = (rule, value, callback) => {
      let {range_upper} = this.t_obj
      if ((value || value===0)&&range_upper && (+range_upper<+value)) {
        callback(new Error('区间上限要大于区间下限'));
      } else {
        callback()
      }
    }
    var validatePass_up = (rule, value, callback) => {
      let {range_lower} = this.t_obj
      if ((value || value===0)&&range_lower && (+range_lower>+value)) {
        callback(new Error('区间上限要大于区间下限'));
      } else {
        callback()
      }
    };
    return {
      last_select_radio: 1,
      visible:true,
      rules: {
      },
      killip_list: killip_list,
      score_table_list: score_table_list,
      is_show: true,
      validatePass_low,
      validatePass_up,
      is_first: true,
      is_assessment: false,
      no_visible: false
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: String,
      default:''
    },
    is_has_data: {
      type: Boolean,
      default: false
    },
    patient_id:{
      type: String,
      default:''
    },
    current_item: {
      default: null,
      type: Object
    },
    report_id:{
      type: String,
      default:''
    },
  },
  computed:{
    t_obj() {
      for(let i of this.data.laboratories) {
        if(i.key==='blood_lactate_dehydrogenase') {
          return i
        }
      }
      return null
    },
    ...mapState('labDetails', {
      exudate_list:state => state.exudate_list,
    }),
  },
  components: {
    LabTable,
    MyInput,
    ApiType6Dialog
  },
  watch: {
    current_report_id() {
      this.search_exudate()
      this.visible = true
      this.is_show = !!this.current_report_id
    },
    is_show(val) {
      this.$emit('is_edit',!val)
    },
    exudate_list(val) {
      this.$emit('update_list',val)
      if(val.length>0&&!this.current_report_id&&(this.current_item && !this.current_item.hint)) {
        this.$emit('select_common_report',val[0].report_id)
      } else {
        this.search_exudate()
      }
      if(this.is_first) {
        this.$emit('update_loading',false)
        this.is_first = false
      }
    },
  },
  created() {
    this.$emit('update_loading',true)
    this.get_exudate_list(this.patient_id)
    if(!this.current_report_id) this.is_show = false
    this.$emit('is_edit',!this.is_show)
  },
  methods: {
    ...mapActions('labDetails', [
      'get_exudate_list',
      'get_my_application'
    ]),
    format_date,
    select_report(id) {
      this.$emit('select_common_report',id)
    },
    to_submit_exudate() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确定要保存结果?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.submit_exudate(this.data)
          }).catch(() => {
          });
        }
      })
    },
    to_del_exudate() {
      this.$confirm('此操作将永久删除该报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del_exudate(this.data)
      }).catch(() => {
      });
    },
    assessment_report() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.assessment_report_exudate(this.data)
        }
      })
    },
    // 渗出液详情
    async search_exudate() {
      this.$emit('update_loading',true)
      let list = []
      // 菜单
      this.$emit('update_menus',list)
      const {current_report_id} = this
      if(!current_report_id){
        if(this.current_item&&!this.current_item.disable){
          list.push({key: 'laboratories',name: '模型'})
          // 菜单
          this.$emit('update_menus',list)
          this.search_init_exudate()
        } else{
          this.$emit('update_loading',false)
        }
        return
      }
      try {
        let params = {
          report_id:current_report_id
        }
        let {data} = await get_exudate(params)
        data = hander_data_6(data)
        this.is_assessment = false
        list.push({key: 'laboratories',name: '模型'})
        list = hander_commom_menus(data, list)
        // 菜单
        this.$emit('update_menus',list)
        let exudate_data = JSON.parse(JSON.stringify(exudate_config))
        let obj = {api_type: this.data.api_type,...exudate_data,...data}
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    // 渗出初始值
    async search_init_exudate() {
      try {
        this.$emit('update_loading',true)
        let {data} = await get_lab_list(this.patient_id)
        data = hander_data_6(data)
        let exudate_data = JSON.parse(JSON.stringify(exudate_config))
        let obj = {api_type: this.data.api_type,...exudate_data,...data}
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },

    //预估渗出液模型报告详情
    async assessment_report_exudate(val) {
      this.$emit('update_loading',true)
      try {
        let params = hander_param_6(val)
        const {report_id,total_protein,protein_quantification,blood_lactate_dehydrogenase,fluid_lactate_dehydrogenase} = params
        params = {
          report_id,
          total_protein,
          protein_quantification,
          blood_lactate_dehydrogenase,
          fluid_lactate_dehydrogenase
        }
        let {data} = await submit_assessment_exudate(params)
        let obj = {}
        if(data.diagnosis_name) {
          data = hander_data_6(data)
          // 是否已经预估了，放开保存按钮
          this.is_assessment = true
          obj = {...this.data,...data}
        } else {
          this.no_visible = true
          data.diagnosis_name = ''
          obj = {...this.data,...data}
          this.is_assessment = false
        }
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    // 提交渗出液
    async submit_exudate(val) {
      this.$emit('update_loading',true)
      try {
        let params = hander_param_6(val)
        const {patient_id} = this
        const {report_id,total_protein,protein_quantification,blood_lactate_dehydrogenase,fluid_lactate_dehydrogenase} = params
        params = {
          patient_id,
          report_id,
          total_protein,
          protein_quantification,
          blood_lactate_dehydrogenase,
          fluid_lactate_dehydrogenase
        }
        const{data} = await submit_exudate(params)
        this.$emit('select_common_report',data.report_id)
        this.get_exudate_list(patient_id)
        //防止出现loading先为true,finally之后为false
        //报告相同直接申请不走watch
        if(data.report_id === this.current_report_id){
          setTimeout(() => {
            this.search_exudate()
          }, 60)
        }
        this.get_my_application({patientId:this.patient_id,reportId: this.report_id})
        //是否要刷新主页
        this.$emit('reflesh_page',true)
        // 编辑完成之后要把保存置灰
        this.is_show = true

      } finally{
        this.$emit('update_loading',false)
      }
    },
    // 删除渗出液
    async del_exudate() {
      this.$emit('update_loading',true)
      try {
        const {current_report_id} = this
        let params = {
          report_id:current_report_id
        }
        await del_exudate(params)
        if(this.current_report_id === this.report_id) {
          this.$router.replace({
            path:'/detail',
            query: {
              ...this.$route.query,
              report_id: ''
            }
          })
        }
        setTimeout(()=>{
          location.reload()
        }, 10)
        this.close()
      } finally{
        this.$emit('update_loading',false)
      }
    },

  }
}
</script>
<style scoped>
.flow-content{
  & >li {
    display: flex;
    position: relative;
    & .line {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 7px;
      height: 100%;
    }
    & .line-left {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 6px;
      height: 100%;
    }
    & .icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border: 4px solid rgba(0, 136, 255, 1);
      border-radius: 50%;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 1;
      &.icon-left {
        width: 14px;
        height: 14px;
      }
    }
    & .message {
      flex:1;
      font-size: 14px;;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 18px;
      color: #262626;
      margin-top: -2px;
      &.message-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #303133;
        &.select-message {
          color: #0088FF;
        }
      }
      & .time {
        font-size: 12px;;
        font-weight: 400;
        line-height: 19px;
        color: #565656;
        & .time-left {
          font-size: 12px;
          line-height: 17px;
          color: #606266;
          padding-top: 7px;
        }
      }
      & .add {
        font-size: 14px;;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}
.type_content {
  flex:1;
  & .left {
    width: 260px;
    overflow: auto;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding-left: 28px;
    top: 0;
    border-right: 1px solid rgba(112, 112, 112, 0.14);
    padding-top: 28px;
  }
  & .right {
    padding-bottom: 100px;
    margin-left: 260px;
    padding-top: 28px;
    &.is-hav-pad{
      padding-left: 28px;
    }
  }
}
.result {
  display: flex;
  align-items: center;
  & .bt {
    flex:1;
    display: flex;
    justify-content: flex-end;
  }
  &.result-text {
    font-size: 16px;
    line-height: 28px;
    color: rgba(48, 49, 51, 1);
    font-weight: 600;
  }
}
.result-but {
  display: flex;
  align-items: center;
  & .score {
    padding-left: 30px;
  }
}

.score {
  font-weight: 400;
  font-size: 14px;
  & >span {
    color: rgba(240, 82, 82, 1);
    font-size: 20px;
  }
}

.tixing {
  position: relative;
  & .tixing-arrow{
      color: #8CD665;
      position: absolute;
      top: -13px;
      right: 20px;
      font-size: 20px;
    }
  & .massage {
    position: absolute;
    width: 305px;
    height: 33px;
    padding: 0 10px;
    line-height: 33px;
    background: #8CD665;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: #FFFFFF;
    right: 0;

    top: 40px;
    &.massage-1{
      width: 180px;
    }
  }
}
.title{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #303133;
  padding: 36px 0 25px;
}

.form{
 display: flex;
 flex-wrap: wrap;
 margin-right: -24px;
}
.range{
  display: flex;
  align-items: center;
  width: 100%;
}
.lower {
  position: relative;
}
>>>.lower .el-form-item__label {
  position: absolute;
  width: 330px;
  left: 0;
  top: 0;
  text-align: left;
}

</style>

