import api from '../../../utils/api'

// 获取化验项详情
export async function get_trend (payload) {
  return api.post('/api/v2/plan/history', payload)
  // .then(({data}) => {
  //   data.reports = [
  //     {
  //       report_time: 1576284721000,
  //       reports: [
  //         {
  //           report_type: 8,
  //           report_name: '甲状腺用药随访记录'
  //         }
  //       ]
  //     },
  //     {
  //       report_time: 1576027302000,
  //       reports: [
  //         {
  //           report_type: 8,
  //           report_name: '甲状腺用药随访记录2'
  //         }
  //       ]
  //     }
  //   ]
  //   return {data}
  // })
}
