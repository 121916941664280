<template>
<!-- 新冠 -->
  <div class="g-detail-type-content">
    <ul class="g-detail-flow-content left">
      <el-button class="add" type="primary" @click="add()"><span style="font-size: 16px;margin-right: 6px">+</span>新 建</el-button>
      <li v-for="(i,index) in list" :key="i.report_id" @click="select_report(i.report_id)" style="cursor: pointer;" :ref="i.report_id">
        <div class="li-bg" v-if="i.report_id === current_report_id"/>
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': `${i.report_id}` === `${current_report_id}`}]" :style="{marginTop: -parseInt(line_height/6) + 'px'}">
          <div ref="time" class="time-left">{{format_date(i.report_time,'yyyy-MM-dd HH:mm:ss')}}</div>
        </div>
      </li>
      <div style="padding-bottom: 50px;"></div>
    </ul>
    <div class="right" v-if="is_has_data">
      <slot name="head"></slot>
      <div class="common-item">
        <div class="g-detail-conclusion-top">
          <div class="g-detail-conclusion-content">
            <div class="conclusion-title">结论</div>
            <div class="conclusion-right conclusion">
              <div v-for="(i,index) in data.results" :key="i">{{index+1}}、{{i}}</div>
            </div>
          </div>
        </div>
        <template v-if="data.suggests&&data.suggests.length">
          <h2 class="g-detail-commom-text commom-text-bottom commom-text-top">
            <div class="line"/>
            治疗建议
          </h2>
           <div class="content_box">
            <div v-for="(i,index) in data.suggests" :key="i">{{index+1}}、{{i}}</div>
          </div>
        </template>
        <template v-if="data.prompts&&data.prompts.length">
          <h2 class="g-detail-commom-text commom-text-bottom commom-text-top">
            <div class="line"/>
            更多提示
          </h2>
           <div class="content_box">
            <div v-for="(i,index) in data.prompts" :key="i">{{index+1}}、{{i}}</div>
          </div>
        </template>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          一般情况
        </h2>
        <ul class="t_content">
          <li><div>姓名</div><div>{{data.name}}</div></li>
          <li><div>性别</div><div>{{data.gender}}</div></li>
          <li><div>年龄</div><div>{{data.age}} <span style="padding-left: 5px;" v-if="data.age"> 岁</span></div></li>
          <li><div>体重</div><div>{{data.weight}} <span style="padding-left: 5px;" v-if="data.weight"> kg</span></div></li>
          <li><div>身高</div><div>{{data.height}} <span style="padding-left: 5px;" v-if="data.height"> cm</span></div></li>
          <li><div>BMI</div><div>{{data.BMI}}</div></li>
          <li class="last"><div> 肺部符合新冠肺炎的新发影像学异常</div><div>{{data.pul_ct_abnormality ? '是' : '否'}}</div></li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          推荐检查
        </h2>
        <ul class="t_content">
          <li class="last"><div>必要检查</div><div>生命体征、心电图、血气分析（注意记录吸氧条件）、血常规、凝血、D二聚体、肝功、肾全、LDH、CRP、ESR</div></li>
          <li class="last"><div>推荐检查</div><div>铁蛋白、CK、cTnI、NT-proBNP/BNP、TB淋巴细胞亚群、IL-6</div></li>
          <li class="last"><div>可选检查</div><div>IL-8、IL-10、球蛋白、补体；抗MDA5抗体；抗磷脂抗体谱；胸CT（没有72小时之内影像资料且病情危重的患者）</div></li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          重症和危重症的高危因素
        </h2>
        <ul class="t_content">
          <li><div>慢病史</div><div v-html="hander_label('chronic_history').labels.join('<br/>')"></div></li>
          <li><div>肿瘤病史</div><div v-html="hander_label('history_tumor').labels.join('<br/>')"></div></li>
          <li><div>用药史</div><div v-html="hander_label('drug_history').labels.join('<br/>')"></div></li>
          <li><div>个人史</div><div v-html="hander_label('personal_history').labels.join('<br/>')"></div></li>
          <li class="last"><div>其他</div><div v-html="hander_label('other_risk').labels.join('<br/>')"></div></li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          重症及危重症的预警因素
        </h2>
        <ul class="t_content">
          <li><div>临检</div><div v-html="format_label(data.inspection)"></div></li>
          <li><div>生化</div><div v-html="format_label(data.biochemistry)"></div></li>
          <li><div>免疫</div><div v-html="format_label(data.immune)"></div></li>
          <li><div>凝血</div><div v-html="format_label(data.coagulation)"></div></li>
          <li class="last">
            <div>其他</div>
            <div>
              <div>
                <div v-for="i in format_list(data.other_warning)" :key="i.key" class="tips">
                  <div>{{i.label}}</div>
                  <i v-if="i.key === 3" class="el-icon-caret-left tips-icon"/>
                  <div class="tips-text" v-if="i.key === 3"> Tips：症状出现后第2周内发展为危重症，需要警惕 </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          危重症因子
        </h2>
        <ul class="t_content">
          <li class="last"><div>危重症因子</div><div v-html="hander_label('illness_factor').labels.join('<br/>')"></div></li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          重症因子
        </h2>
        <ul class="t_content">
          <li><div>重症因子</div><div v-html="hander_label('severe_factor').labels.join('<br/>')"></div></li>
          <li><div>动脉血氧分压(PaO2)</div><div>{{data.paO2}}<span style="padding-left: 5px;" v-if="data.paO2">mmHg</span></div></li>
          <li><div>吸氧浓度(FiO2)</div><div>{{data.fiO2}}<span style="padding-left: 5px;" v-if="data.fiO2">%</span></div></li>
          <li><div>动脉血氧分压(PaO2)/吸氧浓度(FiO2)</div><div>{{data.pCF}}<span style="padding-left: 5px;" v-if="data.pCF">mmHg</span></div></li>
        </ul>
        <h2 class="g-detail-commom-text commom-text-top commom-text-bottom">
          <div class="line"/>
          影像学评估
        </h2>
        <ul class="t_content">
          <li>
            <div>左肺上叶病变百分比</div>
            <div>{{data.imaging_evaluation?.left_upper_blade}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_upper_blade || data.imaging_evaluation?.left_upper_blade === 0">%</span>
            </div>
          </li>
          <li>
            <div>左肺上叶病变分数</div>
            <div>{{data.imaging_evaluation?.left_upper_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_upper_score || data.imaging_evaluation?.left_upper_score === 0">分</span>
            </div>
          </li>
          <li>
            <div>左肺中叶百分比</div>
            <div>{{data.imaging_evaluation?.left_midd_blade}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_midd_blade || data.imaging_evaluation?.left_midd_blade === 0">%</span>
            </div>
          </li>
          <li>
            <div>左肺中叶分数</div>
            <div>{{data.imaging_evaluation?.left_midd_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_midd_score || data.imaging_evaluation?.left_midd_score === 0">分</span>
            </div>
          </li>
          <li>
            <div>左肺下叶百分比</div>
            <div>{{data.imaging_evaluation?.left_lower_blade}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_lower_blade || data.imaging_evaluation?.left_lower_blade === 0">%</span>
            </div>
          </li>
          <li>
            <div>左肺下叶分数</div>
            <div>{{data.imaging_evaluation?.left_lower_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.left_lower_score || data.imaging_evaluation?.left_lower_score === 0">分</span>
            </div>
          </li>
          <li>
            <div>右肺上叶百分比</div>
            <div>{{data.imaging_evaluation?.right_upper_blade}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.right_upper_blade || data.imaging_evaluation?.right_upper_blade === 0">%</span>
            </div>
          </li>
          <li>
            <div>右肺上叶病变分数</div>
            <div>{{data.imaging_evaluation?.right_uppper_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.right_uppper_score || data.imaging_evaluation?.right_uppper_score === 0">分</span>
            </div>
          </li>
          <li>
            <div>右肺下叶百分比</div>
            <div>{{data.imaging_evaluation?.right_lower_blade}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.right_lower_blade || data.imaging_evaluation?.right_lower_blade === 0">%</span>
            </div>
          </li>
          <li>
            <div>右肺下叶分数</div>
            <div>{{data.imaging_evaluation?.right_lower_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.right_lower_score || data.imaging_evaluation?.right_lower_score === 0">分</span>
            </div>
          </li>
          <li class="last">
            <div>总分</div>
            <div>{{data.imaging_evaluation?.image_score}}
              <span style="padding-left: 5px;" v-if="data.imaging_evaluation?.image_score || data.imaging_evaluation?.image_score === 0">分</span>
            </div>
          </li>
        </ul>
        <p class="info">说明：总分越高，重症、死亡风险越高。6、7分以上，风险显著升高</p>
        <template v-if="data.history&&data.history.length>0">
          <h2 class="g-detail-commom-text commom-text-bottom commom-text-top">
            <div class="line"/>
            修改记录
          </h2>
           <el-timeline class="list">
            <span v-if="data.history.length>3" class="score-icon" @click="show = !show"><i :class="[{'el-icon-arrow-up':show},{'el-icon-arrow-down':!show}]" style="padding-right: 3px;"/>{{show ? '收起'  : '展开'}}</span>
            <el-timeline-item v-for="i in (show || data.history.length<4 ? data.history : data.history.slice(0,3))" :key="i.date" >
              {{format_date(i.date,'yyyy-MM-dd HH:mm:ss')}}
              <span >{{i.role_type === 2 ? '患者' : ' 医生'}}</span><span v-if="i.role_type === 1">（{{i.user_name || i.user_id}}）</span>{{i.type}}了新冠分型诊治辅助
            </el-timeline-item>
          </el-timeline>
        </template>
      </div>
      <div class="foot">
        <el-button plain @click="to_edit">编辑</el-button>
      </div>
    </div>
    <div class="right" v-else style="padding: 0;">
      <slot name="no_data"></slot>
    </div>
    <ApiType9Edit
      :visible.sync="edit_visible"
      v-if="edit_visible"
      :init_data="eidt_data"
      @select_common_report="select_report"
      @search_covid19_list="search_covid19_list"
      @search_covid19_detail="search_covid19_detail"
      :patient_id="patient_id"
      :select_list_data="select_list_data"
      :report_id="report_id"/>
  </div>
</template>
<script>
import {format_date} from '../../../utils/format'
import {get_covid19_detail,get_covid19_list,get_covid19_init,get_select_list} from './service'
import ApiType9Edit from './ApiType9Edit'
import {type9_config, type9_config_imaging_evaluation} from './config'

export default {
  data() {
    return {
      can_save_data: false,
      edit_visible: false,
      show: false,
      init_data: {},
      eidt_data: {},
      line_height:18,
      select_list_data: {},
      t_show: true
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: [String,Number],
      default:null
    },
    patient_id:{
      type: String,
      default:''
    },
    report_id:{
      type: String,
      default:''
    },
    select_key: {
      type: String,
      default:''
    },
    is_has_data: {
      type: Boolean,
      default: false
    },
  },
  components: {
    ApiType9Edit,
  },
  mounted() {
    if(this.current_report_id) {
      let id = this.current_report_id + ''
      setTimeout(()=>{
      // 滑到指定位置
        let dom = this.$refs[id][0]
        dom.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      },800)
    }

  },
  watch: {
    current_report_id(val) {
      if(val) {
        this.search_covid19_detail()
      } else {
        this.search_covid19_list()
      }
    }
  },
  async created() {
    // 必须要获取配置项
    await this.get_options()
    await this.search_covid19_list()
  },
  methods: {
    format_date,
    async add() {
      await this.search_init_data()
      this.edit_visible = true
    },
    update_data(obj) {
      this.$emit('update_data',obj)
    },
    to_edit() {
      this.eidt_data = this.data
      this.edit_visible = true
    },
    select_report(id) {
      this.$emit('select_common_report',id)
    },
    hander_label(key) {
      let keys = this.data[key] || []
      let list = this.select_list_data[key] || []
      let labels = []
      let arr = []
      for(let i of list) {
        if(keys.includes(i.key)) {
          labels.push(i.value)
          arr.push(i)
        }

      }
      return {labels,arr}
    },
    format_label (list) {
      if (list && list.length) {
        const labels = list.filter(item => item.value === 1).map(item => item.label)
        return labels.join('<br/>')
      }
      return ''
    },
    format_list (list) {
      if (list && list.length) {
        return list.filter(item => item.value === 1)
      }
      return []
    },
    // 处理数据
    format_data (data) {
      data.imaging_evaluation = {...type9_config_imaging_evaluation, ...data.imaging_evaluation}
      // 合并
      const marge_arr = (name) => {
        const orgin_list = this.select_list_data[name] || []
        const list = data[name] || []
        const obj = {}
        for (let item of list) {
          obj[item.key] = item.value
        }
        return orgin_list.map(item => {
          item
          return {
            key: item.key,
            label: item.value,
            value: obj[item.key],
          }
        })
      }
      data.inspection = marge_arr('inspection')
      data.biochemistry = marge_arr('biochemistry')
      data.immune = marge_arr('immune')
      data.coagulation = marge_arr('coagulation')
      data.other_warning = marge_arr('other_warning')
      return {...type9_config,...data, api_type: this.data.api_type}
    },
    async search_init_data() {
      this.$emit('update_loading',true)
      let {patient_id} = this
      try {
        let {data} = await get_covid19_init(patient_id)
        this.eidt_data = this.format_data(data)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    async search_covid19_detail() {
      if(!this.current_report_id)  return
      this.$emit('update_loading',true)
      const {current_report_id} = this
      try {
        const params = {
          report_id:current_report_id
        }
        const {data} = await get_covid19_detail(params)
        const obj = this.format_data(data)
        this.$emit('update_data', obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    async search_covid19_list() {
      this.$emit('update_loading',true)
      try {
        const {data} = await get_covid19_list(this.patient_id)
        this.$emit('update_list', data)
        //申请时间列表后接着申请下拉列表
        if(!this.current_report_id && data&& data.length>0) {
          this.$emit('select_common_report',data[0].report_id)
        } else {
          await this.search_covid19_detail()
        }
        setTimeout(()=>{
          this.line_height = this.$refs.time ? this.$refs.time[0].clientHeight : 18
        },500)
      } finally {
        this.$emit('update_loading',false)
      }
    },
    // 获取配置项
    async get_options() {
      const {data} = await get_select_list(this.patient_id)
      this.select_list_data = data
    },
  }
}
</script>
<style scoped>
>>>.el-timeline-item__node {
  background: var(--color-primary);
}
.list {
  border: 1px solid rgba(0,0,0,0.149);
  padding: 20px 20px 0;
  position: relative;
  & .score-icon {
    position: absolute;
    right: 20px;
    color: var(--color-primary);
    cursor: pointer;
    z-index: 100;
    & >i {
      padding-right: 5px;
    }
  }
}
>>>.el-timeline-item__content {
  font-size: 16px;
  color: var(--color-text-regular);
  & >span {
    color: var(--color-primary);
  }
}
>>>.el-timeline-item__wrapper {
  top: -8px;
}
>>>.el-timeline-item  {
  padding-bottom: 1px;
}
.t_content {
  color: var(--color-text-regular);
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(0,0,0,0.149);
  border-right: 1px solid rgba(0,0,0,0.149);
  & >li {
    width: 50%;
    display: flex;
    & >div {
      padding: 13px 23px;
      display: flex;
      align-items: center;
      &:nth-child(1){
        width: 240px;
        background:var(--color-text-six);
        border: 1px solid rgba(0,0,0,0.149);
        border-bottom: none;

      }
      &:nth-child(2){
        flex: 1;
        border-top: 1px solid rgba(0,0,0,0.149);
      }
    }
    &.last {
      width: 100%;
    }
  }
}
.tips {
  display: flex;
  align-items: center;
  & .tips-text {
    border-top: 1px solid rgba(0,0,0,0.149);
    background: #303133;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    font-size: 12px;
    min-width: 100px;
    word-wrap: break-word;
    margin-left: -9px;
  }
  & .tips-icon {
    font-size: 24px;
  }
}

.content_box {
  border: 1px solid rgba(0,0,0,0.149);
  padding: 20px;
  position: relative;
  color: var(--color-text-regular);
}
.info {
  color: var(--color-danger);
  margin-top: 5px;
}
</style>

