import {get_list} from './service'
export default {
  namespaced: true,
  state: {
    loading: false,
    search_params: {
      page: 0,
      page_size: 15,
    },
    list: [],
    data: {},
    total: 0
  },
  mutations: {
    showLoading (state) {
      state.loading = true
    },
    hideLoading (state) {
      state.loading = false
    },
    delete_list(state) {
      state.list = []
    },
    clearState(state) {
      state.loading = false
      state.search_params = {
        page: 0,
        page_size: 15
      }
      state.list = []
      state.data = {}
      state.total = 0
    },
    updateList(state,payload) {
      state.data = payload.data
      state.total = payload.data.total_elements
      state.list = payload.data.data
    },
  },
  actions: {
    async search_list ({ state,commit },payload) {
      commit({
        type: 'showLoading'
      })
      state.search_params={...payload}
      try {
        const {data} = await get_list(payload)
        commit({
          type: 'updateList',
          data
        })
      } finally {
        commit({
          type: 'hideLoading'
        })
      }
    }
  }
}
