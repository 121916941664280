<template>
   <el-dialog
      width="80%"
      title=""
      :visible.sync="visible"
       :before-close="before_close"
      append-to-body>
      <div class="title" slot="title">
        填写依据
      </div>
      <div class="body">
        <el-button type="primary" style="margin: 10px 0;" size="small" @click="add">新增</el-button>
        <ul class="G-TABLE G-TABLE-title">
          <li class="item item-title" style="min-width: 200px">检验项</li>
          <li class="item item-title">标本</li>
          <li class="item item-title">结果</li>
          <li class="item item-title">单位</li>
          <li class="item item-title">参考值</li>
          <li class="item item-title">操作</li>
        </ul>
        <el-form ref="form" :model="data" class="form">
          <div
            class="G-TABLE"
            v-for="(domain, index) in data.evidences"
            :key="domain.key"
           >
           <el-form-item
              class="item item1"
              style="min-width: 200px"
              :prop="'evidences.' + index + '.name'"
              :rules="{
                required: true, message: '检验项不能为空', trigger: 'blur'
              }"
              >
              <el-input autosize type="textarea" size="mini" v-model="domain.name" placeholder="请输入检验项"></el-input>
            </el-form-item>
           <el-form-item
              class="item"
              :prop="'evidences.' + index + '.specimen'"
              :rules="{
                required: true, message: '标本不能为空', trigger: 'blur'
              }"
              >
              <el-input autosize type="textarea" size="mini" v-model="domain.specimen" placeholder="请输入标本"></el-input>
            </el-form-item>
            <el-form-item
              class="item"
              :prop="'evidences.' + index + '.result'"
              :rules="{
                required: true, message: '结果不能为空', trigger: 'blur'
              }"
              >
              <el-input autosize type="textarea" size="mini" v-model="domain.result" placeholder="请输入结果"></el-input>
            </el-form-item>
            <el-form-item
              class="item"
              :prop="'evidences.' + index + '.unit'"
              :rules="{
                required: true, message: '单位不能为空', trigger: 'blur'
              }"
              >
              <el-input autosize type="textarea" size="mini" v-model="domain.unit" placeholder="请输入单位"></el-input>
            </el-form-item>
            <el-form-item
              class="item"
              :prop="'evidences.' + index + '.reference'"
              :rules="{
                required: true, message: '参考值不能为空', trigger: 'blur'
              }"
              >
              <el-input autosize type="textarea" size="mini" v-model="domain.reference" placeholder="请输入参考值"></el-input>
            </el-form-item>
            <el-form-item
              class="item"
              style="text-align: center;"
              >
              <i style="cursor: pointer;line-height: 30px;" class="el-icon-delete" @click="removeDomain(index)"/>
            </el-form-item>
          </div>
        </el-form>
    </div>
     <div slot="footer" class="dialog-footer">
      <el-button @click="before_close" size="small">取 消</el-button>
      <el-button type="primary" @click="confirm" size="small">确定</el-button>
    </div>
  </el-dialog>
</template>
<script>

export default {
  data() {
    return {
      data: {
        evidences: []
      },
      obj:{
        name: '',
        specimen: '',
        result: '',
        unit: '',
        reference: '',
        key: Date.now()
      }
    }
  },
  props: {
    list: {
      type: Array,
      default: () =>[]
    },
    visible: {
      type: Boolean,
      default: false
    },
  },
  watch:{

  },
  components: {

  },
  created() {
    let t_obj = JSON.parse(JSON.stringify(this.obj))
    if(this.list.length===0) {
      this.data.evidences.unshift(t_obj)
    } else {
      this.data.evidences = JSON.parse(JSON.stringify(this.list))
    }
  },
  methods: {
    confirm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          this.$emit('save_list',this.data.evidences)
          this.close()
        }
      })
    },
    close () {
      this.$emit('update:visible', false)
    },
    before_close () {
      this.$confirm('关闭将丢失已编辑的内容，确认关闭？')
        .then(() => {
          this.close()
        })
        .catch(() => {});
    },
    add() {
      let t_obj = JSON.parse(JSON.stringify(this.obj))
      this.data.evidences.unshift({
        ...t_obj,
        key: Date.now()
      });
    },
    removeDomain(index) {
      this.data.evidences.splice(index, 1)
    }
  }
}
</script>
<style scoped>
.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  color: #6B747D;
}
.h2 {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color: #303133;
    padding-bottom: 12px;
  }
  .body {

  }


>>>.el-dialog__header {
  border-bottom: 1px solid rgba(112, 112, 112, 0.22);
}
>>>.el-dialog__body {
  padding:0 20px 20px;
}
>>>.G-TABLE .el-input__inner {
  border: none;
  text-align: center;
}
.form{
  border-right: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5;
}
.G-TABLE-title {
  border-right: 1px solid #ebeef5;
  background: #f0f7ff;
}

.G-TABLE{
  display: flex;
  & .item {
    flex:1;
    border: 1px solid #ebeef5;
    border-right: none;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;

    &.item-title {
      padding: 10px;
      text-align: center;
      font-weight: bold;
    }
  }
}
>>>.G-TABLE .el-form-item {
  margin-bottom: 0px;
}
>>>.G-TABLE .el-form-item__error {
  top: 20px;
  left: 10px;
  z-index: 10;
}
>>>.el-textarea__inner {
  border: none;
 }
 >>>.el-form-item__content {
  line-height: 20px;
 }
</style>
