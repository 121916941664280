<template>
<!-- grace -->
  <div class="type_content" v-if="is_has_data">
    <ul class="flow-content left">
      <li @click="select_report('')" style="cursor: pointer;">
        <div class="line-left" v-if="list.length>0"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': !current_report_id}]">
          <div class="add">新建</div>
        </div>
      </li>
      <li v-for="(i,index) in list" :key="i.report_id" @click="select_report(i.report_id)" style="cursor: pointer;" :ref="i.report_id">
        <div class="line-left" v-if="index !== list.length-1"/>
        <div class="icon icon-left"></div>
        <div :class="['message','message-left',{'select-message': i.report_id === current_report_id}]">
          <div class="time time-left">{{format_date(i.report_time,'yyyy-MM-dd HH:mm')}}</div>
          {{i.level}} {{i.score}}分
        </div>
      </li>
    </ul>
      <div class="right is-hav-pad">
        <div class="result">
          <div class="result-text" v-if="data.score">Grace评分：<span class="score">{{data.level}}{{data.score}}分</span></div>
          <div class="bt">
            <el-button v-if="current_report_id&&data.mine" type="danger" round size="small" @click="to_del_grace">删除</el-button>
            <el-button type="primary" round size="small" @click="is_show = false" v-if="is_show && current_report_id && data.mine">编辑</el-button>
            <div class="tixing" v-if="!is_show">
              <el-button type="primary" slot="reference" style="margin-left: 10px;" round size="small" @click="to_sbmit_grace" :disabled="!is_assessment">保存</el-button>
              <div :class="['massage',{'massage-1': !is_assessment}]" v-if="visible">
                <i class="el-icon-caret-top tixing-arrow"/>
                {{!is_assessment ? '先点击查看结果再保存' : '您可以保存当前报告结果，供其他医生查阅'}}
                <i @click="visible = false" style="font-weight: bold;cursor: pointer;" class="el-icon-close"/>
              </div>
            </div>
          </div>
        </div>
        <div class="result-text juge" v-if="data.judgment">
          <div class="tile">综合判读：</div>
          <div class="t_content">
            <span class="text">{{data.judgment}}</span>
            <el-button type="primary" plain slot="reference" style="margin-left: 10px;" round size="mini" @click="copy(data.judgment)">复制</el-button>
            <input id="input" value="" style="opacity:0;position:absolute;width: 1px;"/>
          </div>
        </div>
        <div class="title">评估内容：</div>
        <el-form :rules="rules" ref="ruleForm" :model="data" label-position="top">
          <div class="assessment_list">
            <el-form-item  prop="cardiac_arrest" class="assessment" >
              <el-checkbox :disabled="is_show" style="width: 100%; color: #606266;font-weight: 400;" border v-model="data.cardiac_arrest">院前心脏骤停</el-checkbox>
            </el-form-item>
            <el-form-item  prop="st_down" style="padding: 0 24px;" class="assessment">
              <el-checkbox :disabled="is_show" style="width: 100%;color: #606266;font-weight: 400;" border v-model="data.st_down">ST段下移</el-checkbox>
            </el-form-item>
            <el-form-item prop="cardiac_enzymes_high" class="assessment">
              <el-checkbox  :disabled="is_show || !data.evidence_modify" style="width: 100%;color: #606266;font-weight: 400;" border v-model="data.cardiac_enzymes_high">心肌坏死标志物升高</el-checkbox>
              <el-button :disabled="is_show" @click="add_list" type="text" v-if="data.evidence_modify&&data.evidences&&data.evidences.length===0&&data.cardiac_enzymes_high" style="padding-bottom: 0;">填写依据，点这里</el-button>
            </el-form-item>
          </div>
          <div class="table-list" v-if="data.evidences&&data.evidences.length>0">
            <div>心肌坏死标志物相关检验项：</div>
            <el-button type="text" v-if="data.evidence_modify" style="padding:0;"  @click="edit" :disabled="is_show">编辑</el-button>
          </div>
          <LabTable v-if="data.evidences&&data.evidences.length>0" :data="data.evidences" style="margin-bottom: 15px;"/>
          <div class="num_list">
            <el-form-item
            :rules="{required: true, message: '请输入年龄', trigger: 'blur'}"
            label="年龄" style="padding-right:24px;" prop="age" >
              <MyInput
              v-model="data.age"
              :integerDigits="3"
              :disabled="is_show"
              :decimalDigits="0"
              unit="岁"
              placeholder="请输入年龄"
              />
            </el-form-item>
            <el-form-item
            :rules="{required: true, message: '请输入心率', trigger: 'blur'}"
            label="心率"  prop="heart_rate" >
              <MyInput
                v-model="data.heart_rate"
                :disabled="is_show"
                :integerDigits="3"
                :decimalDigits="0"
                unit="次/分"
                placeholder="请输入心率"
                />
            </el-form-item>
          </div>
        <div class="num_list">
          <el-form-item label="血压(收缩压)"
            :rules="{required: true, message: '请输入血压(收缩压)', trigger: 'blur'}"
            style="padding-right:24px;" prop="blood_pressure" >
            <div class="unit-list">
              <MyInput
                style="flex:1"
                class="my-input"
                v-model="data.blood_pressure"
                :disabled="is_show"
                :integerDigits="3"
                :decimalDigits="2"
                :data="data"
                placeholder="请输入(收缩压)"
                />
              <el-select :disabled="is_show" v-model="data.blood_pressure_unit" placeholder="请选择单位" style="width: 105px;" class="select-unit">
                <el-option
                  v-for="item in ['mmHg','kPa']"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item
            :rules="{required: true, message: '请输入血肌酐', trigger: 'blur'}"
            label="血肌酐" prop="serum_creatinine"  class="serum_creatinine">
            <div class="ref" v-if="data.serum_creatinine_reference">参考值:{{data.serum_creatinine_reference}}</div>
            <div class="unit-list">
               <MyInput
                class="my-input"
                style="flex:1"
                v-model="data.serum_creatinine"
                :disabled="is_show"
                :data="data"
                :decimalDigits="2"
                placeholder="请输入血肌酐"
              />
                <el-select :disabled="is_show" v-model="data.serum_creatinine_unit" placeholder="请选择单位" style="width: 105px;" class="select-unit">
                  <el-option
                    v-for="item in ['μmol/L','mg/dl']"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </div>
            </el-form-item>
          </div>
          <el-form-item label="Killip分级："
          :rules="{required: true, message: '请选择Killip分级', trigger: 'change'}"
          prop="kill_ip_rank" class="rank" style="padding-top: 10px;">
            <span class="inner-item item-title">
              <span class="item-icon"></span>
              <span class="item-1">级别</span>
              <span class="item-2">症状和体征</span>
            </span>
            <el-radio-group :disabled="is_show" v-model="data.kill_ip_rank" size="small" class="radio-list">
              <div v-for="i in killip_list" :key="i.value" class="radio-item">
                <el-radio :label="i.value" class="inner-item">
                  <span class="item-1">{{i.level}}</span>
                  <span class="item-2">{{i.label}}</span>
                </el-radio>
              </div>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="padding-top: 10px;">
            <div class="result-but">
              <el-button :disabled="(current_report_id&&!data.mine) || is_show" type="primary" round size="small" @click="assessment_report">点击查看结果</el-button>
              <div class="result-text" v-if="data.score">Grace评分：<span class="score">{{data.level}} <template>{{data.score}}分</template></span></div>
            </div>
          </el-form-item>
        </el-form>
        <div class="score-tabel" >
          <span class="score-title">评分量表：</span>
          <span class="score-icon" @click="show = !show"><i :class="[{'el-icon-caret-top':show},{'el-icon-caret-bottom':!show}]" style="padding-right: 3px;"/>详情</span>
        </div>
        <div class="rule" v-if="!show" @click="show = true">点击查看<span>量表评分规则</span></div>
        <!-- <ScoreLabTable style="margin-bottom: 20px;"  v-if="show"/> -->
        <el-table
        v-if="show"
        :data="score_table_list"
        border
        class="tab1"
        style="width: 100%">
        <el-table-column
          prop="level"
          align="center"
          label="风险分级">
        </el-table-column>
        <el-table-column
          align="center"
          prop="score_range"
          label="评分范围">
        </el-table-column>
      </el-table>
      <slot name="CommonCyclopedia"></slot>
    </div>
    <Evidences :visible.sync="lab_visibel" v-if="lab_visibel" :list="data.evidences" @save_list="save_list"/>
  </div>
</template>
<script>
import {format_date} from '../../../utils/format'
import {killip_list,score_table_list} from './config'
import MyInput from './MyInput'
import Evidences from './Evidences'
import LabTable from './LabTable'
import {grace_config,hander_commom_menus} from './config'
import {get_grace,submit_assessment_grace,get_init_grace,submit_grace,del_grace} from './service'
import { mapState,mapActions } from 'vuex'
// import ScoreLabTable from './ScoreLabTable'
export default {
  data() {
    return {
      visible:true,
      rules: {
      },
      killip_list: killip_list,
      score_table_list: score_table_list,
      show: false,
      is_show: true,
      lab_visibel: false,
      is_first: true,
      is_assessment: false
    }
  },
  props: {
    data:{
      type: Object,
      default: () =>{}
    },
    list: {
      type: Array,
      default: () =>[]
    },
    current_report_id: {
      type: String,
      default:''
    },
    is_has_data: {
      type: Boolean,
      default: false
    },
    patient_id:{
      type: String,
      default:''
    },
    current_item: {
      default: null,
      type: Object
    },
    report_id:{
      type: String,
      default:''
    },
  },
  components: {
    MyInput,
    Evidences,
    LabTable,
    // ScoreLabTable
  },
  computed: {
    ...mapState('labDetails', {
      grace_list:state => state.grace_list,
    }),
  },
  watch: {
    current_report_id() {
      this.search_grace()
      this.visible = true
      this.is_show = !!this.current_report_id
      if(this.$refs['ruleForm'])this.$refs['ruleForm'].resetFields()
    },
    is_show(val) {
      this.$emit('is_edit',!val)
    },
    grace_list(val) {
      this.$emit('update_list',val)
      if(val.length>0&&!this.current_report_id&&(this.current_item && !this.current_item.hint)){
        this.$emit('select_common_report',val[0].report_id)
      }else {
        this.search_grace()
      }
      if(this.is_first) {
        this.$emit('update_loading',false)
        this.is_first = false
      }
    },
  },
  created() {
    this.$emit('update_loading',true)
    this.get_grace_list(this.patient_id)
    if(!this.current_report_id) this.is_show = false
    this.$emit('is_edit',!this.is_show)
  },
  mounted() {
    if(this.current_report_id) {
      setTimeout(()=>{
      // 滑到指定位置
        let dom = this.$refs[this.current_report_id][0]
        dom.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      },800)
    }
  },
  methods: {
    ...mapActions('labDetails', [
      'get_grace_list',
    ]),
    format_date,
    select_report(id) {
      this.$emit('select_common_report',id)
    },
    to_sbmit_grace() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.$confirm('确定要保存结果?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.submit_grace(this.data)
          }).catch(() => {
          });

        }
      })
    },
    to_del_grace() {
      this.$confirm('此操作将永久删除该报告, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.del_grace(this.data)
      }).catch(() => {
      });
    },
    assessment_report() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.assessment_report_grace(this.data)
        }
      })
    },
    add_list() {
      this.lab_visibel = true
    },
    edit() {
      this.lab_visibel = true
    },
    save_list(list) {
      this.data.evidences = list
    },
    copy(no) {
      const input = document.getElementById('input')
      input.value = no // 修改文本框的内容
      input.select() // 选中文本
      try{
        document.execCommand('copy');
        this.$notify({
          title: '',
          message: '复制成功',
          type: 'success'
        })
      }catch(e) {
        console.log(e)
      }
    },
    // grace评分详情
    async search_grace() {
      this.is_assessment = false
      this.$emit('update_loading',true)
      let grace_data = JSON.parse(JSON.stringify(grace_config))
      const {current_report_id} = this
      let list = []
      list.push({key: 'laboratories',name: '模型'})
      // 菜单
      this.$emit('update_menus',list)
      if(!current_report_id){
        this.search_init_grace()
        return
      }
      try {
        let params = {
          report_id:current_report_id
        }
        const {data} = await get_grace(params)
        list = hander_commom_menus(data, list)
        // 菜单
        this.$emit('update_menus',list)
        let obj = {api_type: this.data.api_type,...grace_data,...data}
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    // grace初始值
    async search_init_grace() {
      try {
        this.$emit('update_loading',true)
        let {data} = await get_init_grace(this.patient_id)
        let grace_data = JSON.parse(JSON.stringify(grace_config))
        let obj = {api_type: this.data.api_type,...grace_data,...data,evidence_modify: !data.have_evidence}
        this.$emit('update_data',obj)
      } finally{
        this.$emit('update_loading',false)
      }
    },
    //预估grace报告获取结果
    async assessment_report_grace(val) {
      this.$emit('update_loading',true)
      try {
        const {report_id,cardiac_arrest,
          st_down,cardiac_enzymes_high,age,heart_rate,
          blood_pressure,serum_creatinine,kill_ip_rank,
          blood_pressure_unit,serum_creatinine_unit,evidences} = val
        let params = {
          report_id,
          cardiac_arrest,
          st_down,
          cardiac_enzymes_high,
          age,
          heart_rate,
          blood_pressure,
          serum_creatinine,
          kill_ip_rank,
          blood_pressure_unit,
          serum_creatinine_unit,
          evidences,
        }
        const {data} = await submit_assessment_grace(params)
        this.is_assessment = true
        this.$emit('update_data',{...this.data,...data})
      } finally{
        this.$emit('update_loading',false)
      }
    },
    // 提交grace评分
    async submit_grace(val) {
      this.$emit('update_loading',true)
      try {
        const {patient_id} = this
        const {report_id,cardiac_arrest,
          st_down,cardiac_enzymes_high,age,heart_rate,
          blood_pressure,serum_creatinine,kill_ip_rank,
          blood_pressure_unit,serum_creatinine_unit,evidences} = val
        let params = {
          patient_id,
          report_id,
          cardiac_arrest,
          st_down,
          cardiac_enzymes_high,
          age,
          heart_rate,
          blood_pressure,
          serum_creatinine,
          kill_ip_rank,
          blood_pressure_unit,
          serum_creatinine_unit,
          evidences,
        }
        const{data} = await submit_grace(params)
        this.$emit('select_common_report',data.report_id)
        this.get_grace_list(patient_id)
        //报告相同直接申请不走watch
        if(data.report_id === this.current_report_id)this.search_grace()
        //是否要刷新主页
        this.$emit('reflesh_page',true)
        // 编辑完成之后要把保存置灰
        this.is_show = true
      } finally{
        this.$emit('update_loading',false)
      }
    },
    //删除grace评分
    async del_grace() {
      this.$emit('update_loading',true)
      try {
        const {current_report_id} = this
        let params = {
          report_id:current_report_id
        }
        await del_grace(params)
        if(this.current_report_id === this.report_id) {
          this.$router.replace({
            path:'/detail',
            query: {
              ...this.$route.query,
              report_id: ''
            }
          })
        }
        setTimeout(()=>{
          location.reload()
        }, 10)
      } finally{
        this.$emit('update_loading',false)
      }
    },
  }
}
</script>
<style scoped>
.flow-content{
  & >li {
    display: flex;
    position: relative;
    & .line {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 7px;
      height: 100%;
    }
    & .line-left {
      position: absolute;
      border-left: 2px dotted rgba(0, 136, 255, 0.69);
      top: 0px;
      left: 6px;
      height: 100%;
    }
    & .icon {
      width: 16px;
      height: 16px;
      background: #fff;
      border: 4px solid rgba(0, 136, 255, 1);
      border-radius: 50%;
      font-size: 14px;
      font-weight: 600;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
      position: relative;
      z-index: 1;
      &.icon-left {
        width: 14px;
        height: 14px;
      }
    }
    & .message {
      flex:1;
      font-size: 14px;;
      font-weight: 500;
      line-height: 16px;
      padding-bottom: 18px;
      color: #262626;
      margin-top: -2px;
      &.message-left{
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: #303133;
        &.select-message {
          color: #0088FF;
        }
      }
      & .time {
        font-size: 12px;;
        font-weight: 400;
        line-height: 19px;
        color: #565656;
        & .time-left {
          font-size: 12px;
          line-height: 17px;
          color: #606266;
          padding-top: 7px;
        }
      }
      & .add {
        font-size: 14px;;
        font-weight: 400;
        line-height: 19px;
      }
    }
  }
}
.type_content {
  flex:1;
  & .left {
    width: 260px;
    overflow: auto;
    position: absolute;
    left: 0px;
    bottom: 0;
    padding-left: 28px;
    top: 0;
    border-right: 1px solid rgba(112, 112, 112, 0.14);
    padding-top: 28px;
  }
  & .right {
    padding-bottom: 100px;
    padding-top: 28px;
    margin-left: 260px;
    &.is-hav-pad{
      padding-left: 28px;
    }
  }
}
.result {
  display: flex;
  & .bt {
    flex:1;
    display: flex;
    justify-content: flex-end;
  }
}
.result-but {
  display: flex;
  align-items: center;
  & .result-text {
    padding-left: 20px;
  }
}
.result-text {
    font-size: 16px;
    line-height: 28px;
    color: rgba(48, 49, 51, 1);
    font-weight: 600;
    &.juge {
      display: flex;
      padding-top: 40px;
      & .tile {

      }
      & .t_content {
        flex:1;
      }
    }
    & >span {
      color: rgba(240, 82, 82, 1);
      font-size: 20px;
    }
    & .text{
      color: #1890ff;
      font-weight: 500;
    }
  }
.tixing {
  position: relative;
  & .tixing-arrow{
      color: #8CD665;
      position: absolute;
      top: -13px;
      right: 20px;
      font-size: 20px;
    }
  & .massage {
    position: absolute;
    width: 305px;
    height: 33px;
    padding: 0 10px;
    line-height: 33px;
    background: #8CD665;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    color: #FFFFFF;
    right: 0;
    top: 40px;
    &.massage-1{
       width: 180px;
    }
  }
}
.title{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #303133;
  padding: 36px 0 25px;
}
.assessment_list {
  display: flex;
  & .assessment{
    flex:1;
  }
}

.num_list {
  display: flex;
  & >div{
    flex:1;
  }
}
.radio-list{
 border-top: 1px solid #ebeef5;
width: 100%;
  & .radio-item {
    border: 1px solid #ebeef5;
    border-top: none;
    padding: 10px;
   width: 100%;
  }
}

.inner-item{
  display: flex;
  align-items: center;
  width: 100%;
  & .item-1 {
    width: 60px;
    text-align: center;
    padding: 0 10px;
  }

  & .item-2 {
    flex:1;
    padding: 0 10px;
  }
  &.item-title{
    border: 1px solid #ebeef5;
    border-bottom: none;
    font-weight: bold;
    color: #606266;
    background: #f0f7ff;
    & .item-icon {
      width: 40px;
    }
  }
}
>>>.el-radio__label {
  display: flex;
  align-items: center;
  width: 100%;
}
>>>.rank .el-form-item__label {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  padding-bottom: 12px;
  color: #303133;
}
.score-tabel {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  color: #303133;
  padding-top: 10px;
  display: flex;
  padding-bottom: 12px;
  & .score-title{
    flex: 1;
  }
  & .score-icon {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #1890FF;
    cursor: pointer;
  }
}
.rule {
  color: #939395;
  cursor: pointer;
  & >span {
    color: #1890FF;
  }
}

.unit-list {
  display: flex;
  width: 100%;
}
.my-input{
  margin-right: -1px;
  position: relative;
  z-index: 1;
}
>>>.select-unit .el-input__inner{
  border-left: none;
  border-radius:0 4px 4px 0;
}
>>>.select-unit.el-select .el-input.is-focus .el-input__inner {
  border-color: #dcdfe6;
}
 >>>.select-unit.el-select .el-input__inner:focus {
  border-color: #dcdfe6;
}
>>>.el-form-item {
  margin-bottom: 15px;
}
.table-list {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  & >div {
    flex:1;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    color: #303133;
  }
}
>>>.el-radio {
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #606266;
  white-space: normal;
}
.serum_creatinine {
  position: relative;
  & .ref{
    position: absolute;
    top: -50px;
    right: 0;
  }

}
>>>.el-input.is-disabled .el-input__inner {
  color: #606266;
}
</style>

